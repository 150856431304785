import {
    Injectable
} from '@angular/core';
import {
    WebView
} from '@ionic-native/ionic-webview/ngx';
import {
    Device
} from '@ionic-native/device/ngx';
import {
    SplashScreen
} from '@ionic-native/splash-screen/ngx';
import {
    StatusBar
} from '@ionic-native/status-bar/ngx';
import {
    Keyboard
} from '@ionic-native/keyboard/ngx';
import {
    Network
} from '@ionic-native/network/ngx';
import {
    Contacts
} from '@ionic-native/contacts/ngx';
import {
    InAppBrowser
} from '@ionic-native/in-app-browser/ngx';
import {
    Camera
} from '@ionic-native/camera/ngx';
import {
    ScreenOrientation
} from '@ionic-native/screen-orientation/ngx';
import {
    Media
} from '@ionic-native/media/ngx';
import {
    File
} from '@ionic-native/file/ngx';
import {
    Dialogs
} from '@ionic-native/dialogs/ngx';
@Injectable()
export class ApperyioNativeHelperService {
    public audioinput;
    constructor(
        public webView: WebView, public device: Device, public splashScreen: SplashScreen, public statusBar: StatusBar, public keyboard: Keyboard, public network: Network, public contacts: Contacts, public inAppBrowser: InAppBrowser, public camera: Camera, public screenOrientation: ScreenOrientation, public media: Media, public file: File, public dialogs: Dialogs
    ) {
        this.audioinput = (window as any).audioinput;
    }
};