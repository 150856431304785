import {
    Injectable
} from '@angular/core';
@Injectable()
export class ModalScreensService {
    private modalScreens: {
        [name: string]: any
    } = {};
    async getModalScreen(screenName: string) {
        if (!this.modalScreens[screenName]) {
            let modalImport;
            switch (screenName) {
                case "UpgradePopup":
                    modalImport = await
                    import (`../../UpgradePopup/UpgradePopup`);
                    break;
                case "Sample":
                    modalImport = await
                    import (`../../Sample/Sample`);
                    break;
                case "guestlist":
                    modalImport = await
                    import (`../../guestlist/guestlist`);
                    break;
                case "Overview":
                    modalImport = await
                    import (`../../Overview/Overview`);
                    break;
                case "Pricing":
                    modalImport = await
                    import (`../../Pricing/Pricing`);
                    break;
                case "Registry":
                    modalImport = await
                    import (`../../Registry/Registry`);
                    break;
                case "Payment":
                    modalImport = await
                    import (`../../Payment/Payment`);
                    break;
                case "EventOptions":
                    modalImport = await
                    import (`../../EventOptions/EventOptions`);
                    break;
                case "guestBook":
                    modalImport = await
                    import (`../../guestBook/guestBook`);
                    break;
                case "messages":
                    modalImport = await
                    import (`../../messages/messages`);
                    break;
                case "AudioModal":
                    modalImport = await
                    import (`../../AudioModal/AudioModal`);
                    break;
                case "Microphone":
                    modalImport = await
                    import (`../../Microphone/Microphone`);
                    break;
                case "Login":
                    modalImport = await
                    import (`../../Login/Login`);
                    break;
                case "Fonts":
                    modalImport = await
                    import (`../../Fonts/Fonts`);
                    break;
                case "ShareLink":
                    modalImport = await
                    import (`../../ShareLink/ShareLink`);
                    break;
                case "Crop":
                    modalImport = await
                    import (`../../Crop/Crop`);
                    break;
                case "Search":
                    modalImport = await
                    import (`../../Search/Search`);
                    break;
                case "InviteDetails":
                    modalImport = await
                    import (`../../InviteDetails/InviteDetails`);
                    break;
                case "Preview":
                    modalImport = await
                    import (`../../Preview/Preview`);
                    break;
                case "invitedGuests":
                    modalImport = await
                    import (`../../invitedGuests/invitedGuests`);
                    break;
                case "Wizard":
                    modalImport = await
                    import (`../../Wizard/Wizard`);
                    break;
                case "Colors":
                    modalImport = await
                    import (`../../Colors/Colors`);
                    break;
                case "ImageSelect":
                    modalImport = await
                    import (`../../ImageSelect/ImageSelect`);
                    break;
            }
            if (modalImport) {
                this.modalScreens[screenName] = modalImport[screenName];
            }
        }
        return this.modalScreens[screenName]
    }
};