/**
 * Models generated from "Model and Storage" and models extracted from services.
 * To generate entity use syntax:
 * Apperyio.EntityAPI("<model_name>[.<model_field>]");
 */
export var models = {
    "SlideModel_1": {
        "type": "object",
        "properties": {
            "title": {
                "type": "string"
            },
            "text": {
                "type": "string"
            },
            "img": {
                "type": "string"
            }
        }
    },
    "SlidesModel_0": {
        "type": "array",
        "items": [{
            "type": "SlideModel_0"
        }]
    },
    "String": {
        "type": "string"
    },
    "SlidesModel": {
        "type": "array",
        "items": [{
            "type": "SlideModel"
        }]
    },
    "SlideModel_3": {
        "type": "object",
        "properties": {
            "img": {
                "type": "string"
            },
            "text": {
                "type": "string"
            },
            "title": {
                "type": "string"
            }
        }
    },
    "SlidesModel_4": {
        "type": "array",
        "items": [{
            "type": "SlideModel_4"
        }]
    },
    "SlidesModel_2": {
        "type": "array",
        "items": [{
            "type": "SlideModel_2"
        }]
    },
    "Boolean": {
        "type": "boolean"
    },
    "SlideModel": {
        "type": "object",
        "properties": {
            "title": {
                "type": "string"
            },
            "img": {
                "type": "string"
            },
            "text": {
                "type": "string"
            }
        }
    },
    "SlideModel_2": {
        "type": "object",
        "properties": {
            "title": {
                "type": "string"
            },
            "img": {
                "type": "string"
            },
            "text": {
                "type": "string"
            }
        }
    },
    "SlidesModel_3": {
        "type": "array",
        "items": [{
            "type": "SlideModel_3"
        }]
    },
    "SlideModel_4": {
        "type": "object",
        "properties": {
            "text": {
                "type": "string"
            },
            "title": {
                "type": "string"
            },
            "img": {
                "type": "string"
            }
        }
    },
    "SlideModel_0": {
        "type": "object",
        "properties": {
            "img": {
                "type": "string"
            },
            "title": {
                "type": "string"
            },
            "text": {
                "type": "string"
            }
        }
    },
    "Number": {
        "type": "number"
    },
    "SlidesModel_1": {
        "type": "array",
        "items": [{
            "type": "SlideModel_1"
        }]
    },
    "Any": {
        "type": "any"
    },
    "Function": {
        "type": "Function"
    },
    "Promise": {
        "type": "Promise"
    },
    "Observable": {
        "type": "Observable"
    },
    "hostMessage": {
        "type": "object",
        "properties": {
            "url": {
                "type": "string",
                "default": "https://api.backendless.com/876B8C32-01E1-6474-FFA9-06AFA3F13500/D7DD8A50-2CE0-B869-FF19-BF856519F500/services/invites/hostmessage"
            },
            "method": {
                "type": "string",
                "default": "post"
            },
            "request": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {
                            "userId": {
                                "type": "string"
                            },
                            "message": {
                                "type": "string"
                            },
                            "replyButton": {
                                "type": "string"
                            },
                            "name": {
                                "type": "string"
                            },
                            "isVisible": {
                                "type": "boolean",
                                "default": null
                            },
                            "eventId": {
                                "type": "string"
                            },
                            "guests": {
                                "type": "string"
                            }
                        }
                    },
                    "query": {
                        "type": "object",
                        "properties": {}
                    },
                    "headers": {
                        "type": "object",
                        "properties": {
                            "user-token": {
                                "type": "string"
                            },
                            "Content-Type": {
                                "type": "string",
                                "default": "application/json"
                            }
                        }
                    }
                }
            },
            "response": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {}
                    },
                    "headers": {
                        "type": "object",
                        "properties": {}
                    }
                }
            }
        }
    },
    "getMyImages": {
        "type": "object",
        "properties": {
            "url": {
                "type": "string",
                "default": "https://api.backendless.com/876B8C32-01E1-6474-FFA9-06AFA3F13500/D7DD8A50-2CE0-B869-FF19-BF856519F500/data/partyImages?sortBy=created%20desc&pageSize={page}&offset={off}"
            },
            "method": {
                "type": "string",
                "default": "get"
            },
            "request": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {}
                    },
                    "query": {
                        "type": "object",
                        "properties": {
                            "page": {
                                "type": "string"
                            },
                            "off": {
                                "type": "string"
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {
                            "user-token": {
                                "type": "string"
                            }
                        }
                    }
                }
            },
            "response": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {
                            "$": {
                                "type": "array",
                                "items": [{
                                    "type": "object",
                                    "properties": {
                                        "ownerId": {
                                            "type": "null"
                                        },
                                        "objectId": {
                                            "type": "string",
                                            "default": "89323363-6E9A-414D-868E-E3A109FC61F3"
                                        },
                                        "updated": {
                                            "type": "number",
                                            "default": 1692245021526
                                        },
                                        "url": {
                                            "type": "string",
                                            "default": "https://backendlessappcontent.com/876B8C32-01E1-6474-FFA9-06AFA3F13500/D7DD8A50-2CE0-B869-FF19-BF856519F500/files/web/images/1.jpg"
                                        },
                                        "created": {
                                            "type": "number",
                                            "default": 1692242463785
                                        },
                                        "___class": {
                                            "type": "string",
                                            "default": "images"
                                        },
                                        "name": {
                                            "type": "string",
                                            "default": "Sample "
                                        },
                                        "base64": {
                                            "type": "null"
                                        },
                                        "keywords": {
                                            "type": "string",
                                            "default": "test"
                                        }
                                    }
                                }]
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {}
                    }
                }
            }
        }
    },
    "getMyImagesCount": {
        "type": "object",
        "properties": {
            "url": {
                "type": "string",
                "default": "https://api.backendless.com/876B8C32-01E1-6474-FFA9-06AFA3F13500/D7DD8A50-2CE0-B869-FF19-BF856519F500/data/partyImages/count?sortBy=created%20desc&pageSize=15&offset={off}"
            },
            "method": {
                "type": "string",
                "default": "get"
            },
            "request": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {}
                    },
                    "query": {
                        "type": "object",
                        "properties": {
                            "off": {
                                "type": "string"
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {
                            "user-token": {
                                "type": "string"
                            }
                        }
                    }
                }
            },
            "response": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {
                            "$": {
                                "type": "array",
                                "items": [{
                                    "type": "object",
                                    "properties": {
                                        "ownerId": {
                                            "type": "null"
                                        },
                                        "objectId": {
                                            "type": "string",
                                            "default": "89323363-6E9A-414D-868E-E3A109FC61F3"
                                        },
                                        "updated": {
                                            "type": "number",
                                            "default": 1692245021526
                                        },
                                        "url": {
                                            "type": "string",
                                            "default": "https://backendlessappcontent.com/876B8C32-01E1-6474-FFA9-06AFA3F13500/D7DD8A50-2CE0-B869-FF19-BF856519F500/files/web/images/1.jpg"
                                        },
                                        "created": {
                                            "type": "number",
                                            "default": 1692242463785
                                        },
                                        "___class": {
                                            "type": "string",
                                            "default": "images"
                                        },
                                        "name": {
                                            "type": "string",
                                            "default": "Sample "
                                        },
                                        "base64": {
                                            "type": "null"
                                        },
                                        "keywords": {
                                            "type": "string",
                                            "default": "test"
                                        }
                                    }
                                }]
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {}
                    }
                }
            }
        }
    },
    "deleteMyImage": {
        "type": "object",
        "properties": {
            "url": {
                "type": "string",
                "default": "https://api.backendless.com/876B8C32-01E1-6474-FFA9-06AFA3F13500/D7DD8A50-2CE0-B869-FF19-BF856519F500/data/partyImages/{id}"
            },
            "method": {
                "type": "string",
                "default": "delete"
            },
            "request": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {}
                    },
                    "query": {
                        "type": "object",
                        "properties": {
                            "id": {
                                "type": "string"
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {
                            "user-token": {
                                "type": "string"
                            }
                        }
                    }
                }
            },
            "response": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {
                            "$": {
                                "type": "object",
                                "properties": {}
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {}
                    }
                }
            }
        }
    },
    "getFrames": {
        "type": "object",
        "properties": {
            "url": {
                "type": "string",
                "default": "https://api.backendless.com/876B8C32-01E1-6474-FFA9-06AFA3F13500/D7DD8A50-2CE0-B869-FF19-BF856519F500/data/assets?where=keywords%20LIKE%20%27%25{terms}%25%27%20AND%20type%20%3D%20%27{type}%27&sortBy=premium%20asc%2Cname%20asc&pageSize=100"
            },
            "method": {
                "type": "string",
                "default": "get"
            },
            "request": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {}
                    },
                    "query": {
                        "type": "object",
                        "properties": {
                            "terms": {
                                "type": "string"
                            },
                            "type": {
                                "type": "string"
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {
                            "user-token": {
                                "type": "string"
                            }
                        }
                    }
                }
            },
            "response": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {
                            "$": {
                                "type": "array",
                                "items": [{
                                    "type": "object",
                                    "properties": {
                                        "ownerId": {
                                            "type": "null"
                                        },
                                        "objectId": {
                                            "type": "string",
                                            "default": "89323363-6E9A-414D-868E-E3A109FC61F3"
                                        },
                                        "updated": {
                                            "type": "number",
                                            "default": 1692245021526
                                        },
                                        "url": {
                                            "type": "string",
                                            "default": "https://backendlessappcontent.com/876B8C32-01E1-6474-FFA9-06AFA3F13500/D7DD8A50-2CE0-B869-FF19-BF856519F500/files/web/images/1.jpg"
                                        },
                                        "created": {
                                            "type": "number",
                                            "default": 1692242463785
                                        },
                                        "___class": {
                                            "type": "string",
                                            "default": "images"
                                        },
                                        "name": {
                                            "type": "string",
                                            "default": "Sample "
                                        },
                                        "base64": {
                                            "type": "null"
                                        },
                                        "keywords": {
                                            "type": "string",
                                            "default": "test"
                                        }
                                    }
                                }]
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {}
                    }
                }
            }
        }
    },
    "getTemplates": {
        "type": "object",
        "properties": {
            "url": {
                "type": "string",
                "default": "https://api.backendless.com/876B8C32-01E1-6474-FFA9-06AFA3F13500/D7DD8A50-2CE0-B869-FF19-BF856519F500/data/templates?where=keywords%20LIKE%20%27%25{terms}%25%27%20AND%20active%20%3D%20%27yes%27&pageSize={size}"
            },
            "method": {
                "type": "string",
                "default": "get"
            },
            "request": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {}
                    },
                    "query": {
                        "type": "object",
                        "properties": {
                            "size": {
                                "type": "string"
                            },
                            "terms": {
                                "type": "string"
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {
                            "user-token": {
                                "type": "string"
                            }
                        }
                    }
                }
            },
            "response": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {
                            "$": {
                                "type": "array",
                                "items": [{
                                    "type": "object",
                                    "properties": {
                                        "category": {
                                            "type": "null"
                                        },
                                        "cost": {
                                            "type": "number",
                                            "default": 1
                                        },
                                        "order": {
                                            "type": "null"
                                        },
                                        "active": {
                                            "type": "string",
                                            "default": "yes"
                                        },
                                        "keywords": {
                                            "type": "string",
                                            "default": "kids birthday"
                                        },
                                        "created": {
                                            "type": "number",
                                            "default": 1697588571459
                                        },
                                        "ownerId": {
                                            "type": "null"
                                        },
                                        "imageUrl": {
                                            "type": "null"
                                        },
                                        "updated": {
                                            "type": "number",
                                            "default": 1700183332564
                                        },
                                        "___class": {
                                            "type": "string",
                                            "default": "templates"
                                        },
                                        "objectId": {
                                            "type": "string",
                                            "default": "72921E8B-F38B-4435-B1C7-287D9F76487E"
                                        },
                                        "title": {
                                            "type": "string",
                                            "default": "Fishn' For Fifty"
                                        },
                                        "invitation": {
                                            "type": "object",
                                            "properties": {
                                                "puppetFrontVideo": {
                                                    "type": "string",
                                                    "default": "https://backendlessappcontent.com/876B8C32-01E1-6474-FFA9-06AFA3F13500/D7DD8A50-2CE0-B869-FF19-BF856519F500/files/videos/F4558A9A-0AA9-40CA-9752-7141B07D2A62_A3C336BA-12A0-4D8D-80BD-D4AD6420FB22.mp4#t=0.1#t=0.1#t=0.1#t=0.1#t=0.1#t=0.1#t=0.1"
                                                },
                                                "canvasFront": {
                                                    "type": "object",
                                                    "properties": {
                                                        "version": {
                                                            "type": "string",
                                                            "default": "5.3.0"
                                                        },
                                                        "objects": {
                                                            "type": "array",
                                                            "items": [{
                                                                "type": "object",
                                                                "properties": {
                                                                    "visible": {
                                                                        "type": "boolean",
                                                                        "default": true
                                                                    },
                                                                    "height": {
                                                                        "type": "number",
                                                                        "default": 61.02
                                                                    },
                                                                    "skewX": {
                                                                        "type": "number",
                                                                        "default": 0
                                                                    },
                                                                    "scaleY": {
                                                                        "type": "number",
                                                                        "default": 2.39
                                                                    },
                                                                    "fontStyle": {
                                                                        "type": "string",
                                                                        "default": "normal"
                                                                    },
                                                                    "top": {
                                                                        "type": "number",
                                                                        "default": 303.94
                                                                    },
                                                                    "originY": {
                                                                        "type": "string",
                                                                        "default": "top"
                                                                    },
                                                                    "stroke": {
                                                                        "type": "null"
                                                                    },
                                                                    "charSpacing": {
                                                                        "type": "number",
                                                                        "default": 0
                                                                    },
                                                                    "globalCompositeOperation": {
                                                                        "type": "string",
                                                                        "default": "source-over"
                                                                    },
                                                                    "fontWeight": {
                                                                        "type": "string",
                                                                        "default": "400"
                                                                    },
                                                                    "strokeDashOffset": {
                                                                        "type": "number",
                                                                        "default": 0
                                                                    },
                                                                    "fillRule": {
                                                                        "type": "string",
                                                                        "default": "nonzero"
                                                                    },
                                                                    "shadow": {
                                                                        "type": "object",
                                                                        "properties": {
                                                                            "color": {
                                                                                "type": "string",
                                                                                "default": "rgba(0,0,0,1)"
                                                                            },
                                                                            "offsetX": {
                                                                                "type": "number",
                                                                                "default": 0
                                                                            },
                                                                            "blur": {
                                                                                "type": "number",
                                                                                "default": 0
                                                                            },
                                                                            "affectStroke": {
                                                                                "type": "boolean",
                                                                                "default": false
                                                                            },
                                                                            "nonScaling": {
                                                                                "type": "boolean",
                                                                                "default": false
                                                                            },
                                                                            "offsetY": {
                                                                                "type": "number",
                                                                                "default": 0
                                                                            }
                                                                        }
                                                                    },
                                                                    "version": {
                                                                        "type": "string",
                                                                        "default": "5.3.0"
                                                                    },
                                                                    "skewY": {
                                                                        "type": "number",
                                                                        "default": 0
                                                                    },
                                                                    "width": {
                                                                        "type": "number",
                                                                        "default": 87.71
                                                                    },
                                                                    "paintFirst": {
                                                                        "type": "string",
                                                                        "default": "fill"
                                                                    },
                                                                    "left": {
                                                                        "type": "number",
                                                                        "default": 99.79
                                                                    },
                                                                    "originX": {
                                                                        "type": "string",
                                                                        "default": "left"
                                                                    },
                                                                    "type": {
                                                                        "type": "string",
                                                                        "default": "textbox"
                                                                    },
                                                                    "splitByGrapheme": {
                                                                        "type": "boolean",
                                                                        "default": false
                                                                    },
                                                                    "lineHeight": {
                                                                        "type": "number",
                                                                        "default": 1
                                                                    },
                                                                    "path": {
                                                                        "type": "null"
                                                                    },
                                                                    "pathSide": {
                                                                        "type": "string",
                                                                        "default": "left"
                                                                    },
                                                                    "backgroundColor": {
                                                                        "type": "string"
                                                                    },
                                                                    "flipY": {
                                                                        "type": "boolean",
                                                                        "default": false
                                                                    },
                                                                    "pathStartOffset": {
                                                                        "type": "number",
                                                                        "default": 0
                                                                    },
                                                                    "strokeLineJoin": {
                                                                        "type": "string",
                                                                        "default": "miter"
                                                                    },
                                                                    "strokeWidth": {
                                                                        "type": "number",
                                                                        "default": 1
                                                                    },
                                                                    "linethrough": {
                                                                        "type": "boolean",
                                                                        "default": false
                                                                    },
                                                                    "minWidth": {
                                                                        "type": "number",
                                                                        "default": 20
                                                                    },
                                                                    "text": {
                                                                        "type": "string",
                                                                        "default": "Type something awesome!"
                                                                    },
                                                                    "opacity": {
                                                                        "type": "number",
                                                                        "default": 1
                                                                    },
                                                                    "fill": {
                                                                        "type": "string",
                                                                        "default": "#FFB6C1"
                                                                    },
                                                                    "direction": {
                                                                        "type": "string",
                                                                        "default": "ltr"
                                                                    },
                                                                    "overline": {
                                                                        "type": "boolean",
                                                                        "default": false
                                                                    },
                                                                    "fontSize": {
                                                                        "type": "number",
                                                                        "default": 18
                                                                    },
                                                                    "strokeMiterLimit": {
                                                                        "type": "number",
                                                                        "default": 4
                                                                    },
                                                                    "textAlign": {
                                                                        "type": "string",
                                                                        "default": "left"
                                                                    },
                                                                    "fontFamily": {
                                                                        "type": "string",
                                                                        "default": "quicksand"
                                                                    },
                                                                    "textBackgroundColor": {
                                                                        "type": "string"
                                                                    },
                                                                    "strokeDashArray": {
                                                                        "type": "null"
                                                                    },
                                                                    "strokeUniform": {
                                                                        "type": "boolean",
                                                                        "default": false
                                                                    },
                                                                    "pathAlign": {
                                                                        "type": "string",
                                                                        "default": "baseline"
                                                                    },
                                                                    "angle": {
                                                                        "type": "number",
                                                                        "default": 0
                                                                    },
                                                                    "styles": {
                                                                        "type": "array",
                                                                        "items": [{
                                                                            "type": "null"
                                                                        }]
                                                                    },
                                                                    "flipX": {
                                                                        "type": "boolean",
                                                                        "default": false
                                                                    },
                                                                    "scaleX": {
                                                                        "type": "number",
                                                                        "default": 2.39
                                                                    },
                                                                    "strokeLineCap": {
                                                                        "type": "string",
                                                                        "default": "butt"
                                                                    },
                                                                    "underline": {
                                                                        "type": "boolean",
                                                                        "default": false
                                                                    }
                                                                }
                                                            }]
                                                        }
                                                    }
                                                },
                                                "titleText": {
                                                    "type": "string",
                                                    "default": "gfggv"
                                                },
                                                "puppetBackFrameShow": {
                                                    "type": "string",
                                                    "default": "hideGrid"
                                                },
                                                "showCardBackBackgroundVideoUrl": {
                                                    "type": "string",
                                                    "default": "hideGrid"
                                                },
                                                "eventTime": {
                                                    "type": "string",
                                                    "default": "2023-11-15T13:20:00-08:00"
                                                },
                                                "puppetFrontPosition": {
                                                    "type": "string",
                                                    "default": "vclassbottom"
                                                },
                                                "puppetFrontPoster": {
                                                    "type": "string",
                                                    "default": "https://backendlessappcontent.com/876B8C32-01E1-6474-FFA9-06AFA3F13500/D7DD8A50-2CE0-B869-FF19-BF856519F500/files/posters/F4558A9A-0AA9-40CA-9752-7141B07D2A62_A3C336BA-12A0-4D8D-80BD-D4AD6420FB22.jpg"
                                                },
                                                "puppetFrontFrameClass": {
                                                    "type": "string",
                                                    "default": "videoGrid"
                                                },
                                                "showCardFrontBackgroundVideoUrl": {
                                                    "type": "string",
                                                    "default": "hideGrid"
                                                },
                                                "puppetFrontFramePosition": {
                                                    "type": "string",
                                                    "default": "puppetframebottom"
                                                },
                                                "showFrontPuppet": {
                                                    "type": "string",
                                                    "default": "showGrid"
                                                },
                                                "eventDate": {
                                                    "type": "string",
                                                    "default": "2023-11-22T19:19:00-08:00"
                                                },
                                                "eventDateString": {
                                                    "type": "string",
                                                    "default": "Nov 22, 2023"
                                                },
                                                "puppetBackFrameClass": {
                                                    "type": "string"
                                                },
                                                "mainbackgroundvideourl": {
                                                    "type": "string"
                                                },
                                                "canvasBack": {
                                                    "type": "object",
                                                    "properties": {
                                                        "objects": {
                                                            "type": "array",
                                                            "items": [{
                                                                "type": "null"
                                                            }]
                                                        },
                                                        "version": {
                                                            "type": "string",
                                                            "default": "5.3.0"
                                                        }
                                                    }
                                                },
                                                "puppetBackVideo": {
                                                    "type": "string",
                                                    "default": "#t=0.1#t=0.1#t=0.1#t=0.1#t=0.1#t=0.1#t=0.1"
                                                },
                                                "zip": {
                                                    "type": "string"
                                                },
                                                "mainbackgroundgifurl": {
                                                    "type": "string"
                                                },
                                                "giftRegistry": {
                                                    "type": "array",
                                                    "items": [{
                                                        "type": "null"
                                                    }]
                                                },
                                                "cardFrontBackground": {
                                                    "type": "string",
                                                    "default": "background-image: url('https://backendlessappcontent.com/876B8C32-01E1-6474-FFA9-06AFA3F13500/D7DD8A50-2CE0-B869-FF19-BF856519F500/files/web/cardBacks/21.png');background-size:cover;background-repeat:no-repeat;background-position: center center;"
                                                },
                                                "state": {
                                                    "type": "string"
                                                },
                                                "street": {
                                                    "type": "string"
                                                },
                                                "cardFrontBackgroundGifUrl": {
                                                    "type": "string"
                                                },
                                                "canvasBackImage": {
                                                    "type": "string",
                                                    "default": "https://backendlessappcontent.com/876B8C32-01E1-6474-FFA9-06AFA3F13500/03B6F34E-6A69-45E0-8B01-E7ADEC41AC12/files/canvas/F4558A9A-0AA9-40CA-9752-7141B07D2A62-1700104944232-back.png"
                                                },
                                                "envelopeColor": {
                                                    "type": "string",
                                                    "default": "YELLOWenvelope"
                                                },
                                                "eventTimeString": {
                                                    "type": "string",
                                                    "default": "1:20 PM"
                                                },
                                                "cardBackBackgroundGifUrl": {
                                                    "type": "string"
                                                },
                                                "eventOptions": {
                                                    "type": "array",
                                                    "items": [{
                                                        "type": "null"
                                                    }]
                                                },
                                                "puppetBackPosition": {
                                                    "type": "string"
                                                },
                                                "mainBackgroundUrl": {
                                                    "type": "string"
                                                },
                                                "cardFrontBackgroundVideoUrl": {
                                                    "type": "string"
                                                },
                                                "hostedByEmail": {
                                                    "type": "string",
                                                    "default": "seansean@puppetpartyapp.com"
                                                },
                                                "canvasFrontImageShort": {
                                                    "type": "string",
                                                    "default": "F4558A9A-0AA9-40CA-9752-7141B07D2A62-1700104944232-front"
                                                },
                                                "puppetFrontFrameShow": {
                                                    "type": "string",
                                                    "default": "hideGrid"
                                                },
                                                "cardBackBackgroundVideoUrl": {
                                                    "type": "string"
                                                },
                                                "hasBack": {
                                                    "type": "boolean",
                                                    "default": false
                                                },
                                                "city": {
                                                    "type": "string"
                                                },
                                                "canvasBackImageShort": {
                                                    "type": "string",
                                                    "default": "F4558A9A-0AA9-40CA-9752-7141B07D2A62-1700104944232-back"
                                                },
                                                "puppetBackFrameUrl": {
                                                    "type": "string"
                                                },
                                                "canvasFrontImage": {
                                                    "type": "string",
                                                    "default": "https://backendlessappcontent.com/876B8C32-01E1-6474-FFA9-06AFA3F13500/03B6F34E-6A69-45E0-8B01-E7ADEC41AC12/files/canvas/F4558A9A-0AA9-40CA-9752-7141B07D2A62-1700104944232-front.png"
                                                },
                                                "cardBackBackground": {
                                                    "type": "string"
                                                },
                                                "hostedByPhone": {
                                                    "type": "string",
                                                    "default": "(111) 111 - 1111"
                                                },
                                                "showBackPuppet": {
                                                    "type": "string",
                                                    "default": "hideGrid"
                                                },
                                                "puppetBackPoster": {
                                                    "type": "string"
                                                },
                                                "puppetFrontFrameUrl": {
                                                    "type": "string",
                                                    "default": "https://backendlessappcontent.com/876B8C32-01E1-6474-FFA9-06AFA3F13500/D7DD8A50-2CE0-B869-FF19-BF856519F500/files/web/data-2.png"
                                                },
                                                "locationName": {
                                                    "type": "string"
                                                },
                                                "hostedByName": {
                                                    "type": "string",
                                                    "default": "test"
                                                },
                                                "showMainBackgroundVideo": {
                                                    "type": "string",
                                                    "default": "hideGrid"
                                                },
                                                "note": {
                                                    "type": "string"
                                                }
                                            }
                                        }
                                    }
                                }]
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {}
                    }
                }
            }
        }
    },
    "getSettings": {
        "type": "object",
        "properties": {
            "url": {
                "type": "string",
                "default": "https://api.backendless.com/876B8C32-01E1-6474-FFA9-06AFA3F13500/D7DD8A50-2CE0-B869-FF19-BF856519F500/data/partySettings/87429FDD-C7C4-41B2-A5B9-CBD56850F0C3"
            },
            "method": {
                "type": "string",
                "default": "get"
            },
            "request": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {}
                    },
                    "query": {
                        "type": "object",
                        "properties": {}
                    },
                    "headers": {
                        "type": "object",
                        "properties": {}
                    }
                }
            },
            "response": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {
                            "$": {
                                "type": "object",
                                "properties": {
                                    "created": {
                                        "type": "number",
                                        "default": 1705092190373
                                    },
                                    "ownerId": {
                                        "type": "null"
                                    },
                                    "envelopeLiners": {
                                        "type": "array",
                                        "items": [{
                                            "type": "object",
                                            "properties": {
                                                "style": {
                                                    "type": "string",
                                                    "default": "background-image: url('https://backendlessappcontent.com/876B8C32-01E1-6474-FFA9-06AFA3F13500/D7DD8A50-2CE0-B869-FF19-BF856519F500/files/web/cardBacks/21.png');background-size:cover;background-repeat:no-repeat;background-position: center center;"
                                                }
                                            }
                                        }]
                                    },
                                    "___class": {
                                        "type": "string",
                                        "default": "partySettings"
                                    },
                                    "envelopeColors": {
                                        "type": "array",
                                        "items": [{
                                            "type": "object",
                                            "properties": {
                                                "families": {
                                                    "type": "array",
                                                    "items": [{
                                                        "type": "string",
                                                        "default": "black"
                                                    }]
                                                },
                                                "hex": {
                                                    "type": "string",
                                                    "default": "#000000"
                                                },
                                                "name": {
                                                    "type": "string",
                                                    "default": "BLACK"
                                                },
                                                "rgb": {
                                                    "type": "string",
                                                    "default": "RGB(0, 0, 0)"
                                                }
                                            }
                                        }]
                                    },
                                    "objectId": {
                                        "type": "string",
                                        "default": "87429FDD-C7C4-41B2-A5B9-CBD56850F0C3"
                                    },
                                    "updated": {
                                        "type": "number",
                                        "default": 1705092672866
                                    }
                                }
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {}
                    }
                }
            }
        }
    },
    "ip": {
        "type": "object",
        "properties": {
            "url": {
                "type": "string",
                "default": ""
            },
            "method": {
                "type": "string",
                "default": "get"
            },
            "request": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {}
                    },
                    "query": {
                        "type": "object",
                        "properties": {}
                    },
                    "headers": {
                        "type": "object",
                        "properties": {}
                    }
                }
            },
            "response": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {
                            "$": {
                                "type": "object",
                                "properties": {}
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {}
                    }
                }
            }
        }
    },
    "getContactsCount": {
        "type": "object",
        "properties": {
            "url": {
                "type": "string",
                "default": "https://api.backendless.com/876B8C32-01E1-6474-FFA9-06AFA3F13500/D7DD8A50-2CE0-B869-FF19-BF856519F500/data/partyContacts/count?sortBy=name%20asc"
            },
            "method": {
                "type": "string",
                "default": "get"
            },
            "request": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {}
                    },
                    "query": {
                        "type": "object",
                        "properties": {
                            "off": {
                                "type": "string"
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {
                            "user-token": {
                                "type": "string"
                            }
                        }
                    }
                }
            },
            "response": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {
                            "$": {
                                "type": "array",
                                "items": [{
                                    "type": "object",
                                    "properties": {}
                                }]
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {}
                    }
                }
            }
        }
    },
    "confirmActivate": {
        "type": "object",
        "properties": {
            "url": {
                "type": "string",
                "default": "https://api.backendless.com/876B8C32-01E1-6474-FFA9-06AFA3F13500/D7DD8A50-2CE0-B869-FF19-BF856519F500/services/resetflow/confirmactivate"
            },
            "method": {
                "type": "string",
                "default": "post"
            },
            "request": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {
                            "email": {
                                "type": "string"
                            },
                            "code": {
                                "type": "string"
                            }
                        }
                    },
                    "query": {
                        "type": "object",
                        "properties": {}
                    },
                    "headers": {
                        "type": "object",
                        "properties": {
                            "Content-Type": {
                                "type": "string",
                                "default": "application/json"
                            }
                        }
                    }
                }
            },
            "response": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {}
                    },
                    "headers": {
                        "type": "object",
                        "properties": {}
                    }
                }
            }
        }
    },
    "updateUser": {
        "type": "object",
        "properties": {
            "url": {
                "type": "string",
                "default": "https://api.backendless.com/876B8C32-01E1-6474-FFA9-06AFA3F13500/D7DD8A50-2CE0-B869-FF19-BF856519F500/users/{user}"
            },
            "method": {
                "type": "string",
                "default": "put"
            },
            "request": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {
                            "phone": {
                                "type": "string"
                            },
                            "marketing": {
                                "type": "string"
                            },
                            "email": {
                                "type": "string"
                            }
                        }
                    },
                    "query": {
                        "type": "object",
                        "properties": {
                            "user": {
                                "type": "string"
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {
                            "Content-Type": {
                                "type": "string",
                                "default": "application/json"
                            },
                            "user-token": {
                                "type": "string"
                            }
                        }
                    }
                }
            },
            "response": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {}
                    },
                    "headers": {
                        "type": "object",
                        "properties": {}
                    }
                }
            }
        }
    },
    "getAccount": {
        "type": "object",
        "properties": {
            "url": {
                "type": "string",
                "default": "https://api.backendless.com/876B8C32-01E1-6474-FFA9-06AFA3F13500/D7DD8A50-2CE0-B869-FF19-BF856519F500/users/{user}"
            },
            "method": {
                "type": "string",
                "default": "get"
            },
            "request": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {}
                    },
                    "query": {
                        "type": "object",
                        "properties": {
                            "user": {
                                "type": "string"
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {
                            "user-token": {
                                "type": "string"
                            }
                        }
                    }
                }
            },
            "response": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {
                            "$": {
                                "type": "object",
                                "properties": {
                                    "socialAccount": {
                                        "type": "string",
                                        "default": "BACKENDLESS"
                                    },
                                    "renew": {
                                        "type": "string",
                                        "default": "none"
                                    },
                                    "userStatus": {
                                        "type": "string",
                                        "default": "ENABLED"
                                    },
                                    "partySubId": {
                                        "type": "string"
                                    },
                                    "usertype": {
                                        "type": "string",
                                        "default": "regular"
                                    },
                                    "accountType": {
                                        "type": "string",
                                        "default": "BACKENDLESS"
                                    },
                                    "account": {
                                        "type": "string",
                                        "default": "regular"
                                    },
                                    "___class": {
                                        "type": "string",
                                        "default": "Users"
                                    },
                                    "name": {
                                        "type": "null"
                                    },
                                    "lastLogin": {
                                        "type": "number",
                                        "default": 1657220470000
                                    },
                                    "oAuthIdentities": {
                                        "type": "null"
                                    },
                                    "digits": {
                                        "type": "string",
                                        "default": "none"
                                    },
                                    "blUserLocale": {
                                        "type": "string",
                                        "default": "en"
                                    },
                                    "subId": {
                                        "type": "string",
                                        "default": "none"
                                    },
                                    "created": {
                                        "type": "number",
                                        "default": 1656095883868
                                    },
                                    "email": {
                                        "type": "string",
                                        "default": "nasa@puppetchat.com"
                                    },
                                    "objectId": {
                                        "type": "string",
                                        "default": "A44C2377-5788-406B-9600-FEFAE4FB6AE0"
                                    },
                                    "marketing": {
                                        "type": "string",
                                        "default": "yes"
                                    },
                                    "lastVideo": {
                                        "type": "null"
                                    },
                                    "ownerId": {
                                        "type": "string",
                                        "default": "A44C2377-5788-406B-9600-FEFAE4FB6AE0"
                                    },
                                    "phone": {
                                        "type": "null"
                                    },
                                    "cusId": {
                                        "type": "string",
                                        "default": "freenew"
                                    },
                                    "updated": {
                                        "type": "null"
                                    }
                                }
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {}
                    }
                }
            }
        }
    },
    "getVideos": {
        "type": "object",
        "properties": {
            "url": {
                "type": "string",
                "default": "https://api.backendless.com/876B8C32-01E1-6474-FFA9-06AFA3F13500/D7DD8A50-2CE0-B869-FF19-BF856519F500/data/sessions?pageSize=100&sortBy=created%20desc%2C"
            },
            "method": {
                "type": "string",
                "default": "get"
            },
            "request": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {}
                    },
                    "query": {
                        "type": "object",
                        "properties": {}
                    },
                    "headers": {
                        "type": "object",
                        "properties": {
                            "user-token": {
                                "type": "string"
                            }
                        }
                    }
                }
            },
            "response": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {
                            "$": {
                                "type": "array",
                                "items": [{
                                    "type": "object",
                                    "properties": {
                                        "subId": {
                                            "type": "null"
                                        },
                                        "updated": {
                                            "type": "number",
                                            "default": 1657114320426
                                        },
                                        "sceneImage": {
                                            "type": "null"
                                        },
                                        "userId": {
                                            "type": "null"
                                        },
                                        "audioFile": {
                                            "type": "string",
                                            "default": "https://backendlessappcontent.com/876B8C32-01E1-6474-FFA9-06AFA3F13500/CD988E3D-5002-2905-FFBA-738AC6F82300/files/audios/none.A44C2377-5788-406B-9600-FEFAE4FB6AE0_beachsunsetmp4-c+90_1657114247615.wav"
                                        },
                                        "voice": {
                                            "type": "null"
                                        },
                                        "title": {
                                            "type": "null"
                                        },
                                        "timeId": {
                                            "type": "string",
                                            "default": "C13837B6-F27F-43A2-AA0C-E721DCD8CA2A"
                                        },
                                        "languageImage": {
                                            "type": "null"
                                        },
                                        "objectId": {
                                            "type": "string",
                                            "default": "3B56DF1B-258B-43E0-BB07-083AD775A306"
                                        },
                                        "duration": {
                                            "type": "null"
                                        },
                                        "ownerId": {
                                            "type": "string",
                                            "default": "A44C2377-5788-406B-9600-FEFAE4FB6AE0"
                                        },
                                        "created": {
                                            "type": "number",
                                            "default": 1657114278436
                                        },
                                        "videoUrl": {
                                            "type": "string",
                                            "default": "https://backendlessappcontent.com/876B8C32-01E1-6474-FFA9-06AFA3F13500/D7DD8A50-2CE0-B869-FF19-BF856519F500/files/videos/A44C2377-5788-406B-9600-FEFAE4FB6AE0_3B56DF1B-258B-43E0-BB07-083AD775A306.mp4"
                                        },
                                        "puppetImage": {
                                            "type": "string",
                                            "default": "https://www.app.puppetchat.com/files/previews/handfsquare.png"
                                        },
                                        "___class": {
                                            "type": "string",
                                            "default": "sessions"
                                        },
                                        "puppetName": {
                                            "type": "string",
                                            "default": "Hank"
                                        },
                                        "sceneId": {
                                            "type": "null"
                                        },
                                        "puppetId": {
                                            "type": "string",
                                            "default": "92D9105D-48BE-4AFE-BAF9-ED2E396F9FFD"
                                        }
                                    }
                                }]
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {}
                    }
                }
            }
        }
    },
    "getVideo": {
        "type": "object",
        "properties": {
            "url": {
                "type": "string",
                "default": "https://api.backendless.com/876B8C32-01E1-6474-FFA9-06AFA3F13500/D7DD8A50-2CE0-B869-FF19-BF856519F500/data/sessions/{id}"
            },
            "method": {
                "type": "string",
                "default": "get"
            },
            "request": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {}
                    },
                    "query": {
                        "type": "object",
                        "properties": {
                            "id": {
                                "type": "string"
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {
                            "user-token": {
                                "type": "string"
                            }
                        }
                    }
                }
            },
            "response": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {
                            "$": {
                                "type": "object",
                                "properties": {}
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {}
                    }
                }
            }
        }
    },
    "getImages": {
        "type": "object",
        "properties": {
            "url": {
                "type": "string",
                "default": "https://api.backendless.com/876B8C32-01E1-6474-FFA9-06AFA3F13500/D7DD8A50-2CE0-B869-FF19-BF856519F500/data/assets?where=keywords%20LIKE%20%27%25{terms}%25%27%20AND%20type%20LIKE%20%27%25{type}%27&pageSize={page}&offset={off}"
            },
            "method": {
                "type": "string",
                "default": "get"
            },
            "request": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {}
                    },
                    "query": {
                        "type": "object",
                        "properties": {
                            "terms": {
                                "type": "string"
                            },
                            "type": {
                                "type": "string"
                            },
                            "off": {
                                "type": "string"
                            },
                            "page": {
                                "type": "string"
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {
                            "user-token": {
                                "type": "string"
                            }
                        }
                    }
                }
            },
            "response": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {
                            "$": {
                                "type": "array",
                                "items": [{
                                    "type": "object",
                                    "properties": {
                                        "ownerId": {
                                            "type": "null"
                                        },
                                        "objectId": {
                                            "type": "string",
                                            "default": "89323363-6E9A-414D-868E-E3A109FC61F3"
                                        },
                                        "updated": {
                                            "type": "number",
                                            "default": 1692245021526
                                        },
                                        "url": {
                                            "type": "string",
                                            "default": "https://backendlessappcontent.com/876B8C32-01E1-6474-FFA9-06AFA3F13500/D7DD8A50-2CE0-B869-FF19-BF856519F500/files/web/images/1.jpg"
                                        },
                                        "created": {
                                            "type": "number",
                                            "default": 1692242463785
                                        },
                                        "___class": {
                                            "type": "string",
                                            "default": "images"
                                        },
                                        "name": {
                                            "type": "string",
                                            "default": "Sample "
                                        },
                                        "base64": {
                                            "type": "null"
                                        },
                                        "keywords": {
                                            "type": "string",
                                            "default": "test"
                                        }
                                    }
                                }]
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {}
                    }
                }
            }
        }
    },
    "getPuppets": {
        "type": "object",
        "properties": {
            "url": {
                "type": "string",
                "default": "https://api.backendless.com/876B8C32-01E1-6474-FFA9-06AFA3F13500/D7DD8A50-2CE0-B869-FF19-BF856519F500/data/puppets?where=active%20%3D%20%27green%27%20OR%20active%20%3D%20%27yellow%27&pageSize=100"
            },
            "method": {
                "type": "string",
                "default": "get"
            },
            "request": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {}
                    },
                    "query": {
                        "type": "object",
                        "properties": {}
                    },
                    "headers": {
                        "type": "object",
                        "properties": {
                            "user-token": {
                                "type": "string"
                            }
                        }
                    }
                }
            },
            "response": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {
                            "$": {
                                "type": "array",
                                "items": [{
                                    "type": "object",
                                    "properties": {
                                        "description": {
                                            "type": "null"
                                        },
                                        "___class": {
                                            "type": "string",
                                            "default": "puppets"
                                        },
                                        "premium": {
                                            "type": "string",
                                            "default": "yes"
                                        },
                                        "order": {
                                            "type": "string",
                                            "default": "90"
                                        },
                                        "backgrounds": {
                                            "type": "array",
                                            "items": [{
                                                "type": "null"
                                            }]
                                        },
                                        "name": {
                                            "type": "string",
                                            "default": "Rhonda"
                                        },
                                        "defaultVideoWeb": {
                                            "type": "string",
                                            "default": "files/previews/rhonda.mp4"
                                        },
                                        "objectId": {
                                            "type": "string",
                                            "default": "C1F243A1-C54D-4834-8FC4-68F4282E0079"
                                        },
                                        "updated": {
                                            "type": "number",
                                            "default": 1654003618000
                                        },
                                        "sfront": {
                                            "type": "string",
                                            "default": "none"
                                        },
                                        "active": {
                                            "type": "string",
                                            "default": "green"
                                        },
                                        "hasGreen": {
                                            "type": "null"
                                        },
                                        "image": {
                                            "type": "string",
                                            "default": "https://www.app.puppetchat.com/files/previews/rhondasquare2.png"
                                        },
                                        "showLeft": {
                                            "type": "string"
                                        },
                                        "defaultVideo": {
                                            "type": "string",
                                            "default": "https://www.app.puppetchat.com/files/previews/rhonda2.png"
                                        },
                                        "multiCharacter": {
                                            "type": "null"
                                        },
                                        "showRight": {
                                            "type": "string"
                                        },
                                        "foregrounds": {
                                            "type": "array",
                                            "items": [{
                                                "type": "null"
                                            }]
                                        },
                                        "ownerId": {
                                            "type": "null"
                                        },
                                        "shrub": {
                                            "type": "null"
                                        },
                                        "created": {
                                            "type": "number",
                                            "default": 1612238413000
                                        },
                                        "poster": {
                                            "type": "null"
                                        },
                                        "sback": {
                                            "type": "string",
                                            "default": "horsemp4"
                                        }
                                    }
                                }]
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {}
                    }
                }
            }
        }
    },
    "checkUser": {
        "type": "object",
        "properties": {
            "url": {
                "type": "string",
                "default": "https://api.backendless.com/876B8C32-01E1-6474-FFA9-06AFA3F13500/D7DD8A50-2CE0-B869-FF19-BF856519F500/users/{user}"
            },
            "method": {
                "type": "string",
                "default": "get"
            },
            "request": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {}
                    },
                    "query": {
                        "type": "object",
                        "properties": {
                            "user": {
                                "type": "string"
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {
                            "user-token": {
                                "type": "string"
                            }
                        }
                    }
                }
            },
            "response": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {
                            "$": {
                                "type": "object",
                                "properties": {
                                    "lastLogin": {
                                        "type": "number",
                                        "default": 1656100352000
                                    },
                                    "renew": {
                                        "type": "string",
                                        "default": "none"
                                    },
                                    "created": {
                                        "type": "number",
                                        "default": 1656095883868
                                    },
                                    "userStatus": {
                                        "type": "string",
                                        "default": "ENABLED"
                                    },
                                    "marketing": {
                                        "type": "string",
                                        "default": "yes"
                                    },
                                    "phone": {
                                        "type": "null"
                                    },
                                    "updated": {
                                        "type": "null"
                                    },
                                    "name": {
                                        "type": "null"
                                    },
                                    "objectId": {
                                        "type": "string",
                                        "default": "A44C2377-5788-406B-9600-FEFAE4FB6AE0"
                                    },
                                    "___class": {
                                        "type": "string",
                                        "default": "Users"
                                    },
                                    "usertype": {
                                        "type": "string",
                                        "default": "regular"
                                    },
                                    "oAuthIdentities": {
                                        "type": "null"
                                    },
                                    "ownerId": {
                                        "type": "string",
                                        "default": "A44C2377-5788-406B-9600-FEFAE4FB6AE0"
                                    },
                                    "blUserLocale": {
                                        "type": "string",
                                        "default": "en"
                                    },
                                    "email": {
                                        "type": "string",
                                        "default": "nasa@puppetchat.com"
                                    },
                                    "socialAccount": {
                                        "type": "string",
                                        "default": "BACKENDLESS"
                                    },
                                    "lastVideo": {
                                        "type": "null"
                                    },
                                    "digits": {
                                        "type": "string",
                                        "default": "none"
                                    },
                                    "cusId": {
                                        "type": "string",
                                        "default": "freenew"
                                    },
                                    "subId": {
                                        "type": "string",
                                        "default": "none"
                                    },
                                    "accountType": {
                                        "type": "string",
                                        "default": "BACKENDLESS"
                                    },
                                    "account": {
                                        "type": "string",
                                        "default": "regular"
                                    }
                                }
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {}
                    }
                }
            }
        }
    },
    "getImagesCount": {
        "type": "object",
        "properties": {
            "url": {
                "type": "string",
                "default": "https://api.backendless.com/876B8C32-01E1-6474-FFA9-06AFA3F13500/D7DD8A50-2CE0-B869-FF19-BF856519F500/data/assets/count?where=keywords%20LIKE%20%27%25{terms}%25%27%20AND%20type%20%3D%20%27{type}%27&sortBy=premium%20asc%2Cname%20asc&pageSize=10&offset={off}"
            },
            "method": {
                "type": "string",
                "default": "get"
            },
            "request": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {}
                    },
                    "query": {
                        "type": "object",
                        "properties": {
                            "terms": {
                                "type": "string"
                            },
                            "type": {
                                "type": "string"
                            },
                            "off": {
                                "type": "string"
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {
                            "user-token": {
                                "type": "string"
                            }
                        }
                    }
                }
            },
            "response": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {
                            "$": {
                                "type": "array",
                                "items": [{
                                    "type": "object",
                                    "properties": {
                                        "ownerId": {
                                            "type": "null"
                                        },
                                        "objectId": {
                                            "type": "string",
                                            "default": "89323363-6E9A-414D-868E-E3A109FC61F3"
                                        },
                                        "updated": {
                                            "type": "number",
                                            "default": 1692245021526
                                        },
                                        "url": {
                                            "type": "string",
                                            "default": "https://backendlessappcontent.com/876B8C32-01E1-6474-FFA9-06AFA3F13500/D7DD8A50-2CE0-B869-FF19-BF856519F500/files/web/images/1.jpg"
                                        },
                                        "created": {
                                            "type": "number",
                                            "default": 1692242463785
                                        },
                                        "___class": {
                                            "type": "string",
                                            "default": "images"
                                        },
                                        "name": {
                                            "type": "string",
                                            "default": "Sample "
                                        },
                                        "base64": {
                                            "type": "null"
                                        },
                                        "keywords": {
                                            "type": "string",
                                            "default": "test"
                                        }
                                    }
                                }]
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {}
                    }
                }
            }
        }
    },
    "login": {
        "type": "object",
        "properties": {
            "url": {
                "type": "string",
                "default": "https://api.backendless.com/876B8C32-01E1-6474-FFA9-06AFA3F13500/D7DD8A50-2CE0-B869-FF19-BF856519F500/users/login"
            },
            "method": {
                "type": "string",
                "default": "post"
            },
            "request": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {
                            "login": {
                                "type": "string"
                            },
                            "password": {
                                "type": "string"
                            }
                        }
                    },
                    "query": {
                        "type": "object",
                        "properties": {}
                    },
                    "headers": {
                        "type": "object",
                        "properties": {
                            "Content-Type": {
                                "type": "string",
                                "default": "application/json"
                            }
                        }
                    }
                }
            },
            "response": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {
                            "socialAccount": {
                                "type": "string",
                                "default": "BACKENDLESS"
                            },
                            "account": {
                                "type": "string",
                                "default": "regular"
                            },
                            "accountType": {
                                "type": "string",
                                "default": "BACKENDLESS"
                            },
                            "___class": {
                                "type": "string",
                                "default": "Users"
                            },
                            "lastVideo": {
                                "type": "null"
                            },
                            "subId": {
                                "type": "string",
                                "default": "none"
                            },
                            "created": {
                                "type": "number",
                                "default": 1656090810543
                            },
                            "oAuthIdentities": {
                                "type": "null"
                            },
                            "email": {
                                "type": "string",
                                "default": "nasa@puppetchat.com"
                            },
                            "name": {
                                "type": "null"
                            },
                            "userStatus": {
                                "type": "string",
                                "default": "ENABLED"
                            },
                            "objectId": {
                                "type": "string",
                                "default": "BC078990-3B13-4697-B60C-EE240C909754"
                            },
                            "phone": {
                                "type": "null"
                            },
                            "digits": {
                                "type": "string",
                                "default": "none"
                            },
                            "blUserLocale": {
                                "type": "string",
                                "default": "en"
                            },
                            "user-token": {
                                "type": "string",
                                "default": "2BFA2FBB-9362-4DD9-8C54-DD6B5C4838BE"
                            },
                            "renew": {
                                "type": "string",
                                "default": "none"
                            },
                            "cusId": {
                                "type": "string",
                                "default": "freenew"
                            },
                            "marketing": {
                                "type": "string",
                                "default": "yes"
                            },
                            "ownerId": {
                                "type": "string",
                                "default": "BC078990-3B13-4697-B60C-EE240C909754"
                            },
                            "lastLogin": {
                                "type": "number",
                                "default": 1656093255505
                            },
                            "usertype": {
                                "type": "string",
                                "default": "regular"
                            },
                            "updated": {
                                "type": "null"
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {}
                    }
                }
            }
        }
    },
    "register": {
        "type": "object",
        "properties": {
            "url": {
                "type": "string",
                "default": "https://api.backendless.com/876B8C32-01E1-6474-FFA9-06AFA3F13500/D7DD8A50-2CE0-B869-FF19-BF856519F500/users/register"
            },
            "method": {
                "type": "string",
                "default": "post"
            },
            "request": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {
                            "digits": {
                                "type": "string"
                            },
                            "marketing": {
                                "type": "string"
                            },
                            "usertype": {
                                "type": "string"
                            },
                            "partyAccount": {
                                "type": "string"
                            },
                            "renew": {
                                "type": "string"
                            },
                            "password": {
                                "type": "string"
                            },
                            "account": {
                                "type": "string"
                            },
                            "phone": {
                                "type": "string"
                            },
                            "cusId": {
                                "type": "string"
                            },
                            "email": {
                                "type": "string"
                            },
                            "subId": {
                                "type": "string"
                            }
                        }
                    },
                    "query": {
                        "type": "object",
                        "properties": {}
                    },
                    "headers": {
                        "type": "object",
                        "properties": {
                            "Content-Type": {
                                "type": "string",
                                "default": "application/json"
                            }
                        }
                    }
                }
            },
            "response": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {
                            "subId": {
                                "type": "string",
                                "default": "none"
                            },
                            "cusId": {
                                "type": "string",
                                "default": "freenew"
                            },
                            "socialAccount": {
                                "type": "null"
                            },
                            "account": {
                                "type": "string",
                                "default": "regular"
                            },
                            "renew": {
                                "type": "string",
                                "default": "none"
                            },
                            "accountType": {
                                "type": "null"
                            },
                            "userStatus": {
                                "type": "string",
                                "default": "ENABLED"
                            },
                            "usertype": {
                                "type": "string",
                                "default": "regular"
                            },
                            "digits": {
                                "type": "string",
                                "default": "none"
                            },
                            "updated": {
                                "type": "null"
                            },
                            "created": {
                                "type": "number",
                                "default": 1656090810543
                            },
                            "email": {
                                "type": "string",
                                "default": "nasa@puppetchat.com"
                            },
                            "lastLogin": {
                                "type": "null"
                            },
                            "marketing": {
                                "type": "string",
                                "default": "yes"
                            },
                            "___class": {
                                "type": "string",
                                "default": "Users"
                            },
                            "phone": {
                                "type": "null"
                            },
                            "name": {
                                "type": "null"
                            },
                            "oAuthIdentities": {
                                "type": "null"
                            },
                            "lastVideo": {
                                "type": "null"
                            },
                            "blUserLocale": {
                                "type": "string",
                                "default": "en"
                            },
                            "ownerId": {
                                "type": "string",
                                "default": "BC078990-3B13-4697-B60C-EE240C909754"
                            },
                            "objectId": {
                                "type": "string",
                                "default": "BC078990-3B13-4697-B60C-EE240C909754"
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {}
                    }
                }
            }
        }
    },
    "cancelSubscription": {
        "type": "object",
        "properties": {
            "url": {
                "type": "string",
                "default": "https://api.backendless.com/876B8C32-01E1-6474-FFA9-06AFA3F13500/D7DD8A50-2CE0-B869-FF19-BF856519F500/services/payments/unsubscribe"
            },
            "method": {
                "type": "string",
                "default": "post"
            },
            "request": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {
                            "sid": {
                                "type": "string"
                            },
                            "blank": {
                                "type": "string"
                            }
                        }
                    },
                    "query": {
                        "type": "object",
                        "properties": {}
                    },
                    "headers": {
                        "type": "object",
                        "properties": {
                            "Content-Type": {
                                "type": "string",
                                "default": "application/json"
                            },
                            "user-token": {
                                "type": "string"
                            }
                        }
                    }
                }
            },
            "response": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {}
                    },
                    "headers": {
                        "type": "object",
                        "properties": {}
                    }
                }
            }
        }
    },
    "deleteReply": {
        "type": "object",
        "properties": {
            "url": {
                "type": "string",
                "default": "https://api.backendless.com/876B8C32-01E1-6474-FFA9-06AFA3F13500/D7DD8A50-2CE0-B869-FF19-BF856519F500/services/invites/deletereply"
            },
            "method": {
                "type": "string",
                "default": "post"
            },
            "request": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {
                            "messageId": {
                                "type": "string"
                            }
                        }
                    },
                    "query": {
                        "type": "object",
                        "properties": {}
                    },
                    "headers": {
                        "type": "object",
                        "properties": {
                            "user-token": {
                                "type": "string"
                            },
                            "Content-Type": {
                                "type": "string",
                                "default": "application/json"
                            }
                        }
                    }
                }
            },
            "response": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {}
                    },
                    "headers": {
                        "type": "object",
                        "properties": {}
                    }
                }
            }
        }
    },
    "hostReply": {
        "type": "object",
        "properties": {
            "url": {
                "type": "string",
                "default": "https://api.backendless.com/876B8C32-01E1-6474-FFA9-06AFA3F13500/D7DD8A50-2CE0-B869-FF19-BF856519F500/services/invites/hostreply"
            },
            "method": {
                "type": "string",
                "default": "post"
            },
            "request": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {
                            "message": {
                                "type": "string"
                            },
                            "eventId": {
                                "type": "string"
                            },
                            "messageId": {
                                "type": "string"
                            }
                        }
                    },
                    "query": {
                        "type": "object",
                        "properties": {}
                    },
                    "headers": {
                        "type": "object",
                        "properties": {
                            "user-token": {
                                "type": "string"
                            },
                            "Content-Type": {
                                "type": "string",
                                "default": "application/json"
                            }
                        }
                    }
                }
            },
            "response": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {}
                    },
                    "headers": {
                        "type": "object",
                        "properties": {}
                    }
                }
            }
        }
    },
    "getTemplate": {
        "type": "object",
        "properties": {
            "url": {
                "type": "string",
                "default": "https://api.backendless.com/876B8C32-01E1-6474-FFA9-06AFA3F13500/D7DD8A50-2CE0-B869-FF19-BF856519F500/data/templates/{id}"
            },
            "method": {
                "type": "string",
                "default": "get"
            },
            "request": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {}
                    },
                    "query": {
                        "type": "object",
                        "properties": {
                            "id": {
                                "type": "string"
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {
                            "user-token": {
                                "type": "string"
                            }
                        }
                    }
                }
            },
            "response": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {
                            "$": {
                                "type": "object",
                                "properties": {
                                    "category": {
                                        "type": "null"
                                    },
                                    "___class": {
                                        "type": "string",
                                        "default": "templates"
                                    },
                                    "keywords": {
                                        "type": "null"
                                    },
                                    "objectId": {
                                        "type": "string",
                                        "default": "72921E8B-F38B-4435-B1C7-287D9F76487E"
                                    },
                                    "cost": {
                                        "type": "number",
                                        "default": 1
                                    },
                                    "ownerId": {
                                        "type": "null"
                                    },
                                    "active": {
                                        "type": "string",
                                        "default": "yes"
                                    },
                                    "created": {
                                        "type": "number",
                                        "default": 1697588571459
                                    },
                                    "title": {
                                        "type": "string",
                                        "default": "Fishn' For Fifty"
                                    },
                                    "invitation": {
                                        "type": "object",
                                        "properties": {
                                            "puppetBackFrameClass": {
                                                "type": "string",
                                                "default": "videoGrid"
                                            },
                                            "puppetFrontFrameUrl": {
                                                "type": "string",
                                                "default": "assets/images/puppetRight.png"
                                            },
                                            "showMainBackgroundVideo": {
                                                "type": "string",
                                                "default": "hideGrid"
                                            },
                                            "cardBackBackground": {
                                                "type": "string",
                                                "default": "background-image: url('https://backendlessappcontent.com/876B8C32-01E1-6474-FFA9-06AFA3F13500/D7DD8A50-2CE0-B869-FF19-BF856519F500/files/web/cardBacks/21.png');background-size:cover;background-repeat:no-repeat;background-position: center center;"
                                            },
                                            "showCardFrontBackgroundVideoUrl": {
                                                "type": "string",
                                                "default": "hideGrid"
                                            },
                                            "eventOptions": {
                                                "type": "object",
                                                "properties": {
                                                    "kidsAllowed": {
                                                        "type": "boolean",
                                                        "default": true
                                                    },
                                                    "noGifts": {
                                                        "type": "boolean",
                                                        "default": false
                                                    },
                                                    "bringOthers": {
                                                        "type": "boolean",
                                                        "default": false
                                                    },
                                                    "seeAttendees": {
                                                        "type": "boolean",
                                                        "default": true
                                                    },
                                                    "notifyRsvp": {
                                                        "type": "boolean",
                                                        "default": true
                                                    },
                                                    "notifyActivity": {
                                                        "type": "boolean",
                                                        "default": true
                                                    }
                                                }
                                            },
                                            "hasBack": {
                                                "type": "boolean",
                                                "default": false
                                            },
                                            "showCardBackBackgroundVideoUrl": {
                                                "type": "string",
                                                "default": "hideGrid"
                                            },
                                            "eventTime": {
                                                "type": "string",
                                                "default": "2023-10-16T13:59:00-07:00"
                                            },
                                            "canvasBackImage": {
                                                "type": "string",
                                                "default": "https://backendlessappcontent.com/876B8C32-01E1-6474-FFA9-06AFA3F13500/03B6F34E-6A69-45E0-8B01-E7ADEC41AC12/files/canvas/F4558A9A-0AA9-40CA-9752-7141B07D2A62-1697507953148-back.png"
                                            },
                                            "puppetFrontPoster": {
                                                "type": "string",
                                                "default": "assets/images/static1.gif"
                                            },
                                            "envelopeColor": {
                                                "type": "string",
                                                "default": "WHITEenvelope"
                                            },
                                            "hostedByPhone": {
                                                "type": "string",
                                                "default": "(314) 288 - 3057"
                                            },
                                            "titleText": {
                                                "type": "string",
                                                "default": "another"
                                            },
                                            "eventDateString": {
                                                "type": "string",
                                                "default": "Oct 24, 2023"
                                            },
                                            "mainbackgroundvideourl": {
                                                "type": "string"
                                            },
                                            "puppetFrontPosition": {
                                                "type": "string",
                                                "default": "vclass"
                                            },
                                            "cardFrontBackground": {
                                                "type": "string",
                                                "default": "background-image: url('https://backendlessappcontent.com/876B8C32-01E1-6474-FFA9-06AFA3F13500/D7DD8A50-2CE0-B869-FF19-BF856519F500/files/web/cardBacks/21.png');background-size:cover;background-repeat:no-repeat;background-position: center center;"
                                            },
                                            "puppetFrontFrameShow": {
                                                "type": "string",
                                                "default": "hideGrid"
                                            },
                                            "puppetBackFrameUrl": {
                                                "type": "string",
                                                "default": "assets/images/puppetRight.png"
                                            },
                                            "canvasFront": {
                                                "type": "object",
                                                "properties": {
                                                    "objects": {
                                                        "type": "array",
                                                        "items": [{
                                                            "type": "object",
                                                            "properties": {
                                                                "fontSize": {
                                                                    "type": "number",
                                                                    "default": 18
                                                                },
                                                                "text": {
                                                                    "type": "string",
                                                                    "default": "Type something awesome!"
                                                                },
                                                                "visible": {
                                                                    "type": "boolean",
                                                                    "default": true
                                                                },
                                                                "height": {
                                                                    "type": "number",
                                                                    "default": 61.02
                                                                },
                                                                "angle": {
                                                                    "type": "number",
                                                                    "default": 0
                                                                },
                                                                "strokeMiterLimit": {
                                                                    "type": "number",
                                                                    "default": 4
                                                                },
                                                                "globalCompositeOperation": {
                                                                    "type": "string",
                                                                    "default": "source-over"
                                                                },
                                                                "fontStyle": {
                                                                    "type": "string",
                                                                    "default": "normal"
                                                                },
                                                                "linethrough": {
                                                                    "type": "boolean",
                                                                    "default": false
                                                                },
                                                                "fontFamily": {
                                                                    "type": "string",
                                                                    "default": "quicksand"
                                                                },
                                                                "direction": {
                                                                    "type": "string",
                                                                    "default": "ltr"
                                                                },
                                                                "skewY": {
                                                                    "type": "number",
                                                                    "default": 0
                                                                },
                                                                "path": {
                                                                    "type": "null"
                                                                },
                                                                "shadow": {
                                                                    "type": "object",
                                                                    "properties": {
                                                                        "offsetX": {
                                                                            "type": "number",
                                                                            "default": 0
                                                                        },
                                                                        "offsetY": {
                                                                            "type": "number",
                                                                            "default": 0
                                                                        },
                                                                        "blur": {
                                                                            "type": "number",
                                                                            "default": 0
                                                                        },
                                                                        "affectStroke": {
                                                                            "type": "boolean",
                                                                            "default": false
                                                                        },
                                                                        "color": {
                                                                            "type": "string",
                                                                            "default": "rgba(0,0,0,1)"
                                                                        },
                                                                        "nonScaling": {
                                                                            "type": "boolean",
                                                                            "default": false
                                                                        }
                                                                    }
                                                                },
                                                                "pathStartOffset": {
                                                                    "type": "number",
                                                                    "default": 0
                                                                },
                                                                "left": {
                                                                    "type": "number",
                                                                    "default": 50
                                                                },
                                                                "originX": {
                                                                    "type": "string",
                                                                    "default": "left"
                                                                },
                                                                "styles": {
                                                                    "type": "array",
                                                                    "items": [{
                                                                        "type": "null"
                                                                    }]
                                                                },
                                                                "fontWeight": {
                                                                    "type": "string",
                                                                    "default": "400"
                                                                },
                                                                "minWidth": {
                                                                    "type": "number",
                                                                    "default": 20
                                                                },
                                                                "lineHeight": {
                                                                    "type": "number",
                                                                    "default": 1
                                                                },
                                                                "fillRule": {
                                                                    "type": "string",
                                                                    "default": "nonzero"
                                                                },
                                                                "scaleY": {
                                                                    "type": "number",
                                                                    "default": 1
                                                                },
                                                                "fill": {
                                                                    "type": "string",
                                                                    "default": "#000000"
                                                                },
                                                                "strokeDashArray": {
                                                                    "type": "null"
                                                                },
                                                                "top": {
                                                                    "type": "number",
                                                                    "default": 100
                                                                },
                                                                "skewX": {
                                                                    "type": "number",
                                                                    "default": 0
                                                                },
                                                                "originY": {
                                                                    "type": "string",
                                                                    "default": "top"
                                                                },
                                                                "splitByGrapheme": {
                                                                    "type": "boolean",
                                                                    "default": false
                                                                },
                                                                "scaleX": {
                                                                    "type": "number",
                                                                    "default": 1
                                                                },
                                                                "paintFirst": {
                                                                    "type": "string",
                                                                    "default": "fill"
                                                                },
                                                                "overline": {
                                                                    "type": "boolean",
                                                                    "default": false
                                                                },
                                                                "strokeUniform": {
                                                                    "type": "boolean",
                                                                    "default": false
                                                                },
                                                                "width": {
                                                                    "type": "number",
                                                                    "default": 87.71
                                                                },
                                                                "strokeLineJoin": {
                                                                    "type": "string",
                                                                    "default": "miter"
                                                                },
                                                                "opacity": {
                                                                    "type": "number",
                                                                    "default": 1
                                                                },
                                                                "textBackgroundColor": {
                                                                    "type": "string"
                                                                },
                                                                "pathSide": {
                                                                    "type": "string",
                                                                    "default": "left"
                                                                },
                                                                "textAlign": {
                                                                    "type": "string",
                                                                    "default": "left"
                                                                },
                                                                "stroke": {
                                                                    "type": "null"
                                                                },
                                                                "flipY": {
                                                                    "type": "boolean",
                                                                    "default": false
                                                                },
                                                                "pathAlign": {
                                                                    "type": "string",
                                                                    "default": "baseline"
                                                                },
                                                                "backgroundColor": {
                                                                    "type": "string"
                                                                },
                                                                "type": {
                                                                    "type": "string",
                                                                    "default": "textbox"
                                                                },
                                                                "charSpacing": {
                                                                    "type": "number",
                                                                    "default": 0
                                                                },
                                                                "strokeWidth": {
                                                                    "type": "number",
                                                                    "default": 1
                                                                },
                                                                "version": {
                                                                    "type": "string",
                                                                    "default": "5.3.0"
                                                                },
                                                                "strokeLineCap": {
                                                                    "type": "string",
                                                                    "default": "butt"
                                                                },
                                                                "underline": {
                                                                    "type": "boolean",
                                                                    "default": false
                                                                },
                                                                "flipX": {
                                                                    "type": "boolean",
                                                                    "default": false
                                                                },
                                                                "strokeDashOffset": {
                                                                    "type": "number",
                                                                    "default": 0
                                                                }
                                                            }
                                                        }]
                                                    },
                                                    "version": {
                                                        "type": "string",
                                                        "default": "5.3.0"
                                                    }
                                                }
                                            },
                                            "eventDate": {
                                                "type": "string",
                                                "default": "2023-10-24T18:58:00-07:00"
                                            },
                                            "giftRegistry": {
                                                "type": "array",
                                                "items": [{
                                                    "type": "object",
                                                    "properties": {
                                                        "registryName": {
                                                            "type": "string"
                                                        },
                                                        "registries": {
                                                            "type": "array",
                                                            "items": [{
                                                                "type": "object",
                                                                "properties": {
                                                                    "url": {
                                                                        "type": "string"
                                                                    },
                                                                    "showTrash": {
                                                                        "type": "string",
                                                                        "default": "hideGrid"
                                                                    }
                                                                }
                                                            }]
                                                        }
                                                    }
                                                }]
                                            },
                                            "canvasBack": {
                                                "type": "object",
                                                "properties": {
                                                    "version": {
                                                        "type": "string",
                                                        "default": "5.3.0"
                                                    },
                                                    "objects": {
                                                        "type": "array",
                                                        "items": [{
                                                            "type": "null"
                                                        }]
                                                    }
                                                }
                                            },
                                            "canvasFrontImage": {
                                                "type": "string",
                                                "default": "https://backendlessappcontent.com/876B8C32-01E1-6474-FFA9-06AFA3F13500/03B6F34E-6A69-45E0-8B01-E7ADEC41AC12/files/canvas/F4558A9A-0AA9-40CA-9752-7141B07D2A62-1697507953148-front.png"
                                            },
                                            "eventTimeString": {
                                                "type": "string",
                                                "default": "1:59 PM"
                                            },
                                            "puppetFrontFrameClass": {
                                                "type": "string",
                                                "default": "videoGrid"
                                            },
                                            "puppetBackFrameShow": {
                                                "type": "string",
                                                "default": "hideGrid"
                                            },
                                            "hostedByName": {
                                                "type": "string",
                                                "default": "bbcb"
                                            },
                                            "puppetBackPoster": {
                                                "type": "string",
                                                "default": "assets/images/static1.gif"
                                            }
                                        }
                                    },
                                    "updated": {
                                        "type": "number",
                                        "default": 1697588586895
                                    },
                                    "imageUrl": {
                                        "type": "null"
                                    }
                                }
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {}
                    }
                }
            }
        }
    },
    "saveAudio": {
        "type": "object",
        "properties": {
            "url": {
                "type": "string",
                "default": "https://puppetrave.com/api/saveAudioV2.php"
            },
            "method": {
                "type": "string",
                "default": "post"
            },
            "request": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {}
                    },
                    "query": {
                        "type": "object",
                        "properties": {}
                    },
                    "headers": {
                        "type": "object",
                        "properties": {
                            "Content-Type": {
                                "type": "string",
                                "default": "application/x-www-form-urlencoded"
                            }
                        }
                    }
                }
            },
            "response": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {
                            "$": {
                                "type": "object",
                                "properties": {}
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {}
                    }
                }
            }
        }
    },
    "chargeToken": {
        "type": "object",
        "properties": {
            "url": {
                "type": "string",
                "default": "https://api.backendless.com/876B8C32-01E1-6474-FFA9-06AFA3F13500/D7DD8A50-2CE0-B869-FF19-BF856519F500/services/Stripe/charge"
            },
            "method": {
                "type": "string",
                "default": "post"
            },
            "request": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {
                            "amount": {
                                "type": "number",
                                "default": null
                            },
                            "token": {
                                "type": "string"
                            }
                        }
                    },
                    "query": {
                        "type": "object",
                        "properties": {}
                    },
                    "headers": {
                        "type": "object",
                        "properties": {
                            "Content-Type": {
                                "type": "string",
                                "default": "application/json"
                            },
                            "user-token": {
                                "type": "string"
                            }
                        }
                    }
                }
            },
            "response": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {
                            "payment_method_details": {
                                "type": "object",
                                "properties": {
                                    "card": {
                                        "type": "object",
                                        "properties": {
                                            "installments": {
                                                "type": "null"
                                            },
                                            "multicapture": {
                                                "type": "object",
                                                "properties": {
                                                    "status": {
                                                        "type": "string",
                                                        "default": "unavailable"
                                                    }
                                                }
                                            },
                                            "brand": {
                                                "type": "string",
                                                "default": "mastercard"
                                            },
                                            "wallet": {
                                                "type": "null"
                                            },
                                            "incremental_authorization": {
                                                "type": "object",
                                                "properties": {
                                                    "status": {
                                                        "type": "string",
                                                        "default": "unavailable"
                                                    }
                                                }
                                            },
                                            "amount_authorized": {
                                                "type": "number",
                                                "default": 2000
                                            },
                                            "last4": {
                                                "type": "string",
                                                "default": "4444"
                                            },
                                            "checks": {
                                                "type": "object",
                                                "properties": {
                                                    "address_postal_code_check": {
                                                        "type": "string",
                                                        "default": "pass"
                                                    },
                                                    "cvc_check": {
                                                        "type": "string",
                                                        "default": "pass"
                                                    },
                                                    "address_line1_check": {
                                                        "type": "null"
                                                    }
                                                }
                                            },
                                            "network": {
                                                "type": "string",
                                                "default": "mastercard"
                                            },
                                            "network_token": {
                                                "type": "object",
                                                "properties": {
                                                    "used": {
                                                        "type": "boolean",
                                                        "default": false
                                                    }
                                                }
                                            },
                                            "extended_authorization": {
                                                "type": "object",
                                                "properties": {
                                                    "status": {
                                                        "type": "string",
                                                        "default": "disabled"
                                                    }
                                                }
                                            },
                                            "mandate": {
                                                "type": "null"
                                            },
                                            "three_d_secure": {
                                                "type": "null"
                                            },
                                            "fingerprint": {
                                                "type": "string",
                                                "default": "8tuOzAyBZhksqxhZ"
                                            },
                                            "exp_year": {
                                                "type": "number",
                                                "default": 2025
                                            },
                                            "overcapture": {
                                                "type": "object",
                                                "properties": {
                                                    "status": {
                                                        "type": "string",
                                                        "default": "unavailable"
                                                    },
                                                    "maximum_amount_capturable": {
                                                        "type": "number",
                                                        "default": 2000
                                                    }
                                                }
                                            },
                                            "country": {
                                                "type": "string",
                                                "default": "US"
                                            },
                                            "exp_month": {
                                                "type": "number",
                                                "default": 12
                                            },
                                            "funding": {
                                                "type": "string",
                                                "default": "credit"
                                            }
                                        }
                                    },
                                    "type": {
                                        "type": "string",
                                        "default": "card"
                                    }
                                }
                            },
                            "failure_code": {
                                "type": "null"
                            },
                            "id": {
                                "type": "string",
                                "default": "ch_3OxCi2L5nxN8fYai0klFW7nM"
                            },
                            "calculated_statement_descriptor": {
                                "type": "string",
                                "default": "PUPPET PARTY"
                            },
                            "application_fee_amount": {
                                "type": "null"
                            },
                            "amount": {
                                "type": "number",
                                "default": 2000
                            },
                            "paid": {
                                "type": "boolean",
                                "default": true
                            },
                            "destination": {
                                "type": "null"
                            },
                            "object": {
                                "type": "string",
                                "default": "charge"
                            },
                            "source": {
                                "type": "object",
                                "properties": {
                                    "fingerprint": {
                                        "type": "string",
                                        "default": "8tuOzAyBZhksqxhZ"
                                    },
                                    "funding": {
                                        "type": "string",
                                        "default": "credit"
                                    },
                                    "customer": {
                                        "type": "null"
                                    },
                                    "id": {
                                        "type": "string",
                                        "default": "card_1OxCfRL5nxN8fYaiJww3EIJM"
                                    },
                                    "metadata": {
                                        "type": "object",
                                        "properties": {}
                                    },
                                    "address_line1_check": {
                                        "type": "null"
                                    },
                                    "exp_month": {
                                        "type": "number",
                                        "default": 12
                                    },
                                    "address_line1": {
                                        "type": "null"
                                    },
                                    "wallet": {
                                        "type": "null"
                                    },
                                    "address_zip": {
                                        "type": "string",
                                        "default": "90049"
                                    },
                                    "brand": {
                                        "type": "string",
                                        "default": "MasterCard"
                                    },
                                    "country": {
                                        "type": "string",
                                        "default": "US"
                                    },
                                    "name": {
                                        "type": "null"
                                    },
                                    "address_zip_check": {
                                        "type": "string",
                                        "default": "pass"
                                    },
                                    "exp_year": {
                                        "type": "number",
                                        "default": 2025
                                    },
                                    "last4": {
                                        "type": "string",
                                        "default": "4444"
                                    },
                                    "dynamic_last4": {
                                        "type": "null"
                                    },
                                    "object": {
                                        "type": "string",
                                        "default": "card"
                                    },
                                    "address_country": {
                                        "type": "null"
                                    },
                                    "tokenization_method": {
                                        "type": "null"
                                    },
                                    "cvc_check": {
                                        "type": "string",
                                        "default": "pass"
                                    },
                                    "address_state": {
                                        "type": "null"
                                    },
                                    "address_line2": {
                                        "type": "null"
                                    },
                                    "address_city": {
                                        "type": "null"
                                    }
                                }
                            },
                            "application_fee": {
                                "type": "null"
                            },
                            "statement_descriptor": {
                                "type": "null"
                            },
                            "review": {
                                "type": "null"
                            },
                            "statement_descriptor_suffix": {
                                "type": "null"
                            },
                            "amount_refunded": {
                                "type": "number",
                                "default": 0
                            },
                            "payment_intent": {
                                "type": "null"
                            },
                            "refunds": {
                                "type": "object",
                                "properties": {
                                    "data": {
                                        "type": "array",
                                        "items": [{
                                            "type": "null"
                                        }]
                                    },
                                    "object": {
                                        "type": "string",
                                        "default": "list"
                                    },
                                    "url": {
                                        "type": "string",
                                        "default": "/v1/charges/ch_3OxCi2L5nxN8fYai0klFW7nM/refunds"
                                    },
                                    "total_count": {
                                        "type": "number",
                                        "default": 0
                                    },
                                    "has_more": {
                                        "type": "boolean",
                                        "default": false
                                    }
                                }
                            },
                            "created": {
                                "type": "number",
                                "default": 1711131654
                            },
                            "description": {
                                "type": "null"
                            },
                            "customer": {
                                "type": "null"
                            },
                            "outcome": {
                                "type": "object",
                                "properties": {
                                    "type": {
                                        "type": "string",
                                        "default": "authorized"
                                    },
                                    "seller_message": {
                                        "type": "string",
                                        "default": "Payment complete."
                                    },
                                    "network_status": {
                                        "type": "string",
                                        "default": "approved_by_network"
                                    },
                                    "risk_level": {
                                        "type": "string",
                                        "default": "normal"
                                    },
                                    "reason": {
                                        "type": "null"
                                    },
                                    "risk_score": {
                                        "type": "number",
                                        "default": 1
                                    }
                                }
                            },
                            "application": {
                                "type": "null"
                            },
                            "livemode": {
                                "type": "boolean",
                                "default": false
                            },
                            "invoice": {
                                "type": "null"
                            },
                            "disputed": {
                                "type": "boolean",
                                "default": false
                            },
                            "receipt_number": {
                                "type": "null"
                            },
                            "captured": {
                                "type": "boolean",
                                "default": true
                            },
                            "currency": {
                                "type": "string",
                                "default": "usd"
                            },
                            "billing_details": {
                                "type": "object",
                                "properties": {
                                    "address": {
                                        "type": "object",
                                        "properties": {
                                            "state": {
                                                "type": "null"
                                            },
                                            "country": {
                                                "type": "null"
                                            },
                                            "postal_code": {
                                                "type": "string",
                                                "default": "90049"
                                            },
                                            "city": {
                                                "type": "null"
                                            },
                                            "line1": {
                                                "type": "null"
                                            },
                                            "line2": {
                                                "type": "null"
                                            }
                                        }
                                    },
                                    "name": {
                                        "type": "null"
                                    },
                                    "email": {
                                        "type": "null"
                                    },
                                    "phone": {
                                        "type": "null"
                                    }
                                }
                            },
                            "refunded": {
                                "type": "boolean",
                                "default": false
                            },
                            "transfer_group": {
                                "type": "null"
                            },
                            "amount_captured": {
                                "type": "number",
                                "default": 2000
                            },
                            "balance_transaction": {
                                "type": "string",
                                "default": "txn_3OxCi2L5nxN8fYai0SiIw2VR"
                            },
                            "receipt_email": {
                                "type": "null"
                            },
                            "fraud_details": {
                                "type": "object",
                                "properties": {}
                            },
                            "transfer_data": {
                                "type": "null"
                            },
                            "metadata": {
                                "type": "object",
                                "properties": {}
                            },
                            "source_transfer": {
                                "type": "null"
                            },
                            "failure_balance_transaction": {
                                "type": "null"
                            },
                            "on_behalf_of": {
                                "type": "null"
                            },
                            "order": {
                                "type": "null"
                            },
                            "shipping": {
                                "type": "null"
                            },
                            "payment_method": {
                                "type": "string",
                                "default": "card_1OxCfRL5nxN8fYaiJww3EIJM"
                            },
                            "dispute": {
                                "type": "null"
                            },
                            "status": {
                                "type": "string",
                                "default": "succeeded"
                            },
                            "receipt_url": {
                                "type": "string",
                                "default": "https://pay.stripe.com/receipts/payment/CAcaFwoVYWNjdF8xRHgyZ2ZMNW54TjhmWWFpKIeY968GMga5XRALgHA6LBZ_9svglq9GD2mOC6-Ea8RKYEdrrJftT1fQW4RGRw_jvyPHYEkUOUNk7H5V"
                            },
                            "failure_message": {
                                "type": "null"
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {}
                    }
                }
            }
        }
    },
    "checkVideos": {
        "type": "object",
        "properties": {
            "url": {
                "type": "string",
                "default": "https://api.backendless.com/876B8C32-01E1-6474-FFA9-06AFA3F13500/D7DD8A50-2CE0-B869-FF19-BF856519F500/services/checkuser/checkuser"
            },
            "method": {
                "type": "string",
                "default": "get"
            },
            "request": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {}
                    },
                    "query": {
                        "type": "object",
                        "properties": {
                            "userid": {
                                "type": "string"
                            },
                            "email": {
                                "type": "string"
                            },
                            "periodstart": {
                                "type": "string"
                            },
                            "planid": {
                                "type": "string"
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {
                            "user-token": {
                                "type": "string"
                            }
                        }
                    }
                }
            },
            "response": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {
                            "$": {
                                "type": "object",
                                "properties": {
                                    "result": {
                                        "type": "string",
                                        "default": "pass"
                                    },
                                    "videosCreated": {
                                        "type": "string",
                                        "default": "0"
                                    }
                                }
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {}
                    }
                }
            }
        }
    },
    "makeAudio": {
        "type": "object",
        "properties": {
            "url": {
                "type": "string",
                "default": "https://puppetrave.com/api/watson.php"
            },
            "method": {
                "type": "string",
                "default": "post"
            },
            "request": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {
                            "voice": {
                                "type": "string"
                            },
                            "filename": {
                                "type": "string"
                            },
                            "stamp": {
                                "type": "string"
                            },
                            "text": {
                                "type": "string"
                            },
                            "rate": {
                                "type": "string"
                            },
                            "scene": {
                                "type": "string"
                            },
                            "pitch": {
                                "type": "string"
                            },
                            "clientid": {
                                "type": "string"
                            }
                        }
                    },
                    "query": {
                        "type": "object",
                        "properties": {}
                    },
                    "headers": {
                        "type": "object",
                        "properties": {
                            "Content-Type": {
                                "type": "string",
                                "default": "application/x-www-form-urlencoded"
                            }
                        }
                    }
                }
            },
            "response": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {
                            "$": {
                                "type": "object",
                                "properties": {}
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {}
                    }
                }
            }
        }
    },
    "checkPending": {
        "type": "object",
        "properties": {
            "url": {
                "type": "string",
                "default": "https://api.backendless.com/876B8C32-01E1-6474-FFA9-06AFA3F13500/D7DD8A50-2CE0-B869-FF19-BF856519F500/data/sessions/count?where=videoUrl%20%3D%20%27creating%27"
            },
            "method": {
                "type": "string",
                "default": "get"
            },
            "request": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {}
                    },
                    "query": {
                        "type": "object",
                        "properties": {}
                    },
                    "headers": {
                        "type": "object",
                        "properties": {
                            "user-token": {
                                "type": "string"
                            }
                        }
                    }
                }
            },
            "response": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {
                            "$": {
                                "type": "object",
                                "properties": {}
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {}
                    }
                }
            }
        }
    },
    "getVoices": {
        "type": "object",
        "properties": {
            "url": {
                "type": "string",
                "default": "https://api.backendless.com/876B8C32-01E1-6474-FFA9-06AFA3F13500/D7DD8A50-2CE0-B869-FF19-BF856519F500/data/people?where=lang%20%3D%20%27{lang}%27&pageSize=100&sortBy=name%20asc"
            },
            "method": {
                "type": "string",
                "default": "get"
            },
            "request": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {}
                    },
                    "query": {
                        "type": "object",
                        "properties": {
                            "lang": {
                                "type": "string"
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {
                            "user-token": {
                                "type": "string"
                            }
                        }
                    }
                }
            },
            "response": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {
                            "$": {
                                "type": "array",
                                "items": [{
                                    "type": "object",
                                    "properties": {
                                        "audioFile": {
                                            "type": "string",
                                            "default": "files/images/olivia.mp3"
                                        },
                                        "showHigh": {
                                            "type": "boolean",
                                            "default": false
                                        },
                                        "ownerId": {
                                            "type": "null"
                                        },
                                        "name": {
                                            "type": "string",
                                            "default": "Olivia"
                                        },
                                        "pitch": {
                                            "type": "string",
                                            "default": "Normal"
                                        },
                                        "showLow": {
                                            "type": "boolean",
                                            "default": false
                                        },
                                        "___class": {
                                            "type": "string",
                                            "default": "people"
                                        },
                                        "showNormal": {
                                            "type": "boolean",
                                            "default": true
                                        },
                                        "objectId": {
                                            "type": "string",
                                            "default": "94AADAC1-8DE7-4830-8D3C-E9EAD19A6DCC"
                                        },
                                        "id": {
                                            "type": "string",
                                            "default": "en-US_OliviaV3Voice"
                                        },
                                        "updated": {
                                            "type": "number",
                                            "default": 1639525756000
                                        },
                                        "playtext": {
                                            "type": "string"
                                        },
                                        "lang": {
                                            "type": "string",
                                            "default": "7732555F-88A2-4415-B308-27B27A61318F"
                                        },
                                        "showPlay": {
                                            "type": "boolean",
                                            "default": true
                                        },
                                        "created": {
                                            "type": "number",
                                            "default": 1601654785000
                                        },
                                        "classname": {
                                            "type": "string",
                                            "default": "itemNotSelectedVoice"
                                        }
                                    }
                                }]
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {}
                    }
                }
            }
        }
    },
    "getBackgroundsCount": {
        "type": "object",
        "properties": {
            "url": {
                "type": "string",
                "default": "https://api.backendless.com/876B8C32-01E1-6474-FFA9-06AFA3F13500/D7DD8A50-2CE0-B869-FF19-BF856519F500/data/backgrounds/count?where=category%20LIKE%20%27%25{name}%25%27"
            },
            "method": {
                "type": "string",
                "default": "get"
            },
            "request": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {}
                    },
                    "query": {
                        "type": "object",
                        "properties": {
                            "name": {
                                "type": "string"
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {
                            "user-token": {
                                "type": "string"
                            }
                        }
                    }
                }
            },
            "response": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {
                            "$": {
                                "type": "object",
                                "properties": {}
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {}
                    }
                }
            }
        }
    },
    "getBackgrounds": {
        "type": "object",
        "properties": {
            "url": {
                "type": "string",
                "default": "https://api.backendless.com/876B8C32-01E1-6474-FFA9-06AFA3F13500/D7DD8A50-2CE0-B869-FF19-BF856519F500/data/backgrounds?where=category%20LIKE%20%27%25{name}%25%27&sortBy=premium%20asc%2Cname%20asc&pageSize=10&offset={off}"
            },
            "method": {
                "type": "string",
                "default": "get"
            },
            "request": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {}
                    },
                    "query": {
                        "type": "object",
                        "properties": {
                            "off": {
                                "type": "string"
                            },
                            "name": {
                                "type": "string"
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {
                            "user-token": {
                                "type": "string"
                            }
                        }
                    }
                }
            },
            "response": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {
                            "$": {
                                "type": "array",
                                "items": [{
                                    "type": "object",
                                    "properties": {
                                        "image": {
                                            "type": "string",
                                            "default": "https://www.app.puppetchat.com/files/previews/seaturtlemp4.png"
                                        },
                                        "category": {
                                            "type": "string",
                                            "default": "Outdoors,Animals"
                                        },
                                        "active": {
                                            "type": "string",
                                            "default": "yes"
                                        },
                                        "name": {
                                            "type": "string",
                                            "default": "seaturtlemp4"
                                        },
                                        "created": {
                                            "type": "number",
                                            "default": 1613595908000
                                        },
                                        "ownerId": {
                                            "type": "null"
                                        },
                                        "type": {
                                            "type": "string",
                                            "default": "video"
                                        },
                                        "poster": {
                                            "type": "string",
                                            "default": "https://www.app.puppethd.com/files/previews/seaturtlemp4.png"
                                        },
                                        "updated": {
                                            "type": "number",
                                            "default": 1640969330000
                                        },
                                        "keywords": {
                                            "type": "null"
                                        },
                                        "objectId": {
                                            "type": "string",
                                            "default": "FDCB735F-D152-4D08-90AB-FE2C9E5D1640"
                                        },
                                        "premium": {
                                            "type": "string",
                                            "default": "yes"
                                        },
                                        "___class": {
                                            "type": "string",
                                            "default": "backgrounds"
                                        }
                                    }
                                }]
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {}
                    }
                }
            }
        }
    },
    "getCategories": {
        "type": "object",
        "properties": {
            "url": {
                "type": "string",
                "default": "https://api.backendless.com/876B8C32-01E1-6474-FFA9-06AFA3F13500/D7DD8A50-2CE0-B869-FF19-BF856519F500/data/categories?pageSize=100&sortBy=name%20asc"
            },
            "method": {
                "type": "string",
                "default": "get"
            },
            "request": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {}
                    },
                    "query": {
                        "type": "object",
                        "properties": {}
                    },
                    "headers": {
                        "type": "object",
                        "properties": {
                            "user-token": {
                                "type": "string"
                            }
                        }
                    }
                }
            },
            "response": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {
                            "$": {
                                "type": "array",
                                "items": [{
                                    "type": "object",
                                    "properties": {
                                        "___class": {
                                            "type": "string",
                                            "default": "categories"
                                        },
                                        "name": {
                                            "type": "string",
                                            "default": "Transportation"
                                        },
                                        "objectId": {
                                            "type": "string",
                                            "default": "4BE1B62A-6D5B-49AC-8C18-930BEFC2FF35"
                                        },
                                        "created": {
                                            "type": "number",
                                            "default": 1596294900000
                                        },
                                        "updated": {
                                            "type": "number",
                                            "default": 1619638356000
                                        },
                                        "ownerId": {
                                            "type": "null"
                                        }
                                    }
                                }]
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {}
                    }
                }
            }
        }
    },
    "getTemplatesSearch": {
        "type": "object",
        "properties": {
            "url": {
                "type": "string",
                "default": "https://api.backendless.com/876B8C32-01E1-6474-FFA9-06AFA3F13500/D7DD8A50-2CE0-B869-FF19-BF856519F500/data/templates?where=keywords%20LIKE%20%27%25{terms}%25%27%20AND%20active%20%3D%20%27yes%27&pageSize={size}&offset={off}"
            },
            "method": {
                "type": "string",
                "default": "get"
            },
            "request": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {}
                    },
                    "query": {
                        "type": "object",
                        "properties": {
                            "off": {
                                "type": "string"
                            },
                            "size": {
                                "type": "string"
                            },
                            "terms": {
                                "type": "string"
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {
                            "user-token": {
                                "type": "string"
                            }
                        }
                    }
                }
            },
            "response": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {
                            "$": {
                                "type": "array",
                                "items": [{
                                    "type": "object",
                                    "properties": {
                                        "category": {
                                            "type": "null"
                                        },
                                        "cost": {
                                            "type": "number",
                                            "default": 1
                                        },
                                        "order": {
                                            "type": "null"
                                        },
                                        "active": {
                                            "type": "string",
                                            "default": "yes"
                                        },
                                        "keywords": {
                                            "type": "string",
                                            "default": "kids birthday"
                                        },
                                        "created": {
                                            "type": "number",
                                            "default": 1697588571459
                                        },
                                        "ownerId": {
                                            "type": "null"
                                        },
                                        "imageUrl": {
                                            "type": "null"
                                        },
                                        "updated": {
                                            "type": "number",
                                            "default": 1700183332564
                                        },
                                        "___class": {
                                            "type": "string",
                                            "default": "templates"
                                        },
                                        "objectId": {
                                            "type": "string",
                                            "default": "72921E8B-F38B-4435-B1C7-287D9F76487E"
                                        },
                                        "title": {
                                            "type": "string",
                                            "default": "Fishn' For Fifty"
                                        },
                                        "invitation": {
                                            "type": "object",
                                            "properties": {
                                                "puppetFrontVideo": {
                                                    "type": "string",
                                                    "default": "https://backendlessappcontent.com/876B8C32-01E1-6474-FFA9-06AFA3F13500/D7DD8A50-2CE0-B869-FF19-BF856519F500/files/videos/F4558A9A-0AA9-40CA-9752-7141B07D2A62_A3C336BA-12A0-4D8D-80BD-D4AD6420FB22.mp4#t=0.1#t=0.1#t=0.1#t=0.1#t=0.1#t=0.1#t=0.1"
                                                },
                                                "canvasFront": {
                                                    "type": "object",
                                                    "properties": {
                                                        "version": {
                                                            "type": "string",
                                                            "default": "5.3.0"
                                                        },
                                                        "objects": {
                                                            "type": "array",
                                                            "items": [{
                                                                "type": "object",
                                                                "properties": {
                                                                    "visible": {
                                                                        "type": "boolean",
                                                                        "default": true
                                                                    },
                                                                    "height": {
                                                                        "type": "number",
                                                                        "default": 61.02
                                                                    },
                                                                    "skewX": {
                                                                        "type": "number",
                                                                        "default": 0
                                                                    },
                                                                    "scaleY": {
                                                                        "type": "number",
                                                                        "default": 2.39
                                                                    },
                                                                    "fontStyle": {
                                                                        "type": "string",
                                                                        "default": "normal"
                                                                    },
                                                                    "top": {
                                                                        "type": "number",
                                                                        "default": 303.94
                                                                    },
                                                                    "originY": {
                                                                        "type": "string",
                                                                        "default": "top"
                                                                    },
                                                                    "stroke": {
                                                                        "type": "null"
                                                                    },
                                                                    "charSpacing": {
                                                                        "type": "number",
                                                                        "default": 0
                                                                    },
                                                                    "globalCompositeOperation": {
                                                                        "type": "string",
                                                                        "default": "source-over"
                                                                    },
                                                                    "fontWeight": {
                                                                        "type": "string",
                                                                        "default": "400"
                                                                    },
                                                                    "strokeDashOffset": {
                                                                        "type": "number",
                                                                        "default": 0
                                                                    },
                                                                    "fillRule": {
                                                                        "type": "string",
                                                                        "default": "nonzero"
                                                                    },
                                                                    "shadow": {
                                                                        "type": "object",
                                                                        "properties": {
                                                                            "color": {
                                                                                "type": "string",
                                                                                "default": "rgba(0,0,0,1)"
                                                                            },
                                                                            "offsetX": {
                                                                                "type": "number",
                                                                                "default": 0
                                                                            },
                                                                            "blur": {
                                                                                "type": "number",
                                                                                "default": 0
                                                                            },
                                                                            "affectStroke": {
                                                                                "type": "boolean",
                                                                                "default": false
                                                                            },
                                                                            "nonScaling": {
                                                                                "type": "boolean",
                                                                                "default": false
                                                                            },
                                                                            "offsetY": {
                                                                                "type": "number",
                                                                                "default": 0
                                                                            }
                                                                        }
                                                                    },
                                                                    "version": {
                                                                        "type": "string",
                                                                        "default": "5.3.0"
                                                                    },
                                                                    "skewY": {
                                                                        "type": "number",
                                                                        "default": 0
                                                                    },
                                                                    "width": {
                                                                        "type": "number",
                                                                        "default": 87.71
                                                                    },
                                                                    "paintFirst": {
                                                                        "type": "string",
                                                                        "default": "fill"
                                                                    },
                                                                    "left": {
                                                                        "type": "number",
                                                                        "default": 99.79
                                                                    },
                                                                    "originX": {
                                                                        "type": "string",
                                                                        "default": "left"
                                                                    },
                                                                    "type": {
                                                                        "type": "string",
                                                                        "default": "textbox"
                                                                    },
                                                                    "splitByGrapheme": {
                                                                        "type": "boolean",
                                                                        "default": false
                                                                    },
                                                                    "lineHeight": {
                                                                        "type": "number",
                                                                        "default": 1
                                                                    },
                                                                    "path": {
                                                                        "type": "null"
                                                                    },
                                                                    "pathSide": {
                                                                        "type": "string",
                                                                        "default": "left"
                                                                    },
                                                                    "backgroundColor": {
                                                                        "type": "string"
                                                                    },
                                                                    "flipY": {
                                                                        "type": "boolean",
                                                                        "default": false
                                                                    },
                                                                    "pathStartOffset": {
                                                                        "type": "number",
                                                                        "default": 0
                                                                    },
                                                                    "strokeLineJoin": {
                                                                        "type": "string",
                                                                        "default": "miter"
                                                                    },
                                                                    "strokeWidth": {
                                                                        "type": "number",
                                                                        "default": 1
                                                                    },
                                                                    "linethrough": {
                                                                        "type": "boolean",
                                                                        "default": false
                                                                    },
                                                                    "minWidth": {
                                                                        "type": "number",
                                                                        "default": 20
                                                                    },
                                                                    "text": {
                                                                        "type": "string",
                                                                        "default": "Type something awesome!"
                                                                    },
                                                                    "opacity": {
                                                                        "type": "number",
                                                                        "default": 1
                                                                    },
                                                                    "fill": {
                                                                        "type": "string",
                                                                        "default": "#FFB6C1"
                                                                    },
                                                                    "direction": {
                                                                        "type": "string",
                                                                        "default": "ltr"
                                                                    },
                                                                    "overline": {
                                                                        "type": "boolean",
                                                                        "default": false
                                                                    },
                                                                    "fontSize": {
                                                                        "type": "number",
                                                                        "default": 18
                                                                    },
                                                                    "strokeMiterLimit": {
                                                                        "type": "number",
                                                                        "default": 4
                                                                    },
                                                                    "textAlign": {
                                                                        "type": "string",
                                                                        "default": "left"
                                                                    },
                                                                    "fontFamily": {
                                                                        "type": "string",
                                                                        "default": "quicksand"
                                                                    },
                                                                    "textBackgroundColor": {
                                                                        "type": "string"
                                                                    },
                                                                    "strokeDashArray": {
                                                                        "type": "null"
                                                                    },
                                                                    "strokeUniform": {
                                                                        "type": "boolean",
                                                                        "default": false
                                                                    },
                                                                    "pathAlign": {
                                                                        "type": "string",
                                                                        "default": "baseline"
                                                                    },
                                                                    "angle": {
                                                                        "type": "number",
                                                                        "default": 0
                                                                    },
                                                                    "styles": {
                                                                        "type": "array",
                                                                        "items": [{
                                                                            "type": "null"
                                                                        }]
                                                                    },
                                                                    "flipX": {
                                                                        "type": "boolean",
                                                                        "default": false
                                                                    },
                                                                    "scaleX": {
                                                                        "type": "number",
                                                                        "default": 2.39
                                                                    },
                                                                    "strokeLineCap": {
                                                                        "type": "string",
                                                                        "default": "butt"
                                                                    },
                                                                    "underline": {
                                                                        "type": "boolean",
                                                                        "default": false
                                                                    }
                                                                }
                                                            }]
                                                        }
                                                    }
                                                },
                                                "titleText": {
                                                    "type": "string",
                                                    "default": "gfggv"
                                                },
                                                "puppetBackFrameShow": {
                                                    "type": "string",
                                                    "default": "hideGrid"
                                                },
                                                "showCardBackBackgroundVideoUrl": {
                                                    "type": "string",
                                                    "default": "hideGrid"
                                                },
                                                "eventTime": {
                                                    "type": "string",
                                                    "default": "2023-11-15T13:20:00-08:00"
                                                },
                                                "puppetFrontPosition": {
                                                    "type": "string",
                                                    "default": "vclassbottom"
                                                },
                                                "puppetFrontPoster": {
                                                    "type": "string",
                                                    "default": "https://backendlessappcontent.com/876B8C32-01E1-6474-FFA9-06AFA3F13500/D7DD8A50-2CE0-B869-FF19-BF856519F500/files/posters/F4558A9A-0AA9-40CA-9752-7141B07D2A62_A3C336BA-12A0-4D8D-80BD-D4AD6420FB22.jpg"
                                                },
                                                "puppetFrontFrameClass": {
                                                    "type": "string",
                                                    "default": "videoGrid"
                                                },
                                                "showCardFrontBackgroundVideoUrl": {
                                                    "type": "string",
                                                    "default": "hideGrid"
                                                },
                                                "puppetFrontFramePosition": {
                                                    "type": "string",
                                                    "default": "puppetframebottom"
                                                },
                                                "showFrontPuppet": {
                                                    "type": "string",
                                                    "default": "showGrid"
                                                },
                                                "eventDate": {
                                                    "type": "string",
                                                    "default": "2023-11-22T19:19:00-08:00"
                                                },
                                                "eventDateString": {
                                                    "type": "string",
                                                    "default": "Nov 22, 2023"
                                                },
                                                "puppetBackFrameClass": {
                                                    "type": "string"
                                                },
                                                "mainbackgroundvideourl": {
                                                    "type": "string"
                                                },
                                                "canvasBack": {
                                                    "type": "object",
                                                    "properties": {
                                                        "objects": {
                                                            "type": "array",
                                                            "items": [{
                                                                "type": "null"
                                                            }]
                                                        },
                                                        "version": {
                                                            "type": "string",
                                                            "default": "5.3.0"
                                                        }
                                                    }
                                                },
                                                "puppetBackVideo": {
                                                    "type": "string",
                                                    "default": "#t=0.1#t=0.1#t=0.1#t=0.1#t=0.1#t=0.1#t=0.1"
                                                },
                                                "zip": {
                                                    "type": "string"
                                                },
                                                "mainbackgroundgifurl": {
                                                    "type": "string"
                                                },
                                                "giftRegistry": {
                                                    "type": "array",
                                                    "items": [{
                                                        "type": "null"
                                                    }]
                                                },
                                                "cardFrontBackground": {
                                                    "type": "string",
                                                    "default": "background-image: url('https://backendlessappcontent.com/876B8C32-01E1-6474-FFA9-06AFA3F13500/D7DD8A50-2CE0-B869-FF19-BF856519F500/files/web/cardBacks/21.png');background-size:cover;background-repeat:no-repeat;background-position: center center;"
                                                },
                                                "state": {
                                                    "type": "string"
                                                },
                                                "street": {
                                                    "type": "string"
                                                },
                                                "cardFrontBackgroundGifUrl": {
                                                    "type": "string"
                                                },
                                                "canvasBackImage": {
                                                    "type": "string",
                                                    "default": "https://backendlessappcontent.com/876B8C32-01E1-6474-FFA9-06AFA3F13500/03B6F34E-6A69-45E0-8B01-E7ADEC41AC12/files/canvas/F4558A9A-0AA9-40CA-9752-7141B07D2A62-1700104944232-back.png"
                                                },
                                                "envelopeColor": {
                                                    "type": "string",
                                                    "default": "YELLOWenvelope"
                                                },
                                                "eventTimeString": {
                                                    "type": "string",
                                                    "default": "1:20 PM"
                                                },
                                                "cardBackBackgroundGifUrl": {
                                                    "type": "string"
                                                },
                                                "eventOptions": {
                                                    "type": "array",
                                                    "items": [{
                                                        "type": "null"
                                                    }]
                                                },
                                                "puppetBackPosition": {
                                                    "type": "string"
                                                },
                                                "mainBackgroundUrl": {
                                                    "type": "string"
                                                },
                                                "cardFrontBackgroundVideoUrl": {
                                                    "type": "string"
                                                },
                                                "hostedByEmail": {
                                                    "type": "string",
                                                    "default": "seansean@puppetpartyapp.com"
                                                },
                                                "canvasFrontImageShort": {
                                                    "type": "string",
                                                    "default": "F4558A9A-0AA9-40CA-9752-7141B07D2A62-1700104944232-front"
                                                },
                                                "puppetFrontFrameShow": {
                                                    "type": "string",
                                                    "default": "hideGrid"
                                                },
                                                "cardBackBackgroundVideoUrl": {
                                                    "type": "string"
                                                },
                                                "hasBack": {
                                                    "type": "boolean",
                                                    "default": false
                                                },
                                                "city": {
                                                    "type": "string"
                                                },
                                                "canvasBackImageShort": {
                                                    "type": "string",
                                                    "default": "F4558A9A-0AA9-40CA-9752-7141B07D2A62-1700104944232-back"
                                                },
                                                "puppetBackFrameUrl": {
                                                    "type": "string"
                                                },
                                                "canvasFrontImage": {
                                                    "type": "string",
                                                    "default": "https://backendlessappcontent.com/876B8C32-01E1-6474-FFA9-06AFA3F13500/03B6F34E-6A69-45E0-8B01-E7ADEC41AC12/files/canvas/F4558A9A-0AA9-40CA-9752-7141B07D2A62-1700104944232-front.png"
                                                },
                                                "cardBackBackground": {
                                                    "type": "string"
                                                },
                                                "hostedByPhone": {
                                                    "type": "string",
                                                    "default": "(111) 111 - 1111"
                                                },
                                                "showBackPuppet": {
                                                    "type": "string",
                                                    "default": "hideGrid"
                                                },
                                                "puppetBackPoster": {
                                                    "type": "string"
                                                },
                                                "puppetFrontFrameUrl": {
                                                    "type": "string",
                                                    "default": "https://backendlessappcontent.com/876B8C32-01E1-6474-FFA9-06AFA3F13500/D7DD8A50-2CE0-B869-FF19-BF856519F500/files/web/data-2.png"
                                                },
                                                "locationName": {
                                                    "type": "string"
                                                },
                                                "hostedByName": {
                                                    "type": "string",
                                                    "default": "test"
                                                },
                                                "showMainBackgroundVideo": {
                                                    "type": "string",
                                                    "default": "hideGrid"
                                                },
                                                "note": {
                                                    "type": "string"
                                                }
                                            }
                                        }
                                    }
                                }]
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {}
                    }
                }
            }
        }
    },
    "getTemplatesCount": {
        "type": "object",
        "properties": {
            "url": {
                "type": "string",
                "default": "https://api.backendless.com/876B8C32-01E1-6474-FFA9-06AFA3F13500/D7DD8A50-2CE0-B869-FF19-BF856519F500/data/templates/count?where=keywords%20LIKE%20%27%25{terms}%25%27%20AND%20active%20%3D%20%27yes%27&pageSize={size}&offset={off}"
            },
            "method": {
                "type": "string",
                "default": "get"
            },
            "request": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {}
                    },
                    "query": {
                        "type": "object",
                        "properties": {
                            "off": {
                                "type": "string"
                            },
                            "size": {
                                "type": "string"
                            },
                            "terms": {
                                "type": "string"
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {
                            "user-token": {
                                "type": "string"
                            }
                        }
                    }
                }
            },
            "response": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {
                            "$": {
                                "type": "array",
                                "items": [{
                                    "type": "object",
                                    "properties": {
                                        "category": {
                                            "type": "null"
                                        },
                                        "cost": {
                                            "type": "number",
                                            "default": 1
                                        },
                                        "order": {
                                            "type": "null"
                                        },
                                        "active": {
                                            "type": "string",
                                            "default": "yes"
                                        },
                                        "keywords": {
                                            "type": "string",
                                            "default": "kids birthday"
                                        },
                                        "created": {
                                            "type": "number",
                                            "default": 1697588571459
                                        },
                                        "ownerId": {
                                            "type": "null"
                                        },
                                        "imageUrl": {
                                            "type": "null"
                                        },
                                        "updated": {
                                            "type": "number",
                                            "default": 1700183332564
                                        },
                                        "___class": {
                                            "type": "string",
                                            "default": "templates"
                                        },
                                        "objectId": {
                                            "type": "string",
                                            "default": "72921E8B-F38B-4435-B1C7-287D9F76487E"
                                        },
                                        "title": {
                                            "type": "string",
                                            "default": "Fishn' For Fifty"
                                        },
                                        "invitation": {
                                            "type": "object",
                                            "properties": {
                                                "puppetFrontVideo": {
                                                    "type": "string",
                                                    "default": "https://backendlessappcontent.com/876B8C32-01E1-6474-FFA9-06AFA3F13500/D7DD8A50-2CE0-B869-FF19-BF856519F500/files/videos/F4558A9A-0AA9-40CA-9752-7141B07D2A62_A3C336BA-12A0-4D8D-80BD-D4AD6420FB22.mp4#t=0.1#t=0.1#t=0.1#t=0.1#t=0.1#t=0.1#t=0.1"
                                                },
                                                "canvasFront": {
                                                    "type": "object",
                                                    "properties": {
                                                        "version": {
                                                            "type": "string",
                                                            "default": "5.3.0"
                                                        },
                                                        "objects": {
                                                            "type": "array",
                                                            "items": [{
                                                                "type": "object",
                                                                "properties": {
                                                                    "visible": {
                                                                        "type": "boolean",
                                                                        "default": true
                                                                    },
                                                                    "height": {
                                                                        "type": "number",
                                                                        "default": 61.02
                                                                    },
                                                                    "skewX": {
                                                                        "type": "number",
                                                                        "default": 0
                                                                    },
                                                                    "scaleY": {
                                                                        "type": "number",
                                                                        "default": 2.39
                                                                    },
                                                                    "fontStyle": {
                                                                        "type": "string",
                                                                        "default": "normal"
                                                                    },
                                                                    "top": {
                                                                        "type": "number",
                                                                        "default": 303.94
                                                                    },
                                                                    "originY": {
                                                                        "type": "string",
                                                                        "default": "top"
                                                                    },
                                                                    "stroke": {
                                                                        "type": "null"
                                                                    },
                                                                    "charSpacing": {
                                                                        "type": "number",
                                                                        "default": 0
                                                                    },
                                                                    "globalCompositeOperation": {
                                                                        "type": "string",
                                                                        "default": "source-over"
                                                                    },
                                                                    "fontWeight": {
                                                                        "type": "string",
                                                                        "default": "400"
                                                                    },
                                                                    "strokeDashOffset": {
                                                                        "type": "number",
                                                                        "default": 0
                                                                    },
                                                                    "fillRule": {
                                                                        "type": "string",
                                                                        "default": "nonzero"
                                                                    },
                                                                    "shadow": {
                                                                        "type": "object",
                                                                        "properties": {
                                                                            "color": {
                                                                                "type": "string",
                                                                                "default": "rgba(0,0,0,1)"
                                                                            },
                                                                            "offsetX": {
                                                                                "type": "number",
                                                                                "default": 0
                                                                            },
                                                                            "blur": {
                                                                                "type": "number",
                                                                                "default": 0
                                                                            },
                                                                            "affectStroke": {
                                                                                "type": "boolean",
                                                                                "default": false
                                                                            },
                                                                            "nonScaling": {
                                                                                "type": "boolean",
                                                                                "default": false
                                                                            },
                                                                            "offsetY": {
                                                                                "type": "number",
                                                                                "default": 0
                                                                            }
                                                                        }
                                                                    },
                                                                    "version": {
                                                                        "type": "string",
                                                                        "default": "5.3.0"
                                                                    },
                                                                    "skewY": {
                                                                        "type": "number",
                                                                        "default": 0
                                                                    },
                                                                    "width": {
                                                                        "type": "number",
                                                                        "default": 87.71
                                                                    },
                                                                    "paintFirst": {
                                                                        "type": "string",
                                                                        "default": "fill"
                                                                    },
                                                                    "left": {
                                                                        "type": "number",
                                                                        "default": 99.79
                                                                    },
                                                                    "originX": {
                                                                        "type": "string",
                                                                        "default": "left"
                                                                    },
                                                                    "type": {
                                                                        "type": "string",
                                                                        "default": "textbox"
                                                                    },
                                                                    "splitByGrapheme": {
                                                                        "type": "boolean",
                                                                        "default": false
                                                                    },
                                                                    "lineHeight": {
                                                                        "type": "number",
                                                                        "default": 1
                                                                    },
                                                                    "path": {
                                                                        "type": "null"
                                                                    },
                                                                    "pathSide": {
                                                                        "type": "string",
                                                                        "default": "left"
                                                                    },
                                                                    "backgroundColor": {
                                                                        "type": "string"
                                                                    },
                                                                    "flipY": {
                                                                        "type": "boolean",
                                                                        "default": false
                                                                    },
                                                                    "pathStartOffset": {
                                                                        "type": "number",
                                                                        "default": 0
                                                                    },
                                                                    "strokeLineJoin": {
                                                                        "type": "string",
                                                                        "default": "miter"
                                                                    },
                                                                    "strokeWidth": {
                                                                        "type": "number",
                                                                        "default": 1
                                                                    },
                                                                    "linethrough": {
                                                                        "type": "boolean",
                                                                        "default": false
                                                                    },
                                                                    "minWidth": {
                                                                        "type": "number",
                                                                        "default": 20
                                                                    },
                                                                    "text": {
                                                                        "type": "string",
                                                                        "default": "Type something awesome!"
                                                                    },
                                                                    "opacity": {
                                                                        "type": "number",
                                                                        "default": 1
                                                                    },
                                                                    "fill": {
                                                                        "type": "string",
                                                                        "default": "#FFB6C1"
                                                                    },
                                                                    "direction": {
                                                                        "type": "string",
                                                                        "default": "ltr"
                                                                    },
                                                                    "overline": {
                                                                        "type": "boolean",
                                                                        "default": false
                                                                    },
                                                                    "fontSize": {
                                                                        "type": "number",
                                                                        "default": 18
                                                                    },
                                                                    "strokeMiterLimit": {
                                                                        "type": "number",
                                                                        "default": 4
                                                                    },
                                                                    "textAlign": {
                                                                        "type": "string",
                                                                        "default": "left"
                                                                    },
                                                                    "fontFamily": {
                                                                        "type": "string",
                                                                        "default": "quicksand"
                                                                    },
                                                                    "textBackgroundColor": {
                                                                        "type": "string"
                                                                    },
                                                                    "strokeDashArray": {
                                                                        "type": "null"
                                                                    },
                                                                    "strokeUniform": {
                                                                        "type": "boolean",
                                                                        "default": false
                                                                    },
                                                                    "pathAlign": {
                                                                        "type": "string",
                                                                        "default": "baseline"
                                                                    },
                                                                    "angle": {
                                                                        "type": "number",
                                                                        "default": 0
                                                                    },
                                                                    "styles": {
                                                                        "type": "array",
                                                                        "items": [{
                                                                            "type": "null"
                                                                        }]
                                                                    },
                                                                    "flipX": {
                                                                        "type": "boolean",
                                                                        "default": false
                                                                    },
                                                                    "scaleX": {
                                                                        "type": "number",
                                                                        "default": 2.39
                                                                    },
                                                                    "strokeLineCap": {
                                                                        "type": "string",
                                                                        "default": "butt"
                                                                    },
                                                                    "underline": {
                                                                        "type": "boolean",
                                                                        "default": false
                                                                    }
                                                                }
                                                            }]
                                                        }
                                                    }
                                                },
                                                "titleText": {
                                                    "type": "string",
                                                    "default": "gfggv"
                                                },
                                                "puppetBackFrameShow": {
                                                    "type": "string",
                                                    "default": "hideGrid"
                                                },
                                                "showCardBackBackgroundVideoUrl": {
                                                    "type": "string",
                                                    "default": "hideGrid"
                                                },
                                                "eventTime": {
                                                    "type": "string",
                                                    "default": "2023-11-15T13:20:00-08:00"
                                                },
                                                "puppetFrontPosition": {
                                                    "type": "string",
                                                    "default": "vclassbottom"
                                                },
                                                "puppetFrontPoster": {
                                                    "type": "string",
                                                    "default": "https://backendlessappcontent.com/876B8C32-01E1-6474-FFA9-06AFA3F13500/D7DD8A50-2CE0-B869-FF19-BF856519F500/files/posters/F4558A9A-0AA9-40CA-9752-7141B07D2A62_A3C336BA-12A0-4D8D-80BD-D4AD6420FB22.jpg"
                                                },
                                                "puppetFrontFrameClass": {
                                                    "type": "string",
                                                    "default": "videoGrid"
                                                },
                                                "showCardFrontBackgroundVideoUrl": {
                                                    "type": "string",
                                                    "default": "hideGrid"
                                                },
                                                "puppetFrontFramePosition": {
                                                    "type": "string",
                                                    "default": "puppetframebottom"
                                                },
                                                "showFrontPuppet": {
                                                    "type": "string",
                                                    "default": "showGrid"
                                                },
                                                "eventDate": {
                                                    "type": "string",
                                                    "default": "2023-11-22T19:19:00-08:00"
                                                },
                                                "eventDateString": {
                                                    "type": "string",
                                                    "default": "Nov 22, 2023"
                                                },
                                                "puppetBackFrameClass": {
                                                    "type": "string"
                                                },
                                                "mainbackgroundvideourl": {
                                                    "type": "string"
                                                },
                                                "canvasBack": {
                                                    "type": "object",
                                                    "properties": {
                                                        "objects": {
                                                            "type": "array",
                                                            "items": [{
                                                                "type": "null"
                                                            }]
                                                        },
                                                        "version": {
                                                            "type": "string",
                                                            "default": "5.3.0"
                                                        }
                                                    }
                                                },
                                                "puppetBackVideo": {
                                                    "type": "string",
                                                    "default": "#t=0.1#t=0.1#t=0.1#t=0.1#t=0.1#t=0.1#t=0.1"
                                                },
                                                "zip": {
                                                    "type": "string"
                                                },
                                                "mainbackgroundgifurl": {
                                                    "type": "string"
                                                },
                                                "giftRegistry": {
                                                    "type": "array",
                                                    "items": [{
                                                        "type": "null"
                                                    }]
                                                },
                                                "cardFrontBackground": {
                                                    "type": "string",
                                                    "default": "background-image: url('https://backendlessappcontent.com/876B8C32-01E1-6474-FFA9-06AFA3F13500/D7DD8A50-2CE0-B869-FF19-BF856519F500/files/web/cardBacks/21.png');background-size:cover;background-repeat:no-repeat;background-position: center center;"
                                                },
                                                "state": {
                                                    "type": "string"
                                                },
                                                "street": {
                                                    "type": "string"
                                                },
                                                "cardFrontBackgroundGifUrl": {
                                                    "type": "string"
                                                },
                                                "canvasBackImage": {
                                                    "type": "string",
                                                    "default": "https://backendlessappcontent.com/876B8C32-01E1-6474-FFA9-06AFA3F13500/03B6F34E-6A69-45E0-8B01-E7ADEC41AC12/files/canvas/F4558A9A-0AA9-40CA-9752-7141B07D2A62-1700104944232-back.png"
                                                },
                                                "envelopeColor": {
                                                    "type": "string",
                                                    "default": "YELLOWenvelope"
                                                },
                                                "eventTimeString": {
                                                    "type": "string",
                                                    "default": "1:20 PM"
                                                },
                                                "cardBackBackgroundGifUrl": {
                                                    "type": "string"
                                                },
                                                "eventOptions": {
                                                    "type": "array",
                                                    "items": [{
                                                        "type": "null"
                                                    }]
                                                },
                                                "puppetBackPosition": {
                                                    "type": "string"
                                                },
                                                "mainBackgroundUrl": {
                                                    "type": "string"
                                                },
                                                "cardFrontBackgroundVideoUrl": {
                                                    "type": "string"
                                                },
                                                "hostedByEmail": {
                                                    "type": "string",
                                                    "default": "seansean@puppetpartyapp.com"
                                                },
                                                "canvasFrontImageShort": {
                                                    "type": "string",
                                                    "default": "F4558A9A-0AA9-40CA-9752-7141B07D2A62-1700104944232-front"
                                                },
                                                "puppetFrontFrameShow": {
                                                    "type": "string",
                                                    "default": "hideGrid"
                                                },
                                                "cardBackBackgroundVideoUrl": {
                                                    "type": "string"
                                                },
                                                "hasBack": {
                                                    "type": "boolean",
                                                    "default": false
                                                },
                                                "city": {
                                                    "type": "string"
                                                },
                                                "canvasBackImageShort": {
                                                    "type": "string",
                                                    "default": "F4558A9A-0AA9-40CA-9752-7141B07D2A62-1700104944232-back"
                                                },
                                                "puppetBackFrameUrl": {
                                                    "type": "string"
                                                },
                                                "canvasFrontImage": {
                                                    "type": "string",
                                                    "default": "https://backendlessappcontent.com/876B8C32-01E1-6474-FFA9-06AFA3F13500/03B6F34E-6A69-45E0-8B01-E7ADEC41AC12/files/canvas/F4558A9A-0AA9-40CA-9752-7141B07D2A62-1700104944232-front.png"
                                                },
                                                "cardBackBackground": {
                                                    "type": "string"
                                                },
                                                "hostedByPhone": {
                                                    "type": "string",
                                                    "default": "(111) 111 - 1111"
                                                },
                                                "showBackPuppet": {
                                                    "type": "string",
                                                    "default": "hideGrid"
                                                },
                                                "puppetBackPoster": {
                                                    "type": "string"
                                                },
                                                "puppetFrontFrameUrl": {
                                                    "type": "string",
                                                    "default": "https://backendlessappcontent.com/876B8C32-01E1-6474-FFA9-06AFA3F13500/D7DD8A50-2CE0-B869-FF19-BF856519F500/files/web/data-2.png"
                                                },
                                                "locationName": {
                                                    "type": "string"
                                                },
                                                "hostedByName": {
                                                    "type": "string",
                                                    "default": "test"
                                                },
                                                "showMainBackgroundVideo": {
                                                    "type": "string",
                                                    "default": "hideGrid"
                                                },
                                                "note": {
                                                    "type": "string"
                                                }
                                            }
                                        }
                                    }
                                }]
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {}
                    }
                }
            }
        }
    },
    "bTimes": {
        "type": "object",
        "properties": {
            "url": {
                "type": "string",
                "default": "https://api.backendless.com/876B8C32-01E1-6474-FFA9-06AFA3F13500/D7DD8A50-2CE0-B869-FF19-BF856519F500/data/times/count?where=puppetId%20%3D%20%27{pid}%27"
            },
            "method": {
                "type": "string",
                "default": "get"
            },
            "request": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {}
                    },
                    "query": {
                        "type": "object",
                        "properties": {
                            "pid": {
                                "type": "string"
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {
                            "user-token": {
                                "type": "string"
                            }
                        }
                    }
                }
            },
            "response": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {
                            "$": {
                                "type": "object",
                                "properties": {}
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {}
                    }
                }
            }
        }
    },
    "sendService": {
        "type": "object",
        "properties": {
            "url": {
                "type": "string",
                "default": "https://api.backendless.com/876B8C32-01E1-6474-FFA9-06AFA3F13500/D7DD8A50-2CE0-B869-FF19-BF856519F500/services/build/buildit"
            },
            "method": {
                "type": "string",
                "default": "post"
            },
            "request": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {
                            "app": {
                                "type": "string"
                            },
                            "ownerId": {
                                "type": "string"
                            },
                            "seconds": {
                                "type": "string"
                            },
                            "email": {
                                "type": "string"
                            },
                            "puppetName": {
                                "type": "string"
                            },
                            "audioFile": {
                                "type": "string"
                            },
                            "puppetImage": {
                                "type": "string"
                            },
                            "fileName": {
                                "type": "string"
                            },
                            "puppetId": {
                                "type": "string"
                            }
                        }
                    },
                    "query": {
                        "type": "object",
                        "properties": {}
                    },
                    "headers": {
                        "type": "object",
                        "properties": {
                            "user-token": {
                                "type": "string"
                            },
                            "Content-Type": {
                                "type": "string",
                                "default": "application/json"
                            }
                        }
                    }
                }
            },
            "response": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {
                            "name": {
                                "type": "string"
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {}
                    }
                }
            }
        }
    },
    "resetUpdate": {
        "type": "object",
        "properties": {
            "url": {
                "type": "string",
                "default": "https://api.backendless.com/876B8C32-01E1-6474-FFA9-06AFA3F13500/D7DD8A50-2CE0-B869-FF19-BF856519F500/services/resetflow/updatereset"
            },
            "method": {
                "type": "string",
                "default": "post"
            },
            "request": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {
                            "email": {
                                "type": "string"
                            },
                            "code": {
                                "type": "string"
                            },
                            "password": {
                                "type": "string"
                            }
                        }
                    },
                    "query": {
                        "type": "object",
                        "properties": {}
                    },
                    "headers": {
                        "type": "object",
                        "properties": {
                            "Content-Type": {
                                "type": "string",
                                "default": "application/json"
                            }
                        }
                    }
                }
            },
            "response": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {
                            "socialAccount": {
                                "type": "string",
                                "default": "BACKENDLESS"
                            },
                            "___class": {
                                "type": "string",
                                "default": "Users"
                            },
                            "cusId": {
                                "type": "string",
                                "default": "cus_K3cqshg87roiGc"
                            },
                            "renew": {
                                "type": "string",
                                "default": "none"
                            },
                            "lastLogin": {
                                "type": "number",
                                "default": 1656659678000
                            },
                            "accountType": {
                                "type": "string",
                                "default": "BACKENDLESS"
                            },
                            "created": {
                                "type": "number",
                                "default": 1629219432000
                            },
                            "phone": {
                                "type": "string",
                                "default": "blurb"
                            },
                            "blUserLocale": {
                                "type": "string",
                                "default": "en"
                            },
                            "updated": {
                                "type": "number",
                                "default": 1657029837017
                            },
                            "ownerId": {
                                "type": "string",
                                "default": "4A65C42A-8F9D-4FDF-9DF0-DA5C49A4B066"
                            },
                            "subId": {
                                "type": "string",
                                "default": "sub_K3cqYJqQ1YxYme"
                            },
                            "marketing": {
                                "type": "string",
                                "default": "yes"
                            },
                            "usertype": {
                                "type": "string"
                            },
                            "lastVideo": {
                                "type": "null"
                            },
                            "email": {
                                "type": "string",
                                "default": "sean.butler@puppetchat.com"
                            },
                            "userStatus": {
                                "type": "string",
                                "default": "ENABLED"
                            },
                            "oAuthIdentities": {
                                "type": "null"
                            },
                            "objectId": {
                                "type": "string",
                                "default": "4A65C42A-8F9D-4FDF-9DF0-DA5C49A4B066"
                            },
                            "name": {
                                "type": "null"
                            },
                            "digits": {
                                "type": "string",
                                "default": "none"
                            },
                            "account": {
                                "type": "string",
                                "default": "Premium"
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {}
                    }
                }
            }
        }
    },
    "confirmCode": {
        "type": "object",
        "properties": {
            "url": {
                "type": "string",
                "default": "https://api.backendless.com/876B8C32-01E1-6474-FFA9-06AFA3F13500/D7DD8A50-2CE0-B869-FF19-BF856519F500/services/resetflow/confirmreset"
            },
            "method": {
                "type": "string",
                "default": "post"
            },
            "request": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {
                            "code": {
                                "type": "string"
                            },
                            "email": {
                                "type": "string"
                            }
                        }
                    },
                    "query": {
                        "type": "object",
                        "properties": {}
                    },
                    "headers": {
                        "type": "object",
                        "properties": {
                            "Content-Type": {
                                "type": "string",
                                "default": "application/json"
                            }
                        }
                    }
                }
            },
            "response": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {}
                    },
                    "headers": {
                        "type": "object",
                        "properties": {}
                    }
                }
            }
        }
    },
    "sendCode": {
        "type": "object",
        "properties": {
            "url": {
                "type": "string",
                "default": "https://api.backendless.com/876B8C32-01E1-6474-FFA9-06AFA3F13500/D7DD8A50-2CE0-B869-FF19-BF856519F500/services/resetflow/sendreset"
            },
            "method": {
                "type": "string",
                "default": "post"
            },
            "request": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {
                            "blank": {
                                "type": "string",
                                "default": "pchatforgot"
                            },
                            "email": {
                                "type": "string"
                            }
                        }
                    },
                    "query": {
                        "type": "object",
                        "properties": {}
                    },
                    "headers": {
                        "type": "object",
                        "properties": {
                            "Content-Type": {
                                "type": "string",
                                "default": "application/json"
                            }
                        }
                    }
                }
            },
            "response": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {}
                    },
                    "headers": {
                        "type": "object",
                        "properties": {}
                    }
                }
            }
        }
    },
    "deleteInvite": {
        "type": "object",
        "properties": {
            "url": {
                "type": "string",
                "default": "https://api.backendless.com/876B8C32-01E1-6474-FFA9-06AFA3F13500/D7DD8A50-2CE0-B869-FF19-BF856519F500/data/partyInvites/{id}"
            },
            "method": {
                "type": "string",
                "default": "delete"
            },
            "request": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {}
                    },
                    "query": {
                        "type": "object",
                        "properties": {
                            "id": {
                                "type": "string"
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {
                            "user-token": {
                                "type": "string"
                            }
                        }
                    }
                }
            },
            "response": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {
                            "$": {
                                "type": "object",
                                "properties": {}
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {}
                    }
                }
            }
        }
    },
    "getCardBacks": {
        "type": "object",
        "properties": {
            "url": {
                "type": "string",
                "default": "https://api.backendless.com/876B8C32-01E1-6474-FFA9-06AFA3F13500/D7DD8A50-2CE0-B869-FF19-BF856519F500/data/cardBackgrounds?where=category%20LIKE%20%27%25{name}%25%27&sortBy=premium%20asc%2Cname%20asc&pageSize=100"
            },
            "method": {
                "type": "string",
                "default": "get"
            },
            "request": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {}
                    },
                    "query": {
                        "type": "object",
                        "properties": {
                            "name": {
                                "type": "string"
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {
                            "user-token": {
                                "type": "string"
                            }
                        }
                    }
                }
            },
            "response": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {
                            "$": {
                                "type": "array",
                                "items": [{
                                    "type": "object",
                                    "properties": {
                                        "name": {
                                            "type": "string",
                                            "default": "confetti1"
                                        },
                                        "assetType": {
                                            "type": "string",
                                            "default": "image"
                                        },
                                        "titleColor": {
                                            "type": "string",
                                            "default": "#000000"
                                        },
                                        "updated": {
                                            "type": "number",
                                            "default": 1666997978547
                                        },
                                        "premium": {
                                            "type": "string",
                                            "default": "no"
                                        },
                                        "bodyColor": {
                                            "type": "string",
                                            "default": "#000000"
                                        },
                                        "assetUrl": {
                                            "type": "string",
                                            "default": "https://backendlessappcontent.com/876B8C32-01E1-6474-FFA9-06AFA3F13500/D7DD8A50-2CE0-B869-FF19-BF856519F500/files/web/conf.png"
                                        },
                                        "objectId": {
                                            "type": "string",
                                            "default": "A94F428E-D7AA-40F2-9255-94CCCCCF722F"
                                        },
                                        "category": {
                                            "type": "string",
                                            "default": "birthday"
                                        },
                                        "headlineColor": {
                                            "type": "string",
                                            "default": "#000000"
                                        },
                                        "___class": {
                                            "type": "string",
                                            "default": "cardBackgrounds"
                                        },
                                        "selectedColor": {
                                            "type": "string",
                                            "default": "notSelected"
                                        },
                                        "ownerId": {
                                            "type": "null"
                                        },
                                        "created": {
                                            "type": "number",
                                            "default": 1666997946259
                                        },
                                        "active": {
                                            "type": "string",
                                            "default": "yes"
                                        }
                                    }
                                }]
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {}
                    }
                }
            }
        }
    },
    "getEventMessages": {
        "type": "object",
        "properties": {
            "url": {
                "type": "string",
                "default": "https://api.backendless.com/876B8C32-01E1-6474-FFA9-06AFA3F13500/D7DD8A50-2CE0-B869-FF19-BF856519F500/data/partyMessages?where=eventId%20%3D%20%27{id}%27&sortBy=created%20desc&pageSize=100"
            },
            "method": {
                "type": "string",
                "default": "get"
            },
            "request": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {}
                    },
                    "query": {
                        "type": "object",
                        "properties": {
                            "id": {
                                "type": "string"
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {
                            "user-token": {
                                "type": "string"
                            }
                        }
                    }
                }
            },
            "response": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {
                            "$": {
                                "type": "array",
                                "items": [{
                                    "type": "object",
                                    "properties": {
                                        "name": {
                                            "type": "string",
                                            "default": "confetti1"
                                        },
                                        "assetType": {
                                            "type": "string",
                                            "default": "image"
                                        },
                                        "titleColor": {
                                            "type": "string",
                                            "default": "#000000"
                                        },
                                        "updated": {
                                            "type": "number",
                                            "default": 1666997978547
                                        },
                                        "premium": {
                                            "type": "string",
                                            "default": "no"
                                        },
                                        "bodyColor": {
                                            "type": "string",
                                            "default": "#000000"
                                        },
                                        "assetUrl": {
                                            "type": "string",
                                            "default": "https://backendlessappcontent.com/876B8C32-01E1-6474-FFA9-06AFA3F13500/D7DD8A50-2CE0-B869-FF19-BF856519F500/files/web/conf.png"
                                        },
                                        "objectId": {
                                            "type": "string",
                                            "default": "A94F428E-D7AA-40F2-9255-94CCCCCF722F"
                                        },
                                        "category": {
                                            "type": "string",
                                            "default": "birthday"
                                        },
                                        "headlineColor": {
                                            "type": "string",
                                            "default": "#000000"
                                        },
                                        "___class": {
                                            "type": "string",
                                            "default": "cardBackgrounds"
                                        },
                                        "selectedColor": {
                                            "type": "string",
                                            "default": "notSelected"
                                        },
                                        "ownerId": {
                                            "type": "null"
                                        },
                                        "created": {
                                            "type": "number",
                                            "default": 1666997946259
                                        },
                                        "active": {
                                            "type": "string",
                                            "default": "yes"
                                        }
                                    }
                                }]
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {}
                    }
                }
            }
        }
    },
    "getContacts": {
        "type": "object",
        "properties": {
            "url": {
                "type": "string",
                "default": "https://api.backendless.com/876B8C32-01E1-6474-FFA9-06AFA3F13500/D7DD8A50-2CE0-B869-FF19-BF856519F500/data/partyContacts?sortBy=name%20asc&pageSize=100&offset={off}"
            },
            "method": {
                "type": "string",
                "default": "get"
            },
            "request": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {}
                    },
                    "query": {
                        "type": "object",
                        "properties": {
                            "off": {
                                "type": "string"
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {
                            "user-token": {
                                "type": "string"
                            }
                        }
                    }
                }
            },
            "response": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {
                            "$": {
                                "type": "array",
                                "items": [{
                                    "type": "object",
                                    "properties": {}
                                }]
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {}
                    }
                }
            }
        }
    },
    "updateEventMessages": {
        "type": "object",
        "properties": {
            "url": {
                "type": "string",
                "default": "https://api.backendless.com/876B8C32-01E1-6474-FFA9-06AFA3F13500/D7DD8A50-2CE0-B869-FF19-BF856519F500/data/partyEvents/{id}"
            },
            "method": {
                "type": "string",
                "default": "put"
            },
            "request": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {
                            "publicMessages": {
                                "type": "boolean",
                                "default": null
                            }
                        }
                    },
                    "query": {
                        "type": "object",
                        "properties": {
                            "id": {
                                "type": "string"
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {
                            "user-token": {
                                "type": "string"
                            },
                            "Content-Type": {
                                "type": "string",
                                "default": "application/json"
                            }
                        }
                    }
                }
            },
            "response": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {
                            "name": {
                                "type": "string"
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {}
                    }
                }
            }
        }
    },
    "deleteContact": {
        "type": "object",
        "properties": {
            "url": {
                "type": "string",
                "default": "https://api.backendless.com/876B8C32-01E1-6474-FFA9-06AFA3F13500/D7DD8A50-2CE0-B869-FF19-BF856519F500/data/partyContacts/{id}"
            },
            "method": {
                "type": "string",
                "default": "delete"
            },
            "request": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {}
                    },
                    "query": {
                        "type": "object",
                        "properties": {
                            "id": {
                                "type": "string"
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {
                            "user-token": {
                                "type": "string"
                            }
                        }
                    }
                }
            },
            "response": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {
                            "$": {
                                "type": "object",
                                "properties": {}
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {}
                    }
                }
            }
        }
    },
    "deleteMessage": {
        "type": "object",
        "properties": {
            "url": {
                "type": "string",
                "default": "https://api.backendless.com/876B8C32-01E1-6474-FFA9-06AFA3F13500/D7DD8A50-2CE0-B869-FF19-BF856519F500/data/partyMessages/{id}"
            },
            "method": {
                "type": "string",
                "default": "delete"
            },
            "request": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {}
                    },
                    "query": {
                        "type": "object",
                        "properties": {
                            "id": {
                                "type": "string"
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {
                            "user-token": {
                                "type": "string"
                            }
                        }
                    }
                }
            },
            "response": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {
                            "$": {
                                "type": "object",
                                "properties": {}
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {}
                    }
                }
            }
        }
    },
    "createEvent": {
        "type": "object",
        "properties": {
            "url": {
                "type": "string",
                "default": "https://api.backendless.com/876B8C32-01E1-6474-FFA9-06AFA3F13500/D7DD8A50-2CE0-B869-FF19-BF856519F500/data/partyEvents"
            },
            "method": {
                "type": "string",
                "default": "post"
            },
            "request": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {
                            "video": {
                                "type": "string"
                            },
                            "hostedby": {
                                "type": "string"
                            },
                            "maybe": {
                                "type": "number",
                                "default": 0
                            },
                            "version": {
                                "type": "string"
                            },
                            "backgroundUrl": {
                                "type": "string"
                            },
                            "publicMessages": {
                                "type": "boolean",
                                "default": null
                            },
                            "eventTimestamp": {
                                "type": "string"
                            },
                            "inviteId": {
                                "type": "string"
                            },
                            "eventTime": {
                                "type": "string"
                            },
                            "saveNew": {
                                "type": "string"
                            },
                            "timeString": {
                                "type": "string"
                            },
                            "dateString": {
                                "type": "string"
                            },
                            "invitation": {
                                "type": "any"
                            },
                            "transactionId": {
                                "type": "string"
                            },
                            "notAttending": {
                                "type": "number",
                                "default": 0
                            },
                            "reminderDays": {
                                "type": "number",
                                "default": null
                            },
                            "invites": {
                                "type": "number",
                                "default": null
                            },
                            "title": {
                                "type": "string"
                            },
                            "eventDate": {
                                "type": "string"
                            },
                            "guests": {
                                "type": "any"
                            },
                            "attending": {
                                "type": "number",
                                "default": 0
                            },
                            "poster": {
                                "type": "string"
                            },
                            "notResponded": {
                                "type": "number",
                                "default": null
                            },
                            "status": {
                                "type": "string",
                                "default": "Sending Invites"
                            },
                            "timezone": {
                                "type": "string"
                            }
                        }
                    },
                    "query": {
                        "type": "object",
                        "properties": {}
                    },
                    "headers": {
                        "type": "object",
                        "properties": {
                            "user-token": {
                                "type": "string"
                            },
                            "Content-Type": {
                                "type": "string",
                                "default": "application/json"
                            }
                        }
                    }
                }
            },
            "response": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {
                            "name": {
                                "type": "string"
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {}
                    }
                }
            }
        }
    },
    "getEvents": {
        "type": "object",
        "properties": {
            "url": {
                "type": "string",
                "default": "https://api.backendless.com/876B8C32-01E1-6474-FFA9-06AFA3F13500/D7DD8A50-2CE0-B869-FF19-BF856519F500/data/partyEvents?where=eventTimestamp%3E{end}&sortBy=eventTimestamp%20asc&pageSize=100"
            },
            "method": {
                "type": "string",
                "default": "get"
            },
            "request": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {}
                    },
                    "query": {
                        "type": "object",
                        "properties": {
                            "end": {
                                "type": "string"
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {
                            "user-token": {
                                "type": "string"
                            }
                        }
                    }
                }
            },
            "response": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {
                            "$": {
                                "type": "array",
                                "items": [{
                                    "type": "object",
                                    "properties": {
                                        "name": {
                                            "type": "string",
                                            "default": "confetti1"
                                        },
                                        "assetType": {
                                            "type": "string",
                                            "default": "image"
                                        },
                                        "titleColor": {
                                            "type": "string",
                                            "default": "#000000"
                                        },
                                        "updated": {
                                            "type": "number",
                                            "default": 1666997978547
                                        },
                                        "premium": {
                                            "type": "string",
                                            "default": "no"
                                        },
                                        "bodyColor": {
                                            "type": "string",
                                            "default": "#000000"
                                        },
                                        "assetUrl": {
                                            "type": "string",
                                            "default": "https://backendlessappcontent.com/876B8C32-01E1-6474-FFA9-06AFA3F13500/D7DD8A50-2CE0-B869-FF19-BF856519F500/files/web/conf.png"
                                        },
                                        "objectId": {
                                            "type": "string",
                                            "default": "A94F428E-D7AA-40F2-9255-94CCCCCF722F"
                                        },
                                        "category": {
                                            "type": "string",
                                            "default": "birthday"
                                        },
                                        "headlineColor": {
                                            "type": "string",
                                            "default": "#000000"
                                        },
                                        "___class": {
                                            "type": "string",
                                            "default": "cardBackgrounds"
                                        },
                                        "selectedColor": {
                                            "type": "string",
                                            "default": "notSelected"
                                        },
                                        "ownerId": {
                                            "type": "null"
                                        },
                                        "created": {
                                            "type": "number",
                                            "default": 1666997946259
                                        },
                                        "active": {
                                            "type": "string",
                                            "default": "yes"
                                        }
                                    }
                                }]
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {}
                    }
                }
            }
        }
    },
    "buildToken": {
        "type": "object",
        "properties": {
            "url": {
                "type": "string",
                "default": "https://api.stripe.com/v1/tokens"
            },
            "method": {
                "type": "string",
                "default": "post"
            },
            "request": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {
                            "card[exp_year]": {
                                "type": "string"
                            },
                            "card[address_zip]": {
                                "type": "string"
                            },
                            "card[cvc]": {
                                "type": "string"
                            },
                            "card[exp_month]": {
                                "type": "string"
                            },
                            "card[number]": {
                                "type": "string"
                            }
                        }
                    },
                    "query": {
                        "type": "object",
                        "properties": {}
                    },
                    "headers": {
                        "type": "object",
                        "properties": {
                            "Authorization": {
                                "type": "string",
                                "default": "Bearer pk_live_hQna3Qpf3o0fpgT9QMvkOMrT"
                            },
                            "Content-Type": {
                                "type": "string",
                                "default": "application/x-www-form-urlencoded"
                            }
                        }
                    }
                }
            },
            "response": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {
                            "$": {
                                "type": "object",
                                "properties": {
                                    "client_ip": {
                                        "type": "string",
                                        "default": "52.7.79.49"
                                    },
                                    "card": {
                                        "type": "object",
                                        "properties": {
                                            "exp_month": {
                                                "type": "number",
                                                "default": 12
                                            },
                                            "address_line1_check": {
                                                "type": "null"
                                            },
                                            "country": {
                                                "type": "string",
                                                "default": "US"
                                            },
                                            "dynamic_last4": {
                                                "type": "null"
                                            },
                                            "address_line2": {
                                                "type": "null"
                                            },
                                            "address_state": {
                                                "type": "null"
                                            },
                                            "address_zip": {
                                                "type": "string",
                                                "default": "90049"
                                            },
                                            "address_country": {
                                                "type": "null"
                                            },
                                            "last4": {
                                                "type": "string",
                                                "default": "4444"
                                            },
                                            "object": {
                                                "type": "string",
                                                "default": "card"
                                            },
                                            "address_line1": {
                                                "type": "null"
                                            },
                                            "address_zip_check": {
                                                "type": "string",
                                                "default": "unchecked"
                                            },
                                            "networks": {
                                                "type": "object",
                                                "properties": {
                                                    "preferred": {
                                                        "type": "null"
                                                    }
                                                }
                                            },
                                            "funding": {
                                                "type": "string",
                                                "default": "credit"
                                            },
                                            "tokenization_method": {
                                                "type": "null"
                                            },
                                            "id": {
                                                "type": "string",
                                                "default": "card_1OxCCiL5nxN8fYaiLfgi0rcY"
                                            },
                                            "name": {
                                                "type": "null"
                                            },
                                            "wallet": {
                                                "type": "null"
                                            },
                                            "address_city": {
                                                "type": "null"
                                            },
                                            "cvc_check": {
                                                "type": "string",
                                                "default": "unchecked"
                                            },
                                            "exp_year": {
                                                "type": "number",
                                                "default": 2025
                                            },
                                            "brand": {
                                                "type": "string",
                                                "default": "MasterCard"
                                            }
                                        }
                                    },
                                    "created": {
                                        "type": "number",
                                        "default": 1711129712
                                    },
                                    "type": {
                                        "type": "string",
                                        "default": "card"
                                    },
                                    "id": {
                                        "type": "string",
                                        "default": "tok_1OxCCiL5nxN8fYaioQkuBAKi"
                                    },
                                    "object": {
                                        "type": "string",
                                        "default": "token"
                                    },
                                    "livemode": {
                                        "type": "boolean",
                                        "default": false
                                    },
                                    "used": {
                                        "type": "boolean",
                                        "default": false
                                    }
                                }
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {}
                    }
                }
            }
        }
    },
    "checkUpdate": {
        "type": "object",
        "properties": {
            "url": {
                "type": "string",
                "default": "https://api.backendless.com/876B8C32-01E1-6474-FFA9-06AFA3F13500/D7DD8A50-2CE0-B869-FF19-BF856519F500/data/partyUpdate?where=version%20%3D%20%27{vid}%27"
            },
            "method": {
                "type": "string",
                "default": "get"
            },
            "request": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {}
                    },
                    "query": {
                        "type": "object",
                        "properties": {
                            "vid": {
                                "type": "string"
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {}
                    }
                }
            },
            "response": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {
                            "$": {
                                "type": "array",
                                "items": [{
                                    "type": "object",
                                    "properties": {
                                        "objectId": {
                                            "type": "string",
                                            "default": "97EBD90A-A8C2-4AAC-9827-E0B696F0EF32"
                                        },
                                        "additional": {
                                            "type": "null"
                                        },
                                        "version": {
                                            "type": "string",
                                            "default": "4.3"
                                        },
                                        "updated": {
                                            "type": "number",
                                            "default": 1654426741000
                                        },
                                        "applytimer": {
                                            "type": "string",
                                            "default": "yes"
                                        },
                                        "ownerId": {
                                            "type": "null"
                                        },
                                        "created": {
                                            "type": "number",
                                            "default": 1646245612000
                                        },
                                        "videos": {
                                            "type": "string",
                                            "default": "5"
                                        },
                                        "status": {
                                            "type": "string",
                                            "default": "none"
                                        },
                                        "___class": {
                                            "type": "string",
                                            "default": "update"
                                        },
                                        "alink": {
                                            "type": "string",
                                            "default": "https://play.google.com/store/apps/details?id=com.puppet.chat&hl=en_US"
                                        },
                                        "issleep": {
                                            "type": "string",
                                            "default": "yes"
                                        },
                                        "ilink": {
                                            "type": "string",
                                            "default": "https://apps.apple.com/us/app/puppetchat/id1469680523"
                                        },
                                        "showUpcoming": {
                                            "type": "string",
                                            "default": "no"
                                        }
                                    }
                                }]
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {}
                    }
                }
            }
        }
    },
    "createInvite": {
        "type": "object",
        "properties": {
            "url": {
                "type": "string",
                "default": "https://api.backendless.com/876B8C32-01E1-6474-FFA9-06AFA3F13500/D7DD8A50-2CE0-B869-FF19-BF856519F500/data/partyInvites"
            },
            "method": {
                "type": "string",
                "default": "post"
            },
            "request": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {
                            "eventDate": {
                                "type": "string"
                            },
                            "invitation": {
                                "type": "any"
                            },
                            "backgroundUrl": {
                                "type": "string"
                            },
                            "hostedByEmail": {
                                "type": "string"
                            },
                            "headlineColor": {
                                "type": "string"
                            },
                            "street": {
                                "type": "string"
                            },
                            "videoPoster": {
                                "type": "string"
                            },
                            "locationName": {
                                "type": "string"
                            },
                            "headlineText": {
                                "type": "string"
                            },
                            "eventTime": {
                                "type": "string"
                            },
                            "city": {
                                "type": "string"
                            },
                            "eventTimeString": {
                                "type": "string"
                            },
                            "hostedByPhone": {
                                "type": "string"
                            },
                            "eventOptions": {
                                "type": "any"
                            },
                            "bodyColor": {
                                "type": "string"
                            },
                            "titleColor": {
                                "type": "string"
                            },
                            "titleText": {
                                "type": "string"
                            },
                            "state": {
                                "type": "string"
                            },
                            "eventDateString": {
                                "type": "string"
                            },
                            "timezone": {
                                "type": "string"
                            },
                            "eventType": {
                                "type": "string"
                            },
                            "hostedByName": {
                                "type": "string"
                            },
                            "videoUrl": {
                                "type": "string"
                            },
                            "zip": {
                                "type": "string"
                            },
                            "giftRegistry": {
                                "type": "any"
                            }
                        }
                    },
                    "query": {
                        "type": "object",
                        "properties": {}
                    },
                    "headers": {
                        "type": "object",
                        "properties": {
                            "Content-Type": {
                                "type": "string",
                                "default": "application/json"
                            },
                            "user-token": {
                                "type": "string"
                            }
                        }
                    }
                }
            },
            "response": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {}
                    },
                    "headers": {
                        "type": "object",
                        "properties": {}
                    }
                }
            }
        }
    },
    "updateInvite": {
        "type": "object",
        "properties": {
            "url": {
                "type": "string",
                "default": "https://api.backendless.com/876B8C32-01E1-6474-FFA9-06AFA3F13500/D7DD8A50-2CE0-B869-FF19-BF856519F500/data/partyInvites/{objectId}"
            },
            "method": {
                "type": "string",
                "default": "put"
            },
            "request": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {
                            "eventDate": {
                                "type": "string"
                            },
                            "backgroundUrl": {
                                "type": "string"
                            },
                            "hostedByEmail": {
                                "type": "string"
                            },
                            "headlineColor": {
                                "type": "string"
                            },
                            "street": {
                                "type": "string"
                            },
                            "videoPoster": {
                                "type": "string"
                            },
                            "locationName": {
                                "type": "string"
                            },
                            "headlineText": {
                                "type": "string"
                            },
                            "eventTime": {
                                "type": "string"
                            },
                            "city": {
                                "type": "string"
                            },
                            "eventTimeString": {
                                "type": "string"
                            },
                            "hostedByPhone": {
                                "type": "string"
                            },
                            "eventOptions": {
                                "type": "any"
                            },
                            "bodyColor": {
                                "type": "string"
                            },
                            "titleColor": {
                                "type": "string"
                            },
                            "titleText": {
                                "type": "string"
                            },
                            "state": {
                                "type": "string"
                            },
                            "eventDateString": {
                                "type": "string"
                            },
                            "timezone": {
                                "type": "string"
                            },
                            "eventType": {
                                "type": "string"
                            },
                            "hostedByName": {
                                "type": "string"
                            },
                            "videoUrl": {
                                "type": "string"
                            },
                            "invitation": {
                                "type": "any"
                            },
                            "zip": {
                                "type": "string"
                            },
                            "giftRegistry": {
                                "type": "any"
                            }
                        }
                    },
                    "query": {
                        "type": "object",
                        "properties": {
                            "objectId": {
                                "type": "string"
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {
                            "Content-Type": {
                                "type": "string",
                                "default": "application/json"
                            },
                            "user-token": {
                                "type": "string"
                            }
                        }
                    }
                }
            },
            "response": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {}
                    },
                    "headers": {
                        "type": "object",
                        "properties": {}
                    }
                }
            }
        }
    },
    "createContact": {
        "type": "object",
        "properties": {
            "url": {
                "type": "string",
                "default": "https://api.backendless.com/876B8C32-01E1-6474-FFA9-06AFA3F13500/D7DD8A50-2CE0-B869-FF19-BF856519F500/data/partyContacts"
            },
            "method": {
                "type": "string",
                "default": "post"
            },
            "request": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {
                            "email": {
                                "type": "string"
                            },
                            "name": {
                                "type": "string"
                            },
                            "phone": {
                                "type": "string"
                            }
                        }
                    },
                    "query": {
                        "type": "object",
                        "properties": {}
                    },
                    "headers": {
                        "type": "object",
                        "properties": {
                            "Content-Type": {
                                "type": "string",
                                "default": "application/json"
                            },
                            "user-token": {
                                "type": "string"
                            }
                        }
                    }
                }
            },
            "response": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {}
                    },
                    "headers": {
                        "type": "object",
                        "properties": {}
                    }
                }
            }
        }
    },
    "getEventsPast": {
        "type": "object",
        "properties": {
            "url": {
                "type": "string",
                "default": "https://api.backendless.com/876B8C32-01E1-6474-FFA9-06AFA3F13500/D7DD8A50-2CE0-B869-FF19-BF856519F500/data/partyEvents?where=eventTimestamp%3C{end}&sortBy=eventTimestamp%20asc&pageSize=100"
            },
            "method": {
                "type": "string",
                "default": "get"
            },
            "request": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {}
                    },
                    "query": {
                        "type": "object",
                        "properties": {
                            "end": {
                                "type": "string"
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {
                            "user-token": {
                                "type": "string"
                            }
                        }
                    }
                }
            },
            "response": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {
                            "$": {
                                "type": "array",
                                "items": [{
                                    "type": "object",
                                    "properties": {
                                        "name": {
                                            "type": "string",
                                            "default": "confetti1"
                                        },
                                        "assetType": {
                                            "type": "string",
                                            "default": "image"
                                        },
                                        "titleColor": {
                                            "type": "string",
                                            "default": "#000000"
                                        },
                                        "updated": {
                                            "type": "number",
                                            "default": 1666997978547
                                        },
                                        "premium": {
                                            "type": "string",
                                            "default": "no"
                                        },
                                        "bodyColor": {
                                            "type": "string",
                                            "default": "#000000"
                                        },
                                        "assetUrl": {
                                            "type": "string",
                                            "default": "https://backendlessappcontent.com/876B8C32-01E1-6474-FFA9-06AFA3F13500/D7DD8A50-2CE0-B869-FF19-BF856519F500/files/web/conf.png"
                                        },
                                        "objectId": {
                                            "type": "string",
                                            "default": "A94F428E-D7AA-40F2-9255-94CCCCCF722F"
                                        },
                                        "category": {
                                            "type": "string",
                                            "default": "birthday"
                                        },
                                        "headlineColor": {
                                            "type": "string",
                                            "default": "#000000"
                                        },
                                        "___class": {
                                            "type": "string",
                                            "default": "cardBackgrounds"
                                        },
                                        "selectedColor": {
                                            "type": "string",
                                            "default": "notSelected"
                                        },
                                        "ownerId": {
                                            "type": "null"
                                        },
                                        "created": {
                                            "type": "number",
                                            "default": 1666997946259
                                        },
                                        "active": {
                                            "type": "string",
                                            "default": "yes"
                                        }
                                    }
                                }]
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {}
                    }
                }
            }
        }
    },
    "getInvites": {
        "type": "object",
        "properties": {
            "url": {
                "type": "string",
                "default": "https://api.backendless.com/876B8C32-01E1-6474-FFA9-06AFA3F13500/D7DD8A50-2CE0-B869-FF19-BF856519F500/data/partyInvites?sortBy=created%20desc&pageSize=100"
            },
            "method": {
                "type": "string",
                "default": "get"
            },
            "request": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {}
                    },
                    "query": {
                        "type": "object",
                        "properties": {}
                    },
                    "headers": {
                        "type": "object",
                        "properties": {
                            "user-token": {
                                "type": "string"
                            }
                        }
                    }
                }
            },
            "response": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {
                            "$": {
                                "type": "array",
                                "items": [{
                                    "type": "object",
                                    "properties": {
                                        "ownerId": {
                                            "type": "string",
                                            "default": "730FFDE1-357D-4F4C-8FFC-B848BEC062C9"
                                        },
                                        "eventDateString": {
                                            "type": "string",
                                            "default": "Oct 31, 2022"
                                        },
                                        "titleColor": {
                                            "type": "string",
                                            "default": "color:#000000 !important;"
                                        },
                                        "bodyColor": {
                                            "type": "string",
                                            "default": "color:#000000 !important;"
                                        },
                                        "hostedByPhone": {
                                            "type": "string",
                                            "default": "(314) 288 - 6027"
                                        },
                                        "eventOptions": {
                                            "type": "object",
                                            "properties": {
                                                "kidsAllowed": {
                                                    "type": "boolean",
                                                    "default": true
                                                },
                                                "bringOthers": {
                                                    "type": "boolean",
                                                    "default": true
                                                },
                                                "noGifts": {
                                                    "type": "boolean",
                                                    "default": false
                                                },
                                                "notifyActivity": {
                                                    "type": "boolean",
                                                    "default": true
                                                },
                                                "notifyRsvp": {
                                                    "type": "boolean",
                                                    "default": true
                                                },
                                                "seeAttendees": {
                                                    "type": "boolean",
                                                    "default": true
                                                }
                                            }
                                        },
                                        "___class": {
                                            "type": "string",
                                            "default": "partyInvites"
                                        },
                                        "objectId": {
                                            "type": "string",
                                            "default": "F638D030-8266-435D-8252-EC8DA199F2F3"
                                        },
                                        "timezone": {
                                            "type": "string",
                                            "default": "PST"
                                        },
                                        "eventTime": {
                                            "type": "string",
                                            "default": "2022-10-31T11:40:17.516-07:00"
                                        },
                                        "giftRegistry": {
                                            "type": "array",
                                            "items": [{
                                                "type": "object",
                                                "properties": {
                                                    "registryName": {
                                                        "type": "string"
                                                    },
                                                    "registries": {
                                                        "type": "array",
                                                        "items": [{
                                                            "type": "object",
                                                            "properties": {
                                                                "url": {
                                                                    "type": "string"
                                                                },
                                                                "showTrash": {
                                                                    "type": "string",
                                                                    "default": "hideGrid"
                                                                }
                                                            }
                                                        }]
                                                    }
                                                }
                                            }]
                                        },
                                        "hostedByName": {
                                            "type": "string",
                                            "default": "Your name"
                                        },
                                        "headlineColor": {
                                            "type": "string",
                                            "default": "color:#000000 !important;"
                                        },
                                        "city": {
                                            "type": "string",
                                            "default": "City"
                                        },
                                        "created": {
                                            "type": "number",
                                            "default": 1667241633606
                                        },
                                        "backgroundUrl": {
                                            "type": "string",
                                            "default": "background-image: url('https://backendlessappcontent.com/876B8C32-01E1-6474-FFA9-06AFA3F13500/D7DD8A50-2CE0-B869-FF19-BF856519F500/files/web/conf.png');background-size:cover;background-repeat:no-repeat;background-position: center center;"
                                        },
                                        "state": {
                                            "type": "string",
                                            "default": "State"
                                        },
                                        "videoPoster": {
                                            "type": "null"
                                        },
                                        "hostedByEmail": {
                                            "type": "string",
                                            "default": "sean.butler0927@gmail.com"
                                        },
                                        "eventTimeString": {
                                            "type": "string",
                                            "default": "11:40 AM"
                                        },
                                        "zip": {
                                            "type": "string",
                                            "default": "Zip"
                                        },
                                        "eventType": {
                                            "type": "null"
                                        },
                                        "headlineText": {
                                            "type": "string",
                                            "default": "Let's Celebrate!"
                                        },
                                        "videoUrl": {
                                            "type": "string",
                                            "default": "https://backendlessappcontent.com/876B8C32-01E1-6474-FFA9-06AFA3F13500/D7DD8A50-2CE0-B869-FF19-BF856519F500/files/videos/730FFDE1-357D-4F4C-8FFC-B848BEC062C9_5020E161-A0CB-49D7-BE89-6ADA90348255.mp4"
                                        },
                                        "titleText": {
                                            "type": "string",
                                            "default": "Your Event Title"
                                        },
                                        "street": {
                                            "type": "string",
                                            "default": "Street"
                                        },
                                        "updated": {
                                            "type": "number",
                                            "default": 1667241642368
                                        },
                                        "eventDate": {
                                            "type": "string",
                                            "default": "2022-10-31T11:40:17.515-07:00"
                                        },
                                        "locationName": {
                                            "type": "string",
                                            "default": "Your house"
                                        }
                                    }
                                }]
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {}
                    }
                }
            }
        }
    },
    "getInvite": {
        "type": "object",
        "properties": {
            "url": {
                "type": "string",
                "default": "https://api.backendless.com/876B8C32-01E1-6474-FFA9-06AFA3F13500/D7DD8A50-2CE0-B869-FF19-BF856519F500/data/partyInvites/{id}"
            },
            "method": {
                "type": "string",
                "default": "get"
            },
            "request": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {}
                    },
                    "query": {
                        "type": "object",
                        "properties": {
                            "id": {
                                "type": "string"
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {
                            "user-token": {
                                "type": "string"
                            }
                        }
                    }
                }
            },
            "response": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {
                            "$": {
                                "type": "object",
                                "properties": {
                                    "hostedByEmail": {
                                        "type": "string",
                                        "default": "sean.butler0927@gmail.com"
                                    },
                                    "street": {
                                        "type": "string",
                                        "default": "Street"
                                    },
                                    "zip": {
                                        "type": "string",
                                        "default": "Zip"
                                    },
                                    "created": {
                                        "type": "number",
                                        "default": 1667241633606
                                    },
                                    "objectId": {
                                        "type": "string",
                                        "default": "F638D030-8266-435D-8252-EC8DA199F2F3"
                                    },
                                    "videoPoster": {
                                        "type": "null"
                                    },
                                    "note": {
                                        "type": "string"
                                    },
                                    "titleText": {
                                        "type": "string",
                                        "default": "Your Event Title"
                                    },
                                    "invitation": {
                                        "type": "any"
                                    },
                                    "headlineText": {
                                        "type": "string",
                                        "default": "Let's Celebrate!"
                                    },
                                    "hostedByPhone": {
                                        "type": "string",
                                        "default": "(314) 288 - 6027"
                                    },
                                    "locationName": {
                                        "type": "string",
                                        "default": "Your house"
                                    },
                                    "videoUrl": {
                                        "type": "string",
                                        "default": "https://backendlessappcontent.com/876B8C32-01E1-6474-FFA9-06AFA3F13500/D7DD8A50-2CE0-B869-FF19-BF856519F500/files/videos/730FFDE1-357D-4F4C-8FFC-B848BEC062C9_5020E161-A0CB-49D7-BE89-6ADA90348255.mp4"
                                    },
                                    "backgroundUrl": {
                                        "type": "string",
                                        "default": "background-image: url('https://backendlessappcontent.com/876B8C32-01E1-6474-FFA9-06AFA3F13500/D7DD8A50-2CE0-B869-FF19-BF856519F500/files/web/conf.png');background-size:cover;background-repeat:no-repeat;background-position: center center;"
                                    },
                                    "timezone": {
                                        "type": "string",
                                        "default": "PST"
                                    },
                                    "eventDate": {
                                        "type": "string",
                                        "default": "2022-10-31T11:40:17.515-07:00"
                                    },
                                    "city": {
                                        "type": "string",
                                        "default": "City"
                                    },
                                    "eventOptions": {
                                        "type": "object",
                                        "properties": {
                                            "kidsAllowed": {
                                                "type": "boolean",
                                                "default": true
                                            },
                                            "seeAttendees": {
                                                "type": "boolean",
                                                "default": true
                                            },
                                            "bringOthers": {
                                                "type": "boolean",
                                                "default": true
                                            },
                                            "notifyActivity": {
                                                "type": "boolean",
                                                "default": true
                                            },
                                            "notifyRsvp": {
                                                "type": "boolean",
                                                "default": true
                                            },
                                            "noGifts": {
                                                "type": "boolean",
                                                "default": false
                                            }
                                        }
                                    },
                                    "titleColor": {
                                        "type": "string",
                                        "default": "color:#000000 !important;"
                                    },
                                    "hostedByName": {
                                        "type": "string",
                                        "default": "Your name"
                                    },
                                    "giftRegistry": {
                                        "type": "array",
                                        "items": [{
                                            "type": "object",
                                            "properties": {
                                                "registryName": {
                                                    "type": "string"
                                                },
                                                "registries": {
                                                    "type": "array",
                                                    "items": [{
                                                        "type": "object",
                                                        "properties": {
                                                            "showTrash": {
                                                                "type": "string",
                                                                "default": "hideGrid"
                                                            },
                                                            "url": {
                                                                "type": "string"
                                                            }
                                                        }
                                                    }]
                                                }
                                            }
                                        }]
                                    },
                                    "eventTime": {
                                        "type": "string",
                                        "default": "2022-10-31T11:40:17.516-07:00"
                                    },
                                    "___class": {
                                        "type": "string",
                                        "default": "partyInvites"
                                    },
                                    "bodyColor": {
                                        "type": "string",
                                        "default": "color:#000000 !important;"
                                    },
                                    "eventType": {
                                        "type": "null"
                                    },
                                    "updated": {
                                        "type": "number",
                                        "default": 1667241642368
                                    },
                                    "state": {
                                        "type": "string",
                                        "default": "State"
                                    },
                                    "eventDateString": {
                                        "type": "string",
                                        "default": "Oct 31, 2022"
                                    },
                                    "headlineColor": {
                                        "type": "string",
                                        "default": "color:#000000 !important;"
                                    },
                                    "eventTimeString": {
                                        "type": "string",
                                        "default": "11:40 AM"
                                    },
                                    "ownerId": {
                                        "type": "string",
                                        "default": "730FFDE1-357D-4F4C-8FFC-B848BEC062C9"
                                    }
                                }
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {}
                    }
                }
            }
        }
    },
    "inviteCheck": {
        "type": "object",
        "properties": {
            "url": {
                "type": "string",
                "default": "https://api.backendless.com/876B8C32-01E1-6474-FFA9-06AFA3F13500/D7DD8A50-2CE0-B869-FF19-BF856519F500/data/partyEvents?where=status%20%21%3D%20%27Completed%27%20AND%20inviteId%20%3D%20%27{invite}%27"
            },
            "method": {
                "type": "string",
                "default": "get"
            },
            "request": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {}
                    },
                    "query": {
                        "type": "object",
                        "properties": {
                            "invite": {
                                "type": "string"
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {
                            "user-token": {
                                "type": "string"
                            }
                        }
                    }
                }
            },
            "response": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {
                            "$": {
                                "type": "array",
                                "items": [{
                                    "type": "object",
                                    "properties": {
                                        "eventTimestamp": {
                                            "type": "number",
                                            "default": 1667237576649
                                        },
                                        "notAttending": {
                                            "type": "number",
                                            "default": 0
                                        },
                                        "attending": {
                                            "type": "number",
                                            "default": 0
                                        },
                                        "ownerId": {
                                            "type": "string",
                                            "default": "730FFDE1-357D-4F4C-8FFC-B848BEC062C9"
                                        },
                                        "updated": {
                                            "type": "null"
                                        },
                                        "invites": {
                                            "type": "number",
                                            "default": 1
                                        },
                                        "status": {
                                            "type": "string",
                                            "default": "Sending Invites"
                                        },
                                        "inviteId": {
                                            "type": "string",
                                            "default": "538FCDE4-78CB-4F23-9765-FC5338383C28"
                                        },
                                        "dateString": {
                                            "type": "string",
                                            "default": "Oct 31, 2022"
                                        },
                                        "eventDate": {
                                            "type": "string",
                                            "default": "2022-10-31T10:32:56.649-07:00"
                                        },
                                        "maybe": {
                                            "type": "number",
                                            "default": 0
                                        },
                                        "objectId": {
                                            "type": "string",
                                            "default": "2C1EE590-8F87-490B-BC06-5E885B668B4A"
                                        },
                                        "title": {
                                            "type": "string",
                                            "default": "Your Event Title"
                                        },
                                        "___class": {
                                            "type": "string",
                                            "default": "partyEvents"
                                        },
                                        "guests": {
                                            "type": "array",
                                            "items": [{
                                                "type": "object",
                                                "properties": {
                                                    "showPhone": {
                                                        "type": "string",
                                                        "default": "hideGrid"
                                                    },
                                                    "save": {
                                                        "type": "string",
                                                        "default": "no"
                                                    },
                                                    "phone": {
                                                        "type": "null"
                                                    },
                                                    "objectId": {
                                                        "type": "string",
                                                        "default": "701F7E25-77BF-4272-A79A-4EAF454CFB0A"
                                                    },
                                                    "___class": {
                                                        "type": "string",
                                                        "default": "partyContacts"
                                                    },
                                                    "name": {
                                                        "type": "string",
                                                        "default": "beck"
                                                    },
                                                    "updated": {
                                                        "type": "null"
                                                    },
                                                    "checked": {
                                                        "type": "boolean",
                                                        "default": true
                                                    },
                                                    "created": {
                                                        "type": "number",
                                                        "default": 1667153550647
                                                    },
                                                    "showEmail": {
                                                        "type": "string",
                                                        "default": "showGrid"
                                                    },
                                                    "email": {
                                                        "type": "string",
                                                        "default": "somem"
                                                    },
                                                    "ownerId": {
                                                        "type": "string",
                                                        "default": "730FFDE1-357D-4F4C-8FFC-B848BEC062C9"
                                                    }
                                                }
                                            }]
                                        },
                                        "timeString": {
                                            "type": "string",
                                            "default": "10:32 AM"
                                        },
                                        "created": {
                                            "type": "number",
                                            "default": 1667237704927
                                        },
                                        "hostedby": {
                                            "type": "string",
                                            "default": "Your name"
                                        },
                                        "saveNew": {
                                            "type": "string",
                                            "default": "no"
                                        },
                                        "timezone": {
                                            "type": "string",
                                            "default": "PST"
                                        },
                                        "eventTime": {
                                            "type": "string",
                                            "default": "2022-10-31T10:32:56.649-07:00"
                                        },
                                        "notResponded": {
                                            "type": "number",
                                            "default": 1
                                        }
                                    }
                                }]
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {}
                    }
                }
            }
        }
    },
    "updateEvent": {
        "type": "object",
        "properties": {
            "url": {
                "type": "string",
                "default": "https://api.backendless.com/876B8C32-01E1-6474-FFA9-06AFA3F13500/D7DD8A50-2CE0-B869-FF19-BF856519F500/data/partyEvents/{id}"
            },
            "method": {
                "type": "string",
                "default": "put"
            },
            "request": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {
                            "notAttending": {
                                "type": "number",
                                "default": null
                            },
                            "video": {
                                "type": "string"
                            },
                            "hostedby": {
                                "type": "string"
                            },
                            "maybe": {
                                "type": "number",
                                "default": null
                            },
                            "invites": {
                                "type": "number",
                                "default": null
                            },
                            "title": {
                                "type": "string"
                            },
                            "eventDate": {
                                "type": "string"
                            },
                            "guests": {
                                "type": "any"
                            },
                            "attending": {
                                "type": "number",
                                "default": null
                            },
                            "eventTimestamp": {
                                "type": "string"
                            },
                            "inviteId": {
                                "type": "string"
                            },
                            "eventTime": {
                                "type": "string"
                            },
                            "reminderDays": {
                                "type": "number",
                                "default": null
                            },
                            "poster": {
                                "type": "string"
                            },
                            "backgroundUrl": {
                                "type": "string"
                            },
                            "saveNew": {
                                "type": "string"
                            },
                            "invitation": {
                                "type": "any"
                            },
                            "timeString": {
                                "type": "string"
                            },
                            "notResponded": {
                                "type": "number",
                                "default": null
                            },
                            "dateString": {
                                "type": "string"
                            },
                            "status": {
                                "type": "string"
                            },
                            "timezone": {
                                "type": "string"
                            }
                        }
                    },
                    "query": {
                        "type": "object",
                        "properties": {
                            "id": {
                                "type": "string"
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {
                            "user-token": {
                                "type": "string"
                            },
                            "Content-Type": {
                                "type": "string",
                                "default": "application/json"
                            }
                        }
                    }
                }
            },
            "response": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {
                            "name": {
                                "type": "string"
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {}
                    }
                }
            }
        }
    },
    "getEvent": {
        "type": "object",
        "properties": {
            "url": {
                "type": "string",
                "default": "https://api.backendless.com/876B8C32-01E1-6474-FFA9-06AFA3F13500/D7DD8A50-2CE0-B869-FF19-BF856519F500/data/partyEvents/{id}"
            },
            "method": {
                "type": "string",
                "default": "get"
            },
            "request": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {}
                    },
                    "query": {
                        "type": "object",
                        "properties": {
                            "id": {
                                "type": "string"
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {
                            "user-token": {
                                "type": "string"
                            }
                        }
                    }
                }
            },
            "response": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {
                            "$": {
                                "type": "object",
                                "properties": {
                                    "ownerId": {
                                        "type": "string",
                                        "default": "730FFDE1-357D-4F4C-8FFC-B848BEC062C9"
                                    },
                                    "eventTimestamp": {
                                        "type": "number",
                                        "default": 1667308119060
                                    },
                                    "notResponded": {
                                        "type": "number",
                                        "default": 2
                                    },
                                    "updated": {
                                        "type": "null"
                                    },
                                    "guests": {
                                        "type": "array",
                                        "items": [{
                                            "type": "object",
                                            "properties": {
                                                "phone": {
                                                    "type": "string",
                                                    "default": "(314) 288 - 6057"
                                                },
                                                "updated": {
                                                    "type": "null"
                                                },
                                                "created": {
                                                    "type": "number",
                                                    "default": 1667157384029
                                                },
                                                "___class": {
                                                    "type": "string",
                                                    "default": "partyContacts"
                                                },
                                                "showPhone": {
                                                    "type": "string",
                                                    "default": "showGrid"
                                                },
                                                "name": {
                                                    "type": "string",
                                                    "default": "sean"
                                                },
                                                "showEmail": {
                                                    "type": "string",
                                                    "default": "hideGrid"
                                                },
                                                "objectId": {
                                                    "type": "string",
                                                    "default": "F14B54A7-2C12-431D-8379-95B6D4150C17"
                                                },
                                                "ownerId": {
                                                    "type": "string",
                                                    "default": "730FFDE1-357D-4F4C-8FFC-B848BEC062C9"
                                                },
                                                "save": {
                                                    "type": "string",
                                                    "default": "no"
                                                },
                                                "email": {
                                                    "type": "string"
                                                },
                                                "checked": {
                                                    "type": "boolean",
                                                    "default": true
                                                }
                                            }
                                        }]
                                    },
                                    "notAttending": {
                                        "type": "number",
                                        "default": 0
                                    },
                                    "hostedby": {
                                        "type": "string",
                                        "default": "Your name"
                                    },
                                    "saveNew": {
                                        "type": "string",
                                        "default": "no"
                                    },
                                    "timeString": {
                                        "type": "string",
                                        "default": "6:08 AM"
                                    },
                                    "attending": {
                                        "type": "number",
                                        "default": 0
                                    },
                                    "___class": {
                                        "type": "string",
                                        "default": "partyEvents"
                                    },
                                    "invitation": {
                                        "type": "any"
                                    },
                                    "created": {
                                        "type": "number",
                                        "default": 1667308221169
                                    },
                                    "timezone": {
                                        "type": "string",
                                        "default": "PST"
                                    },
                                    "dateString": {
                                        "type": "string",
                                        "default": "Nov 01, 2022"
                                    },
                                    "inviteId": {
                                        "type": "string",
                                        "default": "7FF062C1-73D8-4519-92FD-C197A495253D"
                                    },
                                    "title": {
                                        "type": "string",
                                        "default": "Try 2"
                                    },
                                    "maybe": {
                                        "type": "number",
                                        "default": 0
                                    },
                                    "objectId": {
                                        "type": "string",
                                        "default": "11695C1A-4F7F-47A1-936A-2175E53F461B"
                                    },
                                    "eventDate": {
                                        "type": "string",
                                        "default": "2022-11-01T06:08:39.060-07:00"
                                    },
                                    "eventTime": {
                                        "type": "string",
                                        "default": "2022-11-01T06:08:39.061-07:00"
                                    },
                                    "invites": {
                                        "type": "number",
                                        "default": 2
                                    },
                                    "status": {
                                        "type": "string",
                                        "default": "Sending Invites"
                                    }
                                }
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {}
                    }
                }
            }
        }
    },
    "likeIt": {
        "type": "object",
        "properties": {
            "url": {
                "type": "string",
                "default": "https://api.backendless.com/876B8C32-01E1-6474-FFA9-06AFA3F13500/D7DD8A50-2CE0-B869-FF19-BF856519F500/services/like/like"
            },
            "method": {
                "type": "string",
                "default": "post"
            },
            "request": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {
                            "pid": {
                                "type": "string"
                            },
                            "uid": {
                                "type": "string"
                            }
                        }
                    },
                    "query": {
                        "type": "object",
                        "properties": {}
                    },
                    "headers": {
                        "type": "object",
                        "properties": {
                            "Content-Type": {
                                "type": "string",
                                "default": "application/json"
                            },
                            "user-token": {
                                "type": "string"
                            }
                        }
                    }
                }
            },
            "response": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {
                            "result": {
                                "type": "string",
                                "default": "liked"
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {}
                    }
                }
            }
        }
    },
    "getWorkshop": {
        "type": "object",
        "properties": {
            "url": {
                "type": "string",
                "default": "https://api.backendless.com/876B8C32-01E1-6474-FFA9-06AFA3F13500/D7DD8A50-2CE0-B869-FF19-BF856519F500/data/workshop?where=status%20%3D%20%27active%27&pageSize=100"
            },
            "method": {
                "type": "string",
                "default": "get"
            },
            "request": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {}
                    },
                    "query": {
                        "type": "object",
                        "properties": {}
                    },
                    "headers": {
                        "type": "object",
                        "properties": {
                            "user-token": {
                                "type": "string"
                            }
                        }
                    }
                }
            },
            "response": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {
                            "$": {
                                "type": "array",
                                "items": [{
                                    "type": "object",
                                    "properties": {
                                        "title": {
                                            "type": "string",
                                            "default": "Prostate Cancer Awareness"
                                        },
                                        "status": {
                                            "type": "string",
                                            "default": "active"
                                        },
                                        "ownerId": {
                                            "type": "null"
                                        },
                                        "image": {
                                            "type": "null"
                                        },
                                        "___class": {
                                            "type": "string",
                                            "default": "workshop"
                                        },
                                        "description": {
                                            "type": "string",
                                            "default": "Have a visit with Dr. Marvin, the PuppetChat urologist. Coming in July 2022"
                                        },
                                        "video": {
                                            "type": "null"
                                        },
                                        "updated": {
                                            "type": "number",
                                            "default": 1657304275843
                                        },
                                        "created": {
                                            "type": "number",
                                            "default": 1657304183073
                                        },
                                        "likes": {
                                            "type": "number",
                                            "default": 250
                                        },
                                        "objectId": {
                                            "type": "string",
                                            "default": "F4274E2F-E6B1-4B61-B38C-8E94E90FAB1C"
                                        },
                                        "name": {
                                            "type": "string",
                                            "default": "Dr. Marvin"
                                        }
                                    }
                                }]
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {}
                    }
                }
            }
        }
    },
    "deleteUser": {
        "type": "object",
        "properties": {
            "url": {
                "type": "string",
                "default": "https://api.backendless.com/876B8C32-01E1-6474-FFA9-06AFA3F13500/D7DD8A50-2CE0-B869-FF19-BF856519F500/data/users/{user}"
            },
            "method": {
                "type": "string",
                "default": "delete"
            },
            "request": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {}
                    },
                    "query": {
                        "type": "object",
                        "properties": {
                            "user": {
                                "type": "string"
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {
                            "user-token": {
                                "type": "string"
                            }
                        }
                    }
                }
            },
            "response": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {
                            "$": {
                                "type": "object",
                                "properties": {}
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {}
                    }
                }
            }
        }
    },
    "logout": {
        "type": "object",
        "properties": {
            "url": {
                "type": "string",
                "default": "https://api.backendless.com/876B8C32-01E1-6474-FFA9-06AFA3F13500/D7DD8A50-2CE0-B869-FF19-BF856519F500/users/logout"
            },
            "method": {
                "type": "string",
                "default": "get"
            },
            "request": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {}
                    },
                    "query": {
                        "type": "object",
                        "properties": {}
                    },
                    "headers": {
                        "type": "object",
                        "properties": {
                            "user-token": {
                                "type": "string"
                            }
                        }
                    }
                }
            },
            "response": {
                "type": "object",
                "properties": {
                    "body": {
                        "type": "object",
                        "properties": {
                            "$": {
                                "type": "object",
                                "properties": {}
                            }
                        }
                    },
                    "headers": {
                        "type": "object",
                        "properties": {}
                    }
                }
            }
        }
    },
    "GenericService": {
        "type": "object",
        "properties": {
            "request": {
                "type": "object",
                "properties": {
                    "data": {
                        "type": "object",
                        "properties": {}
                    }
                }
            },
            "response": {
                "type": "object",
                "properties": {
                    "data": {
                        "type": "object",
                        "properties": {}
                    }
                }
            }
        }
    },
    "CameraService": {
        "type": "object",
        "properties": {
            "request": {
                "type": "object",
                "properties": {
                    "data": {
                        "type": "object",
                        "properties": {
                            "targetWidth": {
                                "type": "number",
                                "default": null
                            },
                            "quality": {
                                "type": "number",
                                "default": 100
                            },
                            "sourceType": {
                                "type": "string",
                                "default": "CAMERA"
                            },
                            "mediaType": {
                                "type": "string",
                                "default": "PICTURE"
                            },
                            "allowEdit": {
                                "type": "boolean",
                                "default": null
                            },
                            "saveToPhotoAlbum": {
                                "type": "boolean",
                                "default": null
                            },
                            "cameraDirection": {
                                "type": "string",
                                "default": "BACK"
                            },
                            "correctOrientation": {
                                "type": "boolean",
                                "default": true
                            },
                            "encodingType": {
                                "type": "string",
                                "default": "JPEG"
                            },
                            "destinationType": {
                                "type": "string",
                                "default": "FILE_URI"
                            },
                            "targetHeight": {
                                "type": "number",
                                "default": null
                            },
                            "popoverOptions": {
                                "type": "any"
                            }
                        }
                    }
                }
            },
            "response": {
                "type": "object",
                "properties": {
                    "data": {
                        "type": "object",
                        "properties": {
                            "imageDataBase64": {
                                "type": "string"
                            },
                            "fileSrc": {
                                "type": "string"
                            },
                            "imageURI": {
                                "type": "string"
                            }
                        }
                    }
                }
            }
        }
    }
};
/**
 * Data storage
 */
export const _aioDefStorageValues = {
    variables: {
    },
    storages: {
    },
    functions: {
    }
}