import {
    Component
} from '@angular/core';
import {
    ChangeDetectorRef
} from '@angular/core';
import {
    ApperyioHelperService
} from '../scripts/apperyio/apperyio_helper';
import {
    ApperyioMappingHelperService
} from '../scripts/apperyio/apperyio_mapping_helper';
import {
    AlertController
} from '@ionic/angular';
import {
    NavController
} from '@ionic/angular';
import {
    InAppBrowser
} from '@ionic-native/in-app-browser/ngx';
import {
    Purchases
} from '@ionic-native/purchases/ngx';
import {
    $aio_empty_object
} from '../scripts/interfaces';
import {
    ViewChild
} from '@angular/core';
@Component({
    templateUrl: 'Login.html',
    selector: 'page-login',
    styleUrls: ['Login.css', 'Login.scss']
})
export class Login {
    public showLogin: string;
    public showRegister: string;
    public showConfirm: string;
    public footerClass: string;
    public inputActive: string;
    public email: string;
    public password: string;
    public sid: string;
    public customer: string;
    public digits: string;
    public renewal: string;
    public acctType: string;
    public userType: string;
    public marketing: string;
    public announcements: boolean;
    public token: string;
    public id: string;
    public forgotText: string;
    public showForgot: string;
    public showEmail: string;
    public showCode: string;
    public confirmCode: string;
    public showNew: string;
    public newPass: string;
    public emailConfirm: string;
    public showPending: string;
    public party: string;
    public blankV: string;
    public headertext: string;
    public showConfetti: string;
    public $a: ApperyioHelperService;
    public $v: {
        [name: string]: any
    };
    public aioChangeDetector: ChangeDetectorRef;
    public currentItem: any = null;
    @ViewChild('_aio_content') _aio_content;
    public mappingData: any = {};
    public __getMapping(_currentItem, property, defaultValue, isVariable?, isSelected?) {
        return this.$aio_mappingHelper.getMapping(this.mappingData, _currentItem, property, defaultValue, isVariable, isSelected);
    }
    public __isPropertyInMapping(_currentItem, property) {
        return this.$aio_mappingHelper.isPropertyInMapping(this.mappingData, _currentItem, property);
    }
    public __setMapping(data: any = {}, keyName: string, propName?: string): void {
        const changes = data.detail || {};
        if (propName) {
            this.mappingData = this.$aio_mappingHelper.updateData(this.mappingData, [keyName], changes[propName]);
        } else {
            this.mappingData = this.$aio_mappingHelper.updateData(this.mappingData, [keyName], changes.value);
        }
        this.$aio_changeDetector.detectChanges();
    }
    public __bindedMethods: any = {};
    hideIt(event): any {
        this.footerClass = "hideFooter";
    }
    showIt(): any {
        let TIME_IN_MS = 100;
        let hideFooterTimeout = setTimeout(() => {
            const el = document.activeElement.tagName;
            if (el === "INPUT") {
            } else {
                this.footerClass = "";
            }
        }, TIME_IN_MS);
    }
    doForgot(): any {
        this.showForgot = "showGrid";
        this.showLogin = "hideGrid";
        this.showRegister = "hideGrid";
        this.showEmail = "showGrid";
        this.showCode = "hideGrid";
        this.showPending = "hideGrid";
    }
    goLogin(): any {
        this.showForgot = "hideGrid";
        this.showLogin = "hideGrid";
        this.showRegister = "showGrid";
    }
    constructor(public Apperyio: ApperyioHelperService, private $aio_mappingHelper: ApperyioMappingHelperService, private $aio_changeDetector: ChangeDetectorRef, public iab: InAppBrowser, public alertController: AlertController, public purchases: Purchases, public navCtrl: NavController) {
        this.$a = this.Apperyio;
        this.$v = this.Apperyio.vars;
        this.aioChangeDetector = this.$aio_changeDetector;
    }
    ngOnInit() {
        this.Apperyio.setThinScrollIfNeeded();
    }
    ionViewWillEnter() {
        window.currentScreen = this;
        this.pageIonViewWillEnter__j_1132();
    }
    async pageIonViewWillEnter__j_1132(event?, currentItem?) {
        let __aio_tmp_val__: any;
        /* Run TypeScript */
        // resources in description
        this.footerClass = "";
        localStorage.setItem("auth", "no");
        this.announcements = true;
        this.headertext = "Create a free account";
        this.blankV = "partyforgot";
        this.forgotText = "We'll email you a confirmation code:";
        this.showForgot = "hideGrid";
        this.showEmail = "showGrid";
        this.showCode = "hideGrid";
        this.showPending = "hideGrid";
        this.showNew = "hideGrid";
        const mainTabs = document.querySelector(".main-tabsp");
        const mainSliderCircle = document.querySelector(".main-slider-circlep");
        const roundButtons = document.querySelectorAll(".round-buttonp");
        this.showLogin = "showGrid";
        this.showRegister = "hideGrid";
        const colors = {
            blue: {
                50: {
                    value: "#e3f2fd"
                },
                100: {
                    value: "#bbdefb"
                }
            },
            green: {
                50: {
                    value: "#e8f5e9"
                },
                100: {
                    value: "#c8e6c9"
                }
            },
            purple: {
                50: {
                    value: "#f3e5f5"
                },
                100: {
                    value: "#e1bee7"
                }
            },
            orange: {
                50: {
                    value: "#ffe0b2"
                },
                100: {
                    value: "#ffe0b2"
                }
            },
            red: {
                50: {
                    value: "#ffebee"
                },
                100: {
                    value: "#ffcdd2"
                }
            }
        };
        const getColor = (color, variant) => {
            return colors[color][variant].value;
        };
        const handleActiveTab = (tabs, event, className) => {
            tabs.forEach((tab) => {
                tab.classList.remove(className);
            });
            if (!event.target.classList.contains(className)) {
                event.target.classList.add(className);
            }
        };
        const startActiveTab = (tabs) => {
            tabs.forEach((tab) => {
                tab.classList.remove("filter-activep");
            });
            tabs[0].classList.add("filter-activep");
        };
        const filterTabs = document.querySelector(".filter-tabsp");
        const filterButtons = document.querySelectorAll(".filter-buttonp");
        filterTabs.addEventListener("click", (event) => {
            if (event !== null && event.target instanceof HTMLElement) {
                const root = document.documentElement;
                const targetTranslateValue = event.target.dataset.translateValue;
                if (targetTranslateValue === "100%") {
                    this.showLogin = "hideGrid";
                    this.showRegister = "showGrid";
                    this.showForgot = "hideGrid";
                    this.showPending = "hideGrid";
                    this.headertext = "Login";
                } else {
                    this.showLogin = "showGrid";
                    this.showRegister = "hideGrid";
                    this.showForgot = "hideGrid";
                    this.showPending = "hideGrid";
                    this.headertext = "Create a free account";
                }
                if (event.target.classList.contains("filter-buttonp")) {
                    root.style.setProperty("--translate-filters-slider", targetTranslateValue);
                    handleActiveTab(filterButtons, event, "filter-activep");
                }
            }
        });
        const rootfirst = document.documentElement;
        rootfirst.style.setProperty("--translate-filters-slider", "0");
        startActiveTab(filterButtons);
    }
    async text5Click__j_1156(event?, currentItem?) {
        let __aio_tmp_val__: any;
        /* Run TypeScript */
        this.iab.create('https://www.puppetpartyapp.com/terms-and-conditions', '_system');
    }
    async text8Click__j_1158(event?, currentItem?) {
        let __aio_tmp_val__: any;
        /* Run TypeScript */
        this.iab.create('https://www.puppetpartyapp.com/privacy-policy', '_system');
    }
    async button1Click__j_1163(event?, currentItem?) {
        let __aio_tmp_val__: any;
        /* Run TypeScript */
        var reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (this.email === "" || this.email === undefined) {
            const alert = await this.alertController.create({
                cssClass: 'my-custom-class',
                header: 'Alert',
                message: 'Please enter an email.',
                buttons: ['OK']
            });
            await alert.present();
        } else if (this.password === "" || this.password === undefined) {
            const alert = await this.alertController.create({
                cssClass: 'my-custom-class',
                header: 'Alert',
                message: 'Please enter a password.',
                buttons: ['OK']
            });
            await alert.present();
        } else if (this.password.length < 8) {
            const alert = await this.alertController.create({
                cssClass: 'my-custom-class',
                header: 'Alert',
                message: 'Please enter a password with at least 8 characters.',
                buttons: ['OK']
            });
            await alert.present();
        } else if (reg.test(this.email) === false) {
            const alert = await this.alertController.create({
                cssClass: 'my-custom-class',
                header: 'Alert',
                message: 'Please enter a valid email address.',
                buttons: ['OK']
            });
            await alert.present();
            return;
        } else {
            this.sid = "none";
            this.customer = "freenew";
            this.digits = "none";
            this.renewal = "none";
            this.acctType = "party";
            this.userType = "regular";
            if (this.announcements === true) {
                this.marketing = "yes";
            } else {
                this.marketing = "no";
            }
            this.party = "yes";
            this.Apperyio.execDataService(this, "register1"); // Change service_name to created DataSource name
        }
    }
    async button1_copyClick__j_1177(event?, currentItem?) {
        let __aio_tmp_val__: any;
        /* Run TypeScript */
        var reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (this.email === "" || this.email === undefined) {
            const alert = await this.alertController.create({
                cssClass: 'my-custom-class',
                header: 'Alert',
                message: 'Please enter an email.',
                buttons: ['OK']
            });
            await alert.present();
        } else if (this.password === "" || this.password === undefined) {
            const alert = await this.alertController.create({
                cssClass: 'my-custom-class',
                header: 'Alert',
                message: 'Please enter a password.',
                buttons: ['OK']
            });
            await alert.present();
        } else if (reg.test(this.email) === false) {
            const alert = await this.alertController.create({
                cssClass: 'my-custom-class',
                header: 'Alert',
                message: 'Please enter a valid email address.',
                buttons: ['OK']
            });
            await alert.present();
            return;
        } else {
            this.Apperyio.execDataService(this, "login2"); // Change service_name to created DataSource name
        }
    }
    async text5_copyClick__j_1178(event?, currentItem?) {
        let __aio_tmp_val__: any;
        /* Run TypeScript */
        this.doForgot();
    }
    async button1_copy2Click__j_1190(event?, currentItem?) {
        let __aio_tmp_val__: any;
        /* Run TypeScript */
        if (this.emailConfirm === "" || this.emailConfirm === undefined) {
            const alert = await this.alertController.create({
                cssClass: 'my-custom-class',
                header: 'Alert',
                message: 'Please enter a code.',
                buttons: ['OK']
            });
            await alert.present();
        } else {
            this.Apperyio.execDataService(this, "confirmActivate1"); // Change service_name to created DataSource name
        }
    }
    async text5_copy3Click__j_1191(event?, currentItem?) {
        let __aio_tmp_val__: any;
        /* Invoke data service */
        this.invokeService_sendCode1();
    }
    async text5_copy2Click__j_1202(event?, currentItem?) {
        let __aio_tmp_val__: any;
        /* Invoke data service */
        this.invokeService_sendCode1();
    }
    async button1_copy1Click__j_1210(event?, currentItem?) {
        let __aio_tmp_val__: any;
        /* Run TypeScript */
        var reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (this.showEmail === "showGrid") {
            if (this.email === "" || this.email === undefined) {
                const alert = await this.alertController.create({
                    cssClass: 'my-custom-class',
                    header: 'Alert',
                    message: 'Please enter an email.',
                    buttons: ['OK']
                });
                await alert.present();
            } else if (reg.test(this.email) === false) {
                const alert = await this.alertController.create({
                    cssClass: 'my-custom-class',
                    header: 'Alert',
                    message: 'Please enter a valid email address.',
                    buttons: ['OK']
                });
                await alert.present();
                return;
            } else {
                this.Apperyio.execDataService(this, "sendCode1"); // Change service_name to created DataSource name
            }
        } else if (this.showCode === "showGrid") {
            if (this.confirmCode === "" || this.confirmCode === undefined) {
                const alert = await this.alertController.create({
                    cssClass: 'my-custom-class',
                    header: 'Alert',
                    message: 'Please enter an email.',
                    buttons: ['OK']
                });
                await alert.present();
            } else {
                this.Apperyio.execDataService(this, "confirmCode1"); // Change service_name to created DataSource name
            }
        } else if (this.showNew === "showGrid") {
            if (this.newPass === "" || this.newPass === undefined) {
                const alert = await this.alertController.create({
                    cssClass: 'my-custom-class',
                    header: 'Alert',
                    message: 'Please enter a password.',
                    buttons: ['OK']
                });
                await alert.present();
            } else if (this.newPass.length < 8) {
                const alert = await this.alertController.create({
                    cssClass: 'my-custom-class',
                    header: 'Alert',
                    message: 'Please enter a password with at least 8 characters.',
                    buttons: ['OK']
                });
                await alert.present();
            } else {
                this.Apperyio.execDataService(this, "resetUpdate1"); // Change service_name to created DataSource name
            }
        }
    }
    async text5_copy1Click__j_1211(event?, currentItem?) {
        let __aio_tmp_val__: any;
        /* Run TypeScript */
        this.goLogin();
    }
    async button5Click__j_1215(event?, currentItem?) {
        let __aio_tmp_val__: any;
        /* Dismiss Modal */
        this.Apperyio.getController("ModalController").dismiss();
    }
    private $aio_dataServices = {
        "register1": "invokeService_register1",
        "login1": "invokeService_login1",
        "login2": "invokeService_login2",
        "sendCode1": "invokeService_sendCode1",
        "confirmCode1": "invokeService_confirmCode1",
        "resetUpdate1": "invokeService_resetUpdate1",
        "confirmActivate1": "invokeService_confirmActivate1"
    };
    invokeService_register1(cb?: Function) {
        this.Apperyio.getService("register").then(
            async (service) => {
                if (!service) {
                    console.log("Error. Service was not found.");
                    return;
                }
                let data = {};
                let params = {};
                let headers = {};
                let __aio_tmp_val__: any;
                this.$aio_changeDetector.detectChanges();
                /* Mapping */
                data = this.$aio_mappingHelper.updateData(data, ["email"], this.$aio_mappingHelper.getSubdata(this.email, []));
                data = this.$aio_mappingHelper.updateData(data, ["password"], this.$aio_mappingHelper.getSubdata(this.password, []));
                data = this.$aio_mappingHelper.updateData(data, ["subId"], this.$aio_mappingHelper.getSubdata(this.sid, []));
                data = this.$aio_mappingHelper.updateData(data, ["account"], this.$aio_mappingHelper.getSubdata(this.acctType, []));
                data = this.$aio_mappingHelper.updateData(data, ["cusId"], this.$aio_mappingHelper.getSubdata(this.customer, []));
                data = this.$aio_mappingHelper.updateData(data, ["digits"], this.$aio_mappingHelper.getSubdata(this.digits, []));
                data = this.$aio_mappingHelper.updateData(data, ["renew"], this.$aio_mappingHelper.getSubdata(this.renewal, []));
                data = this.$aio_mappingHelper.updateData(data, ["usertype"], this.$aio_mappingHelper.getSubdata(this.userType, []));
                data = this.$aio_mappingHelper.updateData(data, ["marketing"], this.$aio_mappingHelper.getSubdata(this.marketing, []));
                data = this.$aio_mappingHelper.updateData(data, ["partyAccount"], this.$aio_mappingHelper.getSubdata(this.party, []));
                /* Present Loading */
                await (async () => {
                    let options = {
                        'animated': true,
                        'cssClass': 'previewLoader',
                        'keyboardClose': true,
                        'message': 'Creating your account',
                        'mode': 'ios',
                        'showBackdrop': true,
                    };
                    let controller = this.Apperyio.getController('LoadingController');
                    const loading = await controller.create(options);
                    return await loading.present();
                })();
                service.execute({
                    data: data,
                    params: params,
                    headers: headers
                }).subscribe(
                    /* onsuccess */
                    async (res: any) => {
                        let mappingData: any = {};
                        /* Invoke data service */
                        this.invokeService_login1();
                        this.mappingData = { ...this.mappingData,
                            ...mappingData
                        };
                        if (cb && typeof cb === "function") cb(res);
                    },
                    /* onerror */
                    async (err: any) => {
                        /* Run TypeScript */
                        var errorstring = JSON.stringify(err);
                        if (errorstring.indexOf('User already exists') !== -1) {
                            const alert = await this.alertController.create({
                                cssClass: 'my-custom-class',
                                header: 'Alert',
                                message: 'This email is already associated with an account. Please login.',
                                buttons: ['OK']
                            });
                            await alert.present();
                        } else if (errorstring.indexOf('Cannot read property') !== -1) {
                            const alert = await this.alertController.create({
                                cssClass: 'my-custom-class',
                                header: 'Alert',
                                message: 'This email is already associated with an account. Please login.',
                                buttons: ['OK']
                            });
                            await alert.present();
                        } else {
                            const alert = await this.alertController.create({
                                cssClass: 'my-custom-class',
                                header: 'Alert',
                                message: 'Please check your network connection or try again later.',
                                buttons: ['OK']
                            });
                            await alert.present();
                        }
                        /* Dismiss loading */
                        await this.Apperyio.getController("LoadingController").dismiss();
                    }
                )
            }
        );
    }
    invokeService_login1(cb?: Function) {
        this.Apperyio.getService("login").then(
            async (service) => {
                if (!service) {
                    console.log("Error. Service was not found.");
                    return;
                }
                let data = {};
                let params = {};
                let headers = {};
                let __aio_tmp_val__: any;
                this.$aio_changeDetector.detectChanges();
                /* Mapping */
                data = this.$aio_mappingHelper.updateData(data, ["login"], this.$aio_mappingHelper.getSubdata(this.email, []));
                data = this.$aio_mappingHelper.updateData(data, ["password"], this.$aio_mappingHelper.getSubdata(this.password, []));
                service.execute({
                    data: data,
                    params: params,
                    headers: headers
                }).subscribe(
                    /* onsuccess */
                    async (res: any) => {
                        let mappingData: any = {};
                        /* Mapping */
                        this.token = this.$aio_mappingHelper.updateData(this.token, [], this.$aio_mappingHelper.getSubdata(res, ["user-token"]));
                        this.id = this.$aio_mappingHelper.updateData(this.id, [], this.$aio_mappingHelper.getSubdata(res, ["objectId"]));
                        /* Dismiss loading */
                        await this.Apperyio.getController("LoadingController").dismiss();
                        /* Run TypeScript */
                        localStorage.setItem("acctype", this.acctType);
                        localStorage.setItem("token", this.token);
                        localStorage.setItem("uid", this.id);
                        localStorage.setItem("semail", this.email);
                        localStorage.setItem("auth", "granted");
                        //this.purchases.setDebugLogsEnabled(true); // Enable to get debug logs
                        //this.purchases.setup("goog_eyyCTNJrQdazvrXbxXAHnHeUXRY", this.id);
                        //this.Apperyio.navigateTo("Tabs"/*, optional, params, here */);
                        this.token = localStorage.getItem("token");
                        localStorage.setItem("inviteId", "new");
                        var latest = localStorage.getItem("latest");
                        if (latest === "account") {
                            //this.navCtrl.navigateRoot('/tabs/Account');
                        } else if (latest === "events") {
                            //  this.navCtrl.navigateRoot('/tabs/Info');
                        } else if (latest === "canvas") {
                            this.navCtrl.navigateRoot('/Canvas');
                            //this.Apperyio.navigation.forward("Canvas"/*,[route, params, here], {NavigationExtras and AnimationOptions} */);
                        } else {
                            this.navCtrl.navigateRoot('/Canvas');
                        }
                        this.Apperyio.getController("ModalController").dismiss(
                            /* data */
                        );
                        this.mappingData = { ...this.mappingData,
                            ...mappingData
                        };
                        if (cb && typeof cb === "function") cb(res);
                    },
                    /* onerror */
                    async (err: any) => {
                        /* Dismiss loading */
                        await this.Apperyio.getController("LoadingController").dismiss();
                        /* Run TypeScript */
                        var errorstring = JSON.stringify(err);
                        if (errorstring.indexOf('Guests cannot be logged in.') !== -1) {
                            this.showLogin = "hideGrid";
                            this.showRegister = "hideGrid";
                            this.showForgot = "hideGrid";
                            this.showPending = "showGrid";
                        } else if (errorstring.indexOf('confirmed first') !== -1) {
                            this.showLogin = "hideGrid";
                            this.showRegister = "hideGrid";
                            this.showForgot = "hideGrid";
                            this.showPending = "showGrid";
                        } else if (errorstring.indexOf('Invalid login or password') !== -1) {
                            const myalert = await this.alertController.create({
                                cssClass: 'my-custom-class',
                                header: 'Alert',
                                message: 'Please check your login credentials for accuracy.',
                                buttons: ['OK']
                            });
                            await myalert.present();
                        } else {
                            const alert = await this.alertController.create({
                                cssClass: 'my-custom-class',
                                header: 'Alert',
                                message: 'Please check your network connection or try again later.',
                                buttons: ['OK']
                            });
                            await alert.present();
                        }
                    }
                )
            }
        );
    }
    invokeService_login2(cb?: Function) {
        this.Apperyio.getService("login").then(
            async (service) => {
                if (!service) {
                    console.log("Error. Service was not found.");
                    return;
                }
                let data = {};
                let params = {};
                let headers = {};
                let __aio_tmp_val__: any;
                this.$aio_changeDetector.detectChanges();
                /* Mapping */
                data = this.$aio_mappingHelper.updateData(data, ["login"], this.$aio_mappingHelper.getSubdata(this.email, []));
                data = this.$aio_mappingHelper.updateData(data, ["password"], this.$aio_mappingHelper.getSubdata(this.password, []));
                /* Present Loading */
                await (async () => {
                    let options = {
                        'animated': true,
                        'cssClass': 'previewLoader',
                        'keyboardClose': true,
                        'message': 'Verifying',
                        'mode': 'ios',
                        'showBackdrop': true,
                    };
                    let controller = this.Apperyio.getController('LoadingController');
                    const loading = await controller.create(options);
                    return await loading.present();
                })();
                service.execute({
                    data: data,
                    params: params,
                    headers: headers
                }).subscribe(
                    /* onsuccess */
                    async (res: any) => {
                        let mappingData: any = {};
                        /* Mapping */
                        this.id = this.$aio_mappingHelper.updateData(this.id, [], this.$aio_mappingHelper.getSubdata(res, ["objectId"]));
                        this.token = this.$aio_mappingHelper.updateData(this.token, [], this.$aio_mappingHelper.getSubdata(res, ["user-token"]));
                        /* Dismiss loading */
                        await this.Apperyio.getController("LoadingController").dismiss();
                        /* Run TypeScript */
                        localStorage.setItem("acctype", this.acctType);
                        localStorage.setItem("token", this.token);
                        localStorage.setItem("uid", this.id);
                        localStorage.setItem("semail", this.email);
                        localStorage.setItem("auth", "granted");
                        //this.purchases.setDebugLogsEnabled(true); // Enable to get debug logs
                        //this.purchases.setup("goog_eyyCTNJrQdazvrXbxXAHnHeUXRY", this.id);
                        //this.Apperyio.navigateTo("Tabs"/*, optional, params, here */);
                        this.token = localStorage.getItem("token");
                        localStorage.setItem("inviteId", "new");
                        var latest = localStorage.getItem("latest");
                        if (latest === "account") {
                            //this.navCtrl.navigateRoot('/tabs/Account');
                        } else if (latest === "events") {
                            //  this.navCtrl.navigateRoot('/tabs/Info');
                        } else if (latest === "canvas") {
                            this.navCtrl.navigateRoot('/Canvas');
                            //this.Apperyio.navigation.forward("Canvas"/*,[route, params, here], {NavigationExtras and AnimationOptions} */);
                        } else {
                            this.navCtrl.navigateRoot('/Canvas');
                        }
                        this.Apperyio.getController("ModalController").dismiss(
                            /* data */
                        );
                        this.mappingData = { ...this.mappingData,
                            ...mappingData
                        };
                        if (cb && typeof cb === "function") cb(res);
                    },
                    /* onerror */
                    async (err: any) => {
                        /* Dismiss loading */
                        await this.Apperyio.getController("LoadingController").dismiss();
                        /* Run TypeScript */
                        var errorstring = JSON.stringify(err);
                        if (errorstring.indexOf('Guests cannot be logged in.') !== -1) {
                            this.showLogin = "hideGrid";
                            this.showRegister = "hideGrid";
                            this.showForgot = "hideGrid";
                            this.showPending = "showGrid";
                        } else if (errorstring.indexOf('confirmed first') !== -1) {
                            this.showLogin = "hideGrid";
                            this.showRegister = "hideGrid";
                            this.showForgot = "hideGrid";
                            this.showPending = "showGrid";
                        } else if (errorstring.indexOf('Invalid login or password') !== -1) {
                            const myalert = await this.alertController.create({
                                cssClass: 'my-custom-class',
                                header: 'Alert',
                                message: 'Please check your login credentials for accuracy.',
                                buttons: ['OK']
                            });
                            await myalert.present();
                        } else {
                            const alert = await this.alertController.create({
                                cssClass: 'my-custom-class',
                                header: 'Alert',
                                message: 'Please check your network connection or try again later.',
                                buttons: ['OK']
                            });
                            await alert.present();
                        }
                    }
                )
            }
        );
    }
    invokeService_sendCode1(cb?: Function) {
        this.Apperyio.getService("sendCode").then(
            async (service) => {
                if (!service) {
                    console.log("Error. Service was not found.");
                    return;
                }
                let data = {};
                let params = {};
                let headers = {};
                let __aio_tmp_val__: any;
                this.$aio_changeDetector.detectChanges();
                /* Mapping */
                data = this.$aio_mappingHelper.updateData(data, ["email"], this.$aio_mappingHelper.getSubdata(this.email, []));
                data = this.$aio_mappingHelper.updateData(data, ["blank"], this.$aio_mappingHelper.getSubdata(this.blankV, []));
                /* Present Loading */
                await (async () => {
                    let options = {
                        'animated': true,
                        'cssClass': 'previewLoader',
                        'keyboardClose': true,
                        'message': 'loading',
                        'mode': 'ios',
                        'showBackdrop': true,
                    };
                    let controller = this.Apperyio.getController('LoadingController');
                    const loading = await controller.create(options);
                    return await loading.present();
                })();
                service.execute({
                    data: data,
                    params: params,
                    headers: headers
                }).subscribe(
                    /* onsuccess */
                    async (res: any) => {
                        let mappingData: any = {};
                        /* Run TypeScript */
                        this.forgotText = "You should receive a code via email. Be sure to check your junk email. Please enter below:";
                        this.showEmail = "hideGrid";
                        this.showCode = "showGrid";
                        this.showNew = "hideGrid";
                        const alert = await this.alertController.create({
                            cssClass: 'my-custom-class',
                            header: 'Alert',
                            message: 'A code was sent to your email.',
                            buttons: ['OK']
                        });
                        await alert.present();
                        /* Dismiss loading */
                        await this.Apperyio.getController("LoadingController").dismiss();
                        this.mappingData = { ...this.mappingData,
                            ...mappingData
                        };
                        if (cb && typeof cb === "function") cb(res);
                    },
                    /* onerror */
                    async (err: any) => {
                        /* Dismiss loading */
                        await this.Apperyio.getController("LoadingController").dismiss();
                        /* Run TypeScript */
                        var errorstring = JSON.stringify(err);
                        if (errorstring.indexOf('User already exists') !== -1) {
                            const alert = await this.alertController.create({
                                cssClass: 'my-custom-class',
                                header: 'Alert',
                                message: 'This email is already associated with an account. Please login.',
                                buttons: ['OK']
                            });
                            await alert.present();
                        } else if (errorstring.indexOf('Cannot read property') !== -1) {
                            const alert = await this.alertController.create({
                                cssClass: 'my-custom-class',
                                header: 'Alert',
                                message: 'This email is already associated with an account. Please login.',
                                buttons: ['OK']
                            });
                            await alert.present();
                        } else {
                            const alert = await this.alertController.create({
                                cssClass: 'my-custom-class',
                                header: 'Alert',
                                message: 'Please check your network connection or try again later.',
                                buttons: ['OK']
                            });
                            await alert.present();
                        }
                    }
                )
            }
        );
    }
    invokeService_confirmCode1(cb?: Function) {
        this.Apperyio.getService("confirmCode").then(
            async (service) => {
                if (!service) {
                    console.log("Error. Service was not found.");
                    return;
                }
                let data = {};
                let params = {};
                let headers = {};
                let __aio_tmp_val__: any;
                this.$aio_changeDetector.detectChanges();
                /* Mapping */
                data = this.$aio_mappingHelper.updateData(data, ["code"], this.$aio_mappingHelper.getSubdata(this.confirmCode, []));
                data = this.$aio_mappingHelper.updateData(data, ["email"], this.$aio_mappingHelper.getSubdata(this.email, []));
                /* Present Loading */
                await (async () => {
                    let options = {
                        'animated': true,
                        'cssClass': 'previewLoader',
                        'keyboardClose': true,
                        'message': 'loading',
                        'mode': 'ios',
                        'showBackdrop': true,
                    };
                    let controller = this.Apperyio.getController('LoadingController');
                    const loading = await controller.create(options);
                    return await loading.present();
                })();
                service.execute({
                    data: data,
                    params: params,
                    headers: headers
                }).subscribe(
                    /* onsuccess */
                    async (res: any) => {
                        let mappingData: any = {};
                        /* Run TypeScript */
                        this.forgotText = "Please enter a new password below:";
                        this.showEmail = "hideGrid";
                        this.showCode = "hideGrid";
                        this.showNew = "showGrid";
                        /* Dismiss loading */
                        await this.Apperyio.getController("LoadingController").dismiss();
                        this.mappingData = { ...this.mappingData,
                            ...mappingData
                        };
                        if (cb && typeof cb === "function") cb(res);
                    },
                    /* onerror */
                    async (err: any) => {
                        /* Dismiss loading */
                        await this.Apperyio.getController("LoadingController").dismiss();
                        /* Run TypeScript */
                        var errorstring = JSON.stringify(err);
                        if (errorstring.indexOf('Cannot read property') !== -1) {
                            const alert = await this.alertController.create({
                                cssClass: 'my-custom-class',
                                header: 'Alert',
                                message: 'Please check your confirmation code for accuracy.',
                                buttons: ['OK']
                            });
                            await alert.present();
                        } else {
                            const alert = await this.alertController.create({
                                cssClass: 'my-custom-class',
                                header: 'Alert',
                                message: 'Please check your network connection or try again later.',
                                buttons: ['OK']
                            });
                            await alert.present();
                        }
                    }
                )
            }
        );
    }
    invokeService_resetUpdate1(cb?: Function) {
        this.Apperyio.getService("resetUpdate").then(
            async (service) => {
                if (!service) {
                    console.log("Error. Service was not found.");
                    return;
                }
                let data = {};
                let params = {};
                let headers = {};
                let __aio_tmp_val__: any;
                this.$aio_changeDetector.detectChanges();
                /* Mapping */
                data = this.$aio_mappingHelper.updateData(data, ["email"], this.$aio_mappingHelper.getSubdata(this.email, []));
                data = this.$aio_mappingHelper.updateData(data, ["code"], this.$aio_mappingHelper.getSubdata(this.confirmCode, []));
                data = this.$aio_mappingHelper.updateData(data, ["password"], this.$aio_mappingHelper.getSubdata(this.newPass, []));
                /* Present Loading */
                await (async () => {
                    let options = {
                        'animated': true,
                        'cssClass': 'previewLoader',
                        'keyboardClose': true,
                        'message': 'loading',
                        'mode': 'ios',
                        'showBackdrop': true,
                    };
                    let controller = this.Apperyio.getController('LoadingController');
                    const loading = await controller.create(options);
                    return await loading.present();
                })();
                service.execute({
                    data: data,
                    params: params,
                    headers: headers
                }).subscribe(
                    /* onsuccess */
                    async (res: any) => {
                        let mappingData: any = {};
                        /* Run TypeScript */
                        this.password = this.newPass;
                        /* Invoke data service */
                        this.invokeService_login1();
                        this.mappingData = { ...this.mappingData,
                            ...mappingData
                        };
                        if (cb && typeof cb === "function") cb(res);
                    },
                    /* onerror */
                    async (err: any) => {
                        /* Run TypeScript */
                        const alert = await this.alertController.create({
                            cssClass: 'my-custom-class',
                            header: 'Alert',
                            message: 'Please check your network connection or try again later.',
                            buttons: ['OK']
                        });
                        await alert.present();
                        /* Dismiss loading */
                        await this.Apperyio.getController("LoadingController").dismiss();
                    }
                )
            }
        );
    }
    invokeService_confirmActivate1(cb?: Function) {
        this.Apperyio.getService("confirmActivate").then(
            async (service) => {
                if (!service) {
                    console.log("Error. Service was not found.");
                    return;
                }
                let data = {};
                let params = {};
                let headers = {};
                let __aio_tmp_val__: any;
                this.$aio_changeDetector.detectChanges();
                /* Mapping */
                data = this.$aio_mappingHelper.updateData(data, ["code"], this.$aio_mappingHelper.getSubdata(this.emailConfirm, []));
                data = this.$aio_mappingHelper.updateData(data, ["email"], this.$aio_mappingHelper.getSubdata(this.email, []));
                /* Present Loading */
                await (async () => {
                    let options = {
                        'animated': true,
                        'cssClass': 'previewLoader',
                        'keyboardClose': true,
                        'message': 'please wait',
                        'mode': 'ios',
                        'showBackdrop': true,
                    };
                    let controller = this.Apperyio.getController('LoadingController');
                    const loading = await controller.create(options);
                    return await loading.present();
                })();
                service.execute({
                    data: data,
                    params: params,
                    headers: headers
                }).subscribe(
                    /* onsuccess */
                    async (res: any) => {
                        let mappingData: any = {};
                        /* Invoke data service */
                        this.invokeService_login1();
                        this.mappingData = { ...this.mappingData,
                            ...mappingData
                        };
                        if (cb && typeof cb === "function") cb(res);
                    },
                    /* onerror */
                    async (err: any) => {
                        /* Dismiss loading */
                        await this.Apperyio.getController("LoadingController").dismiss();
                        /* Run TypeScript */
                        const alert = await this.alertController.create({
                            cssClass: 'my-custom-class',
                            header: 'Alert',
                            message: 'Please check your code and try again.',
                            buttons: ['OK']
                        });
                        await alert.present();
                    }
                )
            }
        );
    }
}