export const constants = {
    /**
     * Settings
     */
    Settings: {}
};
export const routes = {
    "Home": "home",
    "Profile": "profile",
    "Tabs": "tabs",
    "Info": "info",
    "Account": "account",
    "Screen1": "screen1",
    "Update": "update",
    "Block": "block",
    "Scenes": "scenes",
    "Audio": "audio",
    "Login": "login",
    "Upgrade": "upgrade",
    "Microphone": "microphone",
    "Forgot": "forgot",
    "Tracker": "tracker",
    "Watch": "watch",
    "Photos": "photos",
    "Pro": "pro",
    "Intro": "intro",
    "Guests": "guests",
    "Registry": "registry",
    "Invite": "invite",
    "invitedGuests": "invitedguests",
    "Welcome": "welcome",
    "Sample": "sample",
    "Search": "search",
    "Preview": "preview",
    "Canvas": "canvas",
    "Colors": "colors",
    "Camera": "camera",
    "ImageSelect": "imageselect",
    "Fonts": "fonts",
    "Crop": "crop",
    "Screen2": "screen2",
    "Test": "test",
    "AudioModal": "audiomodal",
    "guestlist": "guestlist",
    "Overview": "overview",
    "Overview_62939": "overview",
    "Pricing": "pricing",
    "Payment": "payment",
    "guestBook": "guestbook",
    "Wizard": "wizard",
    "InviteDetails": "invitedetails",
    "Slider1": "wizard",
    "Templates": "templates",
    "ShareLink": "sharelink",
    "ShareLink_80067": "sharelink",
};
export const pushSettings = {
    appID: 'e0fa908b-749e-42f4-a484-62842522949c',
    baseUrl: 'https://api.appery.io/rest/push/reg',
    baseSendUrl: 'https://api.appery.io/rest/push/msg',
    initOptions: {}
};
export const projectInfo = {
    guid: 'e0fa908b-749e-42f4-a484-62842522949c',
    name: 'pypyweb',
    description: ''
};
export const pwaInfo = {
    name: 'pypyweb',
    shortName: 'pypyweb',
    description: 'App template with Ionic 4 Tabs. ',
    icon: ''
};
export const IGNORED_VALUE = Symbol.for("AIO_REST_IGNORED_VALUE");
export const apiHost = "https://api.appery.io";
export const defaultProxy = "fb87afe1-d84a-4c68-bdac-bdec0a9da192";