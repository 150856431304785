import {
    Component
} from '@angular/core';
import {
    ChangeDetectorRef
} from '@angular/core';
import {
    ApperyioHelperService
} from '../scripts/apperyio/apperyio_helper';
import {
    ApperyioMappingHelperService
} from '../scripts/apperyio/apperyio_mapping_helper';
import {
    AlertController
} from '@ionic/angular';
import {
    InAppBrowser
} from '@ionic-native/in-app-browser/ngx';
import {
    Purchases
} from '@ionic-native/purchases/ngx';
import {
    $aio_empty_object
} from '../scripts/interfaces';
import {
    ViewChild
} from '@angular/core';
@Component({
    templateUrl: 'Payment.html',
    selector: 'page-payment',
    styleUrls: ['Payment.css', 'Payment.scss']
})
export class Payment {
    public backstyle: string;
    public costString: string;
    public package1: any;
    public package2: any;
    public storeTitle: string;
    public storeCost: string;
    public cardimage: string;
    public code: string;
    public cvv: string;
    public exp: string;
    public zip: string;
    public showCard: string;
    public card: string;
    public month: string;
    public year: string;
    public cardToken: string;
    public amount: number;
    public token: string;
    public uid: string;
    public semail: string;
    public chargeId: string;
    public $a: ApperyioHelperService;
    public $v: {
        [name: string]: any
    };
    public aioChangeDetector: ChangeDetectorRef;
    public currentItem: any = null;
    @ViewChild('_aio_content') _aio_content;
    public mappingData: any = {};
    public __getMapping(_currentItem, property, defaultValue, isVariable?, isSelected?) {
        return this.$aio_mappingHelper.getMapping(this.mappingData, _currentItem, property, defaultValue, isVariable, isSelected);
    }
    public __isPropertyInMapping(_currentItem, property) {
        return this.$aio_mappingHelper.isPropertyInMapping(this.mappingData, _currentItem, property);
    }
    public __setMapping(data: any = {}, keyName: string, propName?: string): void {
        const changes = data.detail || {};
        if (propName) {
            this.mappingData = this.$aio_mappingHelper.updateData(this.mappingData, [keyName], changes[propName]);
        } else {
            this.mappingData = this.$aio_mappingHelper.updateData(this.mappingData, [keyName], changes.value);
        }
        this.$aio_changeDetector.detectChanges();
    }
    public __bindedMethods: any = {};
    goSignup(): any {
        this.Apperyio.navigateTo("Login"
            /*, optional, params, here */
        );
    }
    async buyPremium(): Promise < any > {
        if (this.card === "" || this.card === undefined) {
            const alert = await this.alertController.create({
                cssClass: 'my-custom-class',
                header: 'Alert',
                mode: 'ios',
                message: 'Please enter a valid card number.',
                buttons: ['OK']
            });
            await alert.present();
        } else if (this.card.length < 16) {
            const alert = await this.alertController.create({
                cssClass: 'my-custom-class',
                header: 'Alert',
                mode: 'ios',
                message: 'Please enter a valid card number.',
                buttons: ['OK']
            });
            await alert.present();
        } else if (this.exp === "" || this.exp === undefined) {
            const alert = await this.alertController.create({
                cssClass: 'my-custom-class',
                header: 'Alert',
                mode: 'ios',
                message: 'Please enter a valid expiration date.',
                buttons: ['OK']
            });
            await alert.present();
        } else if (this.exp.length < 5) {
            const alert = await this.alertController.create({
                cssClass: 'my-custom-class',
                header: 'Alert',
                mode: 'ios',
                message: 'Please enter a valid expiration date.',
                buttons: ['OK']
            });
            await alert.present();
        } else if (this.cvv === "" || this.cvv === undefined) {
            const alert = await this.alertController.create({
                cssClass: 'my-custom-class',
                header: 'Alert',
                mode: 'ios',
                message: 'Please enter a valid security code.',
                buttons: ['OK']
            });
            await alert.present();
        } else if (this.cvv.length < 3) {
            const alert = await this.alertController.create({
                cssClass: 'my-custom-class',
                header: 'Alert',
                mode: 'ios',
                message: 'Please enter a valid security code.',
                buttons: ['OK']
            });
            await alert.present();
        } else if (this.zip === "" || this.zip === undefined) {
            const alert = await this.alertController.create({
                cssClass: 'my-custom-class',
                header: 'Alert',
                mode: 'ios',
                message: 'Please enter a valid zipcode.',
                buttons: ['OK']
            });
            await alert.present();
        } else if (this.zip.length < 5) {
            const alert = await this.alertController.create({
                cssClass: 'my-custom-class',
                header: 'Alert',
                mode: 'ios',
                message: 'Please enter a valid zipcode.',
                buttons: ['OK']
            });
            await alert.present();
        } else {
            var sdate = this.exp;
            var month = sdate.substr(0, sdate.indexOf('/'));
            var year = sdate.split('/').pop();
            this.month = month;
            this.year = year;
            this.Apperyio.execDataService(this, "buildToken1"); // Change service_name to created DataSource name
        }
    }
    cardChange(): any {
        var myCard = this.card;
        var first = myCard.charAt(0);
        var second = myCard.charAt(1);
        if (myCard.length > 16) {
            myCard = myCard.substr(0, 16);
            this.card = myCard;
        }
        if (first === "5") {
            this.cardimage = "https://puppet-party.b-cdn.net/web/data-7.png";
            this.showCard = "showGrid";
        } else if (first === "4") {
            this.cardimage = "https://puppet-party.b-cdn.net/web/data-4.png";
            this.showCard = "showGrid";
        } else if (first === "6") {
            this.cardimage = "https://puppet-party.b-cdn.net/web/data-8.png";
            this.showCard = "showGrid";
        } else if (first === "3") {
            if (second === "4" || second === "7") {
                this.cardimage = "https://puppet-party.b-cdn.net/web/data-6.png";
                this.showCard = "showGrid";
            } else if (second === "0" || second === "6") {
                this.cardimage = "https://puppet-party.b-cdn.net/web/data-5.png";
                this.showCard = "showGrid";
            } else if (second === "8") {
                this.cardimage = "https://puppet-party.b-cdn.net/web/data-5.png";
                this.showCard = "showGrid";
            } else {
                this.showCard = "hideGrid";
            }
        } else {
            this.showCard = "hideGrid";
        }
    }
    expChange(): any {
        var myexp = this.exp;
        if (myexp.length > 5) {
            myexp = myexp.substr(0, 5);
            this.exp = myexp;
        } else if (myexp.length == 2) {
            this.exp = this.exp + "/";
        } else if (myexp.length == 3) {
            var lastchar = myexp.slice(-1);
            if (lastchar !== "/") {
                var str = myexp.slice(0, -1);
                this.exp = str + "/";
                this.exp = this.exp + lastchar;
            }
        }
    }
    constructor(public Apperyio: ApperyioHelperService, private $aio_mappingHelper: ApperyioMappingHelperService, private $aio_changeDetector: ChangeDetectorRef, public purchases: Purchases, public alertController: AlertController, public iab: InAppBrowser) {
        this.$a = this.Apperyio;
        this.$v = this.Apperyio.vars;
        this.aioChangeDetector = this.$aio_changeDetector;
    }
    ngOnInit() {
        this.Apperyio.setThinScrollIfNeeded();
    }
    ionViewWillEnter() {
        window.currentScreen = this;
        this.pageIonViewWillEnter__j_325();
    }
    async pageIonViewWillEnter__j_325(event?, currentItem?) {
        let __aio_tmp_val__: any;
        /* Run TypeScript */
        this.costString = "$" + localStorage.getItem("templateCost");
        this.token = localStorage.getItem("token");
        this.uid = localStorage.getItem("uid");
        this.semail = localStorage.getItem("semail");
        this.amount = 2000;
        this.showCard = "hideGrid";
        this.backstyle = "background-image: url(assets/images/yellowback.png)";
        this.$a.data.setVariable("transactionStatus", "unknown");
        this.$a.data.setVariable("transactionId", "unknown");
    }
    async button5Click__j_327(event?, currentItem?) {
        let __aio_tmp_val__: any;
        /* Dismiss Modal */
        this.Apperyio.getController("ModalController").dismiss();
    }
    async titlecloudappmaker_copy6Click__j_365(event?, currentItem?) {
        let __aio_tmp_val__: any;
        /* Present Modal */
        this.Apperyio.showModal("Pricing", {
            'animated': true,
            'backdropDismiss': true,
            'keyboardClose': true,
            'showBackdrop': true,
        }).then(modal => {
            modal.present();
        });
    }
    private $aio_dataServices = {
        "buildToken1": "invokeService_buildToken1",
        "chargeToken1": "invokeService_chargeToken1"
    };
    invokeService_buildToken1(cb?: Function) {
        this.Apperyio.getService("buildToken").then(
            async (service) => {
                if (!service) {
                    console.log("Error. Service was not found.");
                    return;
                }
                let data = {};
                let params = {};
                let headers = {};
                let __aio_tmp_val__: any;
                this.$aio_changeDetector.detectChanges();
                /* Mapping */
                data = this.$aio_mappingHelper.updateData(data, ['card[cvc]'], this.$aio_mappingHelper.getSubdata(this.cvv, []));
                data = this.$aio_mappingHelper.updateData(data, ['card[exp_year]'], this.$aio_mappingHelper.getSubdata(this.year, []));
                data = this.$aio_mappingHelper.updateData(data, ['card[exp_month]'], this.$aio_mappingHelper.getSubdata(this.month, []));
                data = this.$aio_mappingHelper.updateData(data, ['card[number]'], this.$aio_mappingHelper.getSubdata(this.card, []));
                data = this.$aio_mappingHelper.updateData(data, ['card[address_zip]'], this.$aio_mappingHelper.getSubdata(this.zip, []));
                /* Present Loading */
                await (async () => {
                    let options = {
                        'animated': true,
                        'keyboardClose': true,
                        'message': 'Please wait ...',
                        'showBackdrop': true,
                        'spinner': 'crescent',
                    };
                    let controller = this.Apperyio.getController('LoadingController');
                    const loading = await controller.create(options);
                    return await loading.present();
                })();
                service.execute({
                    data: data,
                    params: params,
                    headers: headers
                }).subscribe(
                    /* onsuccess */
                    async (res: any) => {
                        let mappingData: any = {};
                        /* Mapping */
                        this.cardToken = this.$aio_mappingHelper.updateData(this.cardToken, [], this.$aio_mappingHelper.getSubdata(res, ["id"]));
                        /* Invoke data service */
                        this.invokeService_chargeToken1();
                        this.mappingData = { ...this.mappingData,
                            ...mappingData
                        };
                        if (cb && typeof cb === "function") cb(res);
                    },
                    /* onerror */
                    async (err: any) => {
                        /* Dismiss loading */
                        await this.Apperyio.getController("LoadingController").dismiss();
                        /* Run TypeScript */
                        var errorstring = JSON.stringify(err);
                        if (errorstring.indexOf('invalid') !== -1) {
                            const alert = await this.alertController.create({
                                cssClass: 'my-custom-class',
                                header: 'Alert',
                                mode: 'ios',
                                message: 'Please check your payment details for accuracy.',
                                buttons: ['OK']
                            });
                            await alert.present();
                        } else {
                            const alert = await this.alertController.create({
                                cssClass: 'my-custom-class',
                                header: 'Alert',
                                mode: 'ios',
                                message: 'Please check your network connection or try again later.',
                                buttons: ['OK']
                            });
                            await alert.present();
                        }
                    }
                )
            }
        );
    }
    invokeService_chargeToken1(cb?: Function) {
        this.Apperyio.getService("chargeToken").then(
            async (service) => {
                if (!service) {
                    console.log("Error. Service was not found.");
                    return;
                }
                let data = {};
                let params = {};
                let headers = {};
                let __aio_tmp_val__: any;
                this.$aio_changeDetector.detectChanges();
                /* Mapping */
                headers = this.$aio_mappingHelper.updateData(headers, ['user-token'], this.$aio_mappingHelper.getSubdata(this.token, []));
                data = this.$aio_mappingHelper.updateData(data, ["token"], this.$aio_mappingHelper.getSubdata(this.cardToken, []));
                data = this.$aio_mappingHelper.updateData(data, ["amount"], this.$aio_mappingHelper.getSubdata(this.amount, []));
                service.execute({
                    data: data,
                    params: params,
                    headers: headers
                }).subscribe(
                    /* onsuccess */
                    async (res: any) => {
                        let mappingData: any = {};
                        /* Mapping */
                        this.chargeId = this.$aio_mappingHelper.updateData(this.chargeId, [], this.$aio_mappingHelper.getSubdata(res, ["id"]));
                        /* Run TypeScript */
                        this.$a.data.setVariable("transactionStatus", "unknown");
                        var paymentResult = res.status;
                        if (paymentResult === "succeeded") {
                            this.$a.data.setVariable("transactionStatus", "approved");
                            this.$a.data.setVariable("transactionId", this.chargeId);
                            await this.$a.dismissLoading();
                            this.$a.closeModal(
                                /* data */
                            );
                        } else {
                            const alert = await this.alertController.create({
                                cssClass: 'my-custom-class',
                                header: 'Alert',
                                message: 'We could not complete your purchase.',
                                buttons: ['OK']
                            });
                            await alert.present();
                        }
                        this.mappingData = { ...this.mappingData,
                            ...mappingData
                        };
                        if (cb && typeof cb === "function") cb(res);
                    },
                    /* onerror */
                    async (err: any) => {
                        /* Dismiss loading */
                        await this.Apperyio.getController("LoadingController").dismiss();
                        /* Run TypeScript */
                        var errorstring = JSON.stringify(err);
                        if (errorstring.indexOf('invalid') !== -1) {
                            const alert = await this.alertController.create({
                                cssClass: 'my-custom-class',
                                header: 'Alert',
                                mode: 'ios',
                                message: 'Please check your payment details for accuracy.',
                                buttons: ['OK']
                            });
                            await alert.present();
                        } else {
                            const alert = await this.alertController.create({
                                cssClass: 'my-custom-class',
                                header: 'Alert',
                                mode: 'ios',
                                message: 'Please check your network connection or try again later.',
                                buttons: ['OK']
                            });
                            await alert.present();
                        }
                    }
                )
            }
        );
    }
}