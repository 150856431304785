import {
    Component
} from '@angular/core';
import {
    ApperyioHelperService
} from '../scripts/apperyio/apperyio_helper';
import {
    ApperyioMappingHelperService
} from '../scripts/apperyio/apperyio_mapping_helper';
import {
    AlertController
} from '@ionic/angular';
import {
    LoadingController
} from '@ionic/angular';
import {
    NavController
} from '@ionic/angular';
import {
    Platform
} from '@ionic/angular';
import {
    Router
} from '@angular/router';
import {
    HttpClient
} from '@angular/common/http';
import {
    File
} from '@ionic-native/file/ngx';
import {
    Dialogs
} from '@ionic-native/dialogs/ngx';
import {
    Media
} from '@ionic-native/media/ngx';
import {
    ChangeDetectorRef
} from '@angular/core';
import {
    Purchases
} from '@ionic-native/purchases/ngx';
import {
    $aio_empty_object
} from '../scripts/interfaces';
import {
    ViewChild
} from '@angular/core';
@Component({
    templateUrl: 'AudioModal.html',
    selector: 'page-audio-modal',
    styleUrls: ['AudioModal.css', 'AudioModal.scss']
})
export class AudioModal {
    public showUpcoming: string;
    public showPast: string;
    public showAudio: string;
    public showPlay: string;
    public language: string;
    public voices: any;
    public token: string;
    public uid: string;
    public semail: string;
    public vlimit: string;
    public caudiourl: string;
    public myscript: string;
    public scriptCount: number;
    public poststamp: number;
    public ownedState: string;
    public filename: string;
    public pitch: string;
    public postscene: string;
    public porder: string;
    public ppitch: string;
    public demourl: string;
    public play: string;
    public image: string;
    public name: string;
    public creating: string;
    public pid: string;
    public myfront: string;
    public timeLeft: number;
    public recordingStatus: boolean;
    public noneclass: string;
    public chosenvoice: string;
    public voiceId: string;
    public voicePitch: string;
    public voiceRate: string;
    public playButton: string;
    public pauseButton: string;
    public recordingText: string;
    public recordingClass: string;
    public rInterval: any;
    public recordingBColor: string;
    public recordingTColor: string;
    public myfile: string;
    public myMedia: any;
    public ampaverage: number;
    public amplitude: string;
    public cstate: string;
    public tempaudio: string;
    public voiceChanger: boolean;
    public sendfile: string;
    public ocall: any;
    public handleData: any;
    public handleError: any;
    public postfile: string;
    public isMine: boolean;
    public isrecording: boolean;
    public puppetId: string;
    public puppetImage: string;
    public puppetName: string;
    public saveaudio: string;
    public duration: number;
    public encodedname: string;
    public uploadRes: string;
    public showSubscribe: boolean;
    public isowned: boolean;
    public showSubButton: string;
    public planid: string;
    public periodstart: string;
    public useFile: string;
    public apptype: string;
    public scriptCountString: string;
    public puppets: any;
    public showTop: any;
    public showBottom: any;
    public buttonIcon: any;
    public buttonColor: any;
    public buttonText: any;
    public canvasref: any;
    public voiceDisabled: boolean;
    public showVisualizer: string;
    public $a: ApperyioHelperService;
    public $v: {
        [name: string]: any
    };
    public aioChangeDetector: ChangeDetectorRef;
    public currentItem: any = null;
    @ViewChild('_aio_content') _aio_content;
    public mappingData: any = {};
    public __getMapping(_currentItem, property, defaultValue, isVariable?, isSelected?) {
        return this.$aio_mappingHelper.getMapping(this.mappingData, _currentItem, property, defaultValue, isVariable, isSelected);
    }
    public __isPropertyInMapping(_currentItem, property) {
        return this.$aio_mappingHelper.isPropertyInMapping(this.mappingData, _currentItem, property);
    }
    public __setMapping(data: any = {}, keyName: string, propName?: string): void {
        const changes = data.detail || {};
        if (propName) {
            this.mappingData = this.$aio_mappingHelper.updateData(this.mappingData, [keyName], changes[propName]);
        } else {
            this.mappingData = this.$aio_mappingHelper.updateData(this.mappingData, [keyName], changes.value);
        }
        this.$aio_changeDetector.detectChanges();
    }
    public __bindedMethods: any = {};
    @ViewChild('j_794', {
        static: true
    }) public j_794;
    @ViewChild('j_805', {
        static: true
    }) public j_805;
    updateVoices(): any {
        this.Apperyio.execDataService(this, "getVoices1_clone_1"); // Change service_name to created DataSource name
    }
    playPreview(index): any {
        var itemUrl = this.voices[index].audioFile;
        var ctext = this.voices[index].playtext;
        this.caudiourl = itemUrl;
        if (ctext === "Stop") {
            for (var i = 0, l = this.voices.length; i < l; i++) {
                this.voices[index].playtext = "Preview";
                const x = document.getElementById('waudio') as HTMLAudioElement | null;
                x.pause();
            }
        } else {
            for (var i = 0, l = this.voices.length; i < l; i++) {
                this.voices[i].playtext = "Preview";
            }
            this.voices[index].playtext = "Preview";
            const x = document.getElementById('waudio') as HTMLAudioElement | null;
            x.pause();
            x.load();
            x.play();
            this.voices[index].playtext = "Stop"
            x.onended = () => {
                this.voices[index].playtext = "Preview";
            };
        }
    }
    scriptChange(): any {
    }
    selectVoice(index): any {
        this.voiceId = this.voices[index].id;
        this.voicePitch = this.voices[index].pitch;
        this.voiceRate = "default";
        if (this.voicePitch === "Low") {
            this.voicePitch = "low";
        } else if (this.voicePitch === "High") {
            this.voicePitch = "high";
        } else {
            this.voicePitch = "none";
        }
        var scriptLength = this.myscript.length;
        if (scriptLength <= 5) {
            this.alertController.create({
                header: 'Alert',
                message: 'Your script is a little short. Add some more words for a better video!',
                buttons: ['OK']
            }).then(res => {
                res.present();
            });
        } else {
            this.Apperyio.execDataService(this, "checkPending1_clone_1"); // Change service_name to created DataSource name
        }
    }
    playAudio(): any {
        this.playButton = "hideGrid";
        this.pauseButton = "showGrid";
        const x = document.getElementById('waudio') as HTMLAudioElement | null;
        x.play();
        x.onended = () => {
            this.playButton = "showGrid";
            this.pauseButton = "hideGrid";
        };
    }
    noLike(): any {
        if (this.isMine === false) {
            this.showUpcoming = "hideGrid";
            this.showPast = "showGrid";
            this.showAudio = "hideGrid";
            this.playButton = "hideGrid";
            this.pauseButton = "hideGrid";
            this.voiceChanger = false;
            this.chosenvoice = "none";
            const x = document.getElementById('waudio') as HTMLAudioElement | null;
            x.pause();
        } else {
            this.showUpcoming = "showGrid";
            this.showPast = "hideGrid";
            this.showAudio = "hideGrid";
            this.playButton = "hideGrid";
            this.pauseButton = "hideGrid";
            this.voiceChanger = false;
            this.chosenvoice = "none";
            const x = document.getElementById('waudio') as HTMLAudioElement | null;
            x.pause();
        }
    }
    yesLike(): any {
        this.playButton = "showGrid";
        this.pauseButton = "hideGrid";
        const x = document.getElementById('waudio') as HTMLAudioElement | null;
        x.pause();
        if (this.isMine === false) {
            this.duration = Math.round(x.duration);
            this.postfile = "done";
            this.saveaudio = this.caudiourl;
            this.Apperyio.execDataService(this, "sendService2_clone_1"); // Change service_name to created DataSource name
        } else {
            this.Apperyio.execDataService(this, "sendService1_clone_1"); // Change service_name to created DataSource name
        }
    }
    pauseAudio(): any {
        this.playButton = "showGrid";
        this.pauseButton = "hideGrid";
        const x = document.getElementById('waudio') as HTMLAudioElement | null;
        x.pause();
    }
    startRecording(): any {
        if (this.recordingText === "Start" && this.isrecording === false) {
            this.isrecording = true;
            //var mediaRec;
            //var timerId;
            var pickedVoice = this.chosenvoice;
            this.voiceChanger = true;
            var amparray = [];
            this.poststamp = Date.now();
            var mycount = 0;
            if (this.isowned === true) {
                this.postfile = pickedVoice + "." + this.uid + "_" + this.postscene + "-" + "++" + this.myfront + "_" + this.poststamp;
                this.useFile = "none" + "." + this.uid + "_" + this.postscene + "-" + "++" + this.myfront + "_" + this.poststamp;
            } else {
                this.postfile = pickedVoice + "." + this.uid + "_" + this.postscene + "-" + "p+" + this.myfront + "_" + this.poststamp;
                this.useFile = "none" + "." + this.uid + "_" + this.postscene + "-" + "p+" + this.myfront + "_" + this.poststamp;
            }
            //this.postfile = pickedVoice + "." + this.uid + "_" + this.postscene + "-" + "c+" + this.myfront + "_" + this.poststamp;
            //this.useFile = "none" + "." + this.uid + "_" + this.postscene + "-" + "c+" + this.myfront + "_" + this.poststamp;
            this.encodedname = encodeURI("name=" + this.postfile);
            if (this.ampaverage < .1) {
                pickedVoice = pickedVoice + "3";
            } else if (this.ampaverage > .1 && this.ampaverage < .2) {
                pickedVoice = pickedVoice + "3";
            } else if (this.ampaverage > .2 && this.ampaverage < .3) {
                pickedVoice = pickedVoice + "3";
            } else if (this.ampaverage > .3 && this.ampaverage < .4) {
                pickedVoice = pickedVoice + "3";
            } else {
                pickedVoice = pickedVoice + "3";
            }
            if (this.isowned === true) {
                var src = pickedVoice + "." + this.uid + "_" + this.postscene + "-" + "++" + this.myfront + "_" + this.poststamp + '.m4a';
                this.sendfile = pickedVoice + "." + this.uid + "_" + this.postscene + "-" + "++" + this.myfront + "_" + this.poststamp + '.m4a';
            } else {
                var src = pickedVoice + "." + this.uid + "_" + this.postscene + "-" + "p+" + this.myfront + "_" + this.poststamp + '.m4a';
                this.sendfile = pickedVoice + "." + this.uid + "_" + this.postscene + "-" + "p+" + this.myfront + "_" + this.poststamp + '.m4a';
            }
            this.saveaudio = "https://backendlessappcontent.com/876B8C32-01E1-6474-FFA9-06AFA3F13500/CD988E3D-5002-2905-FFBA-738AC6F82300/files/audios/" + this.useFile + ".wav";
            //  var src = "recording.m4a";
            //  this.sendfile = "recording.m4a";
            this.myMedia = this.media.create(this.sendfile);
            this.myMedia.startRecord();
            this.timeLeft = 20;
            this.recordingClass = "circleButtonPulse";
            this.recordingBColor = "primary";
            this.cd.markForCheck();
            this.cd.detectChanges();
            this.rInterval = setInterval(() => {
                if (this.timeLeft === 0) {
                    this.myMedia.stopRecord();
                    this.duration = 20;
                    this.recordingClass = "circleButtonWhite";
                    this.recordingBColor = "secondary";
                    this.recordingText = "Start";
                    clearInterval(this.rInterval);
                    this.newUpload();
                } else {
                    this.timeLeft = this.timeLeft - 1;
                    this.recordingText = this.timeLeft.toString();
                }
            }, 1000);
        } else {
            this.myMedia.stopRecord();
            this.duration = 20 - this.timeLeft;
            this.voiceChanger = false;
            this.chosenvoice = "none";
            this.isrecording = false;
            this.recordingClass = "circleButtonWhite";
            this.recordingBColor = "secondary";
            this.recordingText = "Start";
            this.cd.markForCheck();
            this.cd.detectChanges();
            clearInterval(this.rInterval);
            if (this.timeLeft > 18) {
                this.alertController.create({
                    header: 'Alert',
                    message: 'Try creating recording longer audio for better video quality.',
                    buttons: ['OK']
                }).then(res => {
                    res.present();
                });
            } else {
                this.newUpload();
            }
        }
    }
    newUpload(): any {
        this.voiceChanger = false;
        this.loadingController.create({
            message: 'Loading...'
        }).then(res => {
            res.present();
        });

        function getFileReader(): FileReader {
            const fileReader = new FileReader();
            const zoneOriginalInstance = (fileReader as any)["__zone_symbol__originalInstance"];
            return zoneOriginalInstance || fileReader;
        }
        let newInstance = getFileReader();
        this.platform.ready()
            .then(() => {
                return this.file.resolveDirectoryUrl(this.file.tempDirectory);
            })
            .then((rootDir) => {
                var mye = JSON.stringify(rootDir);
                return this.file.getFile(rootDir, this.sendfile, {
                    create: false
                });
            })
            .then((fileEntry) => {
                fileEntry.file(file => {
                    // var myreader = new FileReader();
                    var filename = this.sendfile;
                    // myreader.onloadend = function () {
                    newInstance.onloadend = (e) => {
                        var formData = new FormData(); // Create a blob based on the FileReader "result", which we asked to be retrieved as an ArrayBuffer
                        var blob = new Blob([newInstance.result], {
                            type: "audio/m4a"
                        });
                        formData.append("file", blob, this.postfile); //const myNewFile = new File([this.result], filenaming, {type: 'audio/m4a'});
                        this.ocall = formData;
                        this.http.post("https://puppetrave.com/api/saveAudioV2.php", formData)
                            .subscribe(data => {
                                this.handleData = data;
                                this.caudiourl = this.handleData.audioUrl;
                                this.playButton = "showGrid";
                                this.pauseButton = "hideGrid";
                                let TIME_IN_MS = 2000;
                                let showAudioGrid = setTimeout(() => {
                                    this.showUpcoming = "hideGrid";
                                    this.showPast = "hideGrid";
                                    this.showAudio = "showGrid";
                                    this.loadingController.dismiss();
                                    this.cd.markForCheck();
                                    this.cd.detectChanges();
                                    const x = document.getElementById('waudio') as HTMLAudioElement | null;
                                    x.pause();
                                    x.load();
                                }, TIME_IN_MS);
                            }, error => {
                                this.loadingController.dismiss();
                                this.alertController.create({
                                    header: 'Alert',
                                    message: 'There was an error. Please try again later.',
                                    buttons: ['OK']
                                }).then(res => {
                                    res.present();
                                });
                            });
                    }; // Read the file as an ArrayBuffer
                    newInstance.readAsArrayBuffer(file);
                });
            })
    }
    async checkMic(): Promise < any > {
        this.canvasref.checkMic();
        setTimeout(myTimer, 500);

        function myTimer() {
            const d = localStorage.getItem("gorecord");
            if (d === "none") {
                this.checkMicAgain();
            } else if (d === "yes") {
                this.startRecording();
            } else {
                console.log("no");
            }
        }
    }
    requestMic(): any {
    }
    async exitAudio(): Promise < any > {
        this.dialogs.confirm(
            'Are you sure you would like to exit?', // message         // callback to invoke with index of button pressed
            'Confirm', // title
            ['Cancel', 'Exit'] // buttonLabels
        )
        .then(buttonIndex => {
            if (buttonIndex === 2) {
                this.navCtrl.navigateRoot('/tabs');
            }
        })
        .catch(e => console.log('Error displaying dialog', e));
    }
    goHome(): any {
        this.dialogs.confirm(
                'Are you sure you would like to exit?', // message         // callback to invoke with index of button pressed
                'Confirm', // title
                ['Cancel', 'Exit'] // buttonLabels
            )
            .then(buttonIndex => {
                if (buttonIndex === 2) {
                    this.navCtrl.navigateBack('/tabs/Home');
                }
            })
            .catch(e => console.log('Error displaying dialog', e));
    }
    pickPuppet(index): any {
        this.buttonIcon = "checkmark-circle";
        this.buttonColor = "#2dd36f";
        this.buttonText = "Great choice! Now, let's create the audio.";
        localStorage.setItem("pimage", this.puppets[index].image);
        localStorage.setItem("porder", this.puppets[index].order);
        localStorage.setItem("pname", this.puppets[index].name);
        localStorage.setItem("pid", this.puppets[index].objectId);
        localStorage.setItem("sback", this.puppets[index].sback);
        localStorage.setItem("sfront", this.puppets[index].order);
        localStorage.setItem("sampleVideo", this.puppets[index].defaultVideo);
        localStorage.setItem("samplePoster", this.puppets[index].poster);
        localStorage.setItem("sampleTheme", this.puppets[index].defaultTheme);
        this.puppetImage = localStorage.getItem("pimage");
        this.puppetName = localStorage.getItem("pname");
        this.puppetId = localStorage.getItem("pid");
        this.token = localStorage.getItem("token");
        this.uid = localStorage.getItem("uid");
        this.vlimit = localStorage.getItem("vids");
        this.semail = localStorage.getItem("semail");
        this.demourl = localStorage.getItem("demourl");
        this.image = localStorage.getItem("pimage");
        this.name = localStorage.getItem("pname");
        this.pid = localStorage.getItem("pid");
        this.ownedState = localStorage.getItem("latest");
        this.porder = localStorage.getItem("porder");
        this.postscene = localStorage.getItem("sback");
        this.myfront = localStorage.getItem("sfront");
        this.showTop = "hideGrid";
        this.showBottom = "showGrid";
        for (let i = 0; i < this.puppets.length; i++) {
            this.puppets[i].image = this.puppets[i].image + "border:5px solid #D3D3D3;";
        }
        this.puppets[index].image = this.puppets[index].image + "border:5px solid #EC6F77;";
    }
    checkMicAgain(): any {
        console.log("again");
        this.checkMic();
    }
    sRecord(): any {
        var dur = 0;
        var durSec = "";
        var mynamehold = "";
        var isowned = this.isowned;
        var srurl = "";
        var uid = this.uid;
        var postscene = this.postscene;
        var myfront = this.myfront;
        this.poststamp = Date.now();
        var poststamp = this.poststamp
        let doit = () => {
            this.voiceDisabled = false;
            this.showVisualizer = "hideGrid";
            this.duration = parseInt(durSec);
            this.caudiourl = srurl;
            let TIME_IN_MS = 3000;
            let loadAudioGrid = setTimeout(() => {
                const x = document.getElementById('waudio') as HTMLAudioElement | null;
                x.load();
                this.playButton = "showGrid";
                this.pauseButton = "hideGrid";
                this.showUpcoming = "hideGrid";
                this.showPast = "hideGrid";
                this.showAudio = "showGrid";
                this.loadingController.dismiss();
            }, TIME_IN_MS);
        }
        let showload = () => {
            this.recordingClass = "circleButtonWhite";
            this.recordingBColor = "secondary";
            this.Apperyio.getController("LoadingController").create({
                message: 'Please wait...',
                spinner: 'crescent',
                cssClass: 'canvasLoader',
                mode: 'ios'
            }).then(loading => loading.present());
            this.isrecording = false;
            this.showVisualizer = "hideGrid";
        }
        let tooShort = () => {
            this.recordingClass = "circleButtonWhite";
            this.recordingBColor = "secondary";
            this.isrecording = false;
            this.voiceDisabled = false;
            this.showVisualizer = "hideGrid";
            this.tooshort();
        }
        let notSupported = () => {
            this.notsupported();
        }
        let microphoneError = () => {
            this.microphoneerror();
        }
        let microphoneGrant = () => {
            this.microphonegrant();
        }
        var chosenvoice = this.chosenvoice;
        var ua = navigator.userAgent.toLowerCase();
        if ((ua.indexOf('chrome') != -1) || (ua.indexOf('firefox') != -1)) {
            const mainbutton = document.getElementById("sbutt2");
            //const redomy2 = document.getElementById("redomy2");
            var sbutttext = document.getElementById("sbutt2").innerHTML;
            if (sbutttext === " Start " && this.isrecording === false) {
                if (this.isowned === true) {
                    this.postfile = chosenvoice + "." + this.uid + "_" + this.postscene + "-" + "++" + this.myfront + "_" + this.poststamp;
                    this.useFile = "none" + "." + this.uid + "_" + this.postscene + "-" + "++" + this.myfront + "_" + this.poststamp;
                } else {
                    this.postfile = chosenvoice + "." + this.uid + "_" + this.postscene + "-" + "p+" + this.myfront + "_" + this.poststamp;
                    this.useFile = "none" + "." + this.uid + "_" + this.postscene + "-" + "p+" + this.myfront + "_" + this.poststamp;
                }
                this.saveaudio = "https://backendlessappcontent.com/876B8C32-01E1-6474-FFA9-06AFA3F13500/CD988E3D-5002-2905-FFBA-738AC6F82300/files/audios/" + this.useFile + ".wav";
                this.isrecording = true;
                this.voiceDisabled = true;
                this.showVisualizer = "showGrid";
                this.recordingClass = "circleButtonPulse";
                this.recordingBColor = "primary";
                if (navigator.mediaDevices.getUserMedia) {
                    const constraints = {
                        audio: true
                    };
                    let onSuccess = function(stream) {
                        const canvas = document.querySelector('.visualizer') as HTMLCanvasElement | null;
                        let audioCtx;
                        const canvasCtx = canvas.getContext("2d");
                        var myoptions = {
                            audioBitsPerSecond: 320000
                        };
                        const mediaRecorder = new MediaRecorder(stream, myoptions);
                        visualize(stream);
                        var countsecs = 20;
                        var starttext = "20";
                        let chunks = [];
                        mediaRecorder.start();
                        var recstart = Date.now();
                        //  tostop2.classList.remove("hideIt");
                        //  rtext2.classList.add("hideIt");
                        //viz2.classList.remove("hideIt");
                        //if (redomy2.getAttribute('listener') !== 'true') {
                        //   redomy2.addEventListener("click", redomine);
                        // redomy2.setAttribute('listener', 'true');
                        // console.log("reset");
                        //}else{
                        //}
                        if (mainbutton.getAttribute('listener') !== 'true') {
                            mainbutton.addEventListener("click", stopIt);
                            mainbutton.setAttribute('listener', 'true');
                            console.log("stop attribute set");
                        } else {
                            console.log("stop attribute already exists");
                        }
                        mediaRecorder.onstop = function(e) {
                            var recend = Date.now();
                            var milduration = recend - recstart;
                            if (milduration < 3000) {
                                mainbutton.innerHTML = " Start ";
                                redomine();
                                tooShort();
                                //swal("Error", "Please create a recording at least 3 seconds long.", "error"); 
                            } else {
                                var decdur = milduration / 1000;
                                dur = Math.round(decdur);
                                durSec = dur.toString();
                                sbutttext = " Start ";
                                mainbutton.innerHTML = " Start ";
                                redomine();
                                uploadRecording();
                                // var audio = document.getElementById('recplayer2');
                                // const blob = new Blob(chunks, { 'type' : 'audio/wav' });
                                //chunks = [];
                                //const audioURL = window.URL.createObjectURL(blob);
                                // audio.src = audioURL;
                                //audio.load();
                            }
                        }

                        function uploadRecording() {
                            let TIME_IN_MS = 50;
                            let showAudioGrid = setTimeout(() => {
                                showload();
                            }, TIME_IN_MS);
                            var sup = new Blob(chunks, {
                                type: 'audio/wav'
                            });
                            var pickedVoice = chosenvoice;
                            var myname = "";
                            var src = "";
                            var sendfile = "";
                            if (isowned === true) {
                                src = pickedVoice + "." + uid + "_" + postscene + "-" + "++" + myfront + "_" + poststamp + '.wav';
                                sendfile = pickedVoice + "." + uid + "_" + postscene + "-" + "++" + myfront + "_" + poststamp + '.wav';
                                myname = pickedVoice + "." + uid + "_" + postscene + "-" + "++" + myfront + "_" + poststamp;
                                mynamehold = "none" + "." + uid + "_" + postscene + "-" + "++" + myfront + "_" + poststamp;
                            } else {
                                src = pickedVoice + "." + uid + "_" + postscene + "-" + "p+" + myfront + "_" + poststamp + '.wav';
                                sendfile = pickedVoice + "." + uid + "_" + postscene + "-" + "p+" + myfront + "_" + poststamp + '.wav';
                                myname = pickedVoice + "." + uid + "_" + postscene + "-" + "p+" + myfront + "_" + poststamp;
                                mynamehold = "none" + "." + uid + "_" + postscene + "-" + "p+" + myfront + "_" + poststamp;
                            }
                            const url = "https://puppetrave.com/api/saveAudioWeb.php";
                            var formData = new FormData();
                            //formData.append("name", myname);
                            //formData.append("thefile", sup);
                            formData.append("file", sup, myname);
                            const xhr = new XMLHttpRequest();
                            xhr.onload = () => {
                                var srurlobj = JSON.parse(xhr.responseText);
                                srurl = srurlobj.audioUrl;
                                let TIME_IN_MS = 1000;
                                let showAudioGrid = setTimeout(() => {
                                    doit();
                                }, TIME_IN_MS);
                            };
                            //create and send the reqeust
                            xhr.open('POST', url);
                            xhr.send(formData);
                        }
                        var myintervaltime = setInterval(countdown, 1000);

                        function countdown() {
                            if (countsecs === 0) {
                                stopIt();
                            } else if (countsecs <= 10) {
                                countsecs = countsecs - 1;
                                mainbutton.innerHTML = countsecs.toString();
                                sbutttext = document.getElementById("sbutt2").innerHTML;
                            } else {
                                countsecs = countsecs - 1;
                                mainbutton.innerHTML = countsecs.toString();
                                sbutttext = document.getElementById("sbutt2").innerHTML;
                            }
                        }

                        function visualize(stream) {
                            if (!audioCtx) {
                                audioCtx = new AudioContext();
                            }
                            const source = audioCtx.createMediaStreamSource(stream);
                            const analyser = audioCtx.createAnalyser();
                            analyser.fftSize = 2048;
                            const bufferLength = analyser.frequencyBinCount;
                            const dataArray = new Uint8Array(bufferLength);
                            source.connect(analyser);
                            //analyser.connect(audioCtx.destination);
                            draw()

                            function draw() {
                                const WIDTH = canvas.width
                                const HEIGHT = canvas.height;
                                requestAnimationFrame(draw);
                                analyser.getByteTimeDomainData(dataArray);
                                canvasCtx.fillStyle = 'rgb(255, 255, 255)';
                                canvasCtx.fillRect(0, 0, WIDTH, HEIGHT);
                                canvasCtx.lineWidth = 2;
                                canvasCtx.strokeStyle = '#EC6F77';
                                canvasCtx.beginPath();
                                let sliceWidth = WIDTH * 1.0 / bufferLength;
                                let x = 0;
                                for (let i = 0; i < bufferLength; i++) {
                                    let v = dataArray[i] / 128.0;
                                    let y = v * HEIGHT / 2;
                                    if (i === 0) {
                                        canvasCtx.moveTo(x, y);
                                    } else {
                                        canvasCtx.lineTo(x, y);
                                    }
                                    x += sliceWidth;
                                }
                                canvasCtx.lineTo(canvas.width, canvas.height / 2);
                                canvasCtx.stroke();
                            }
                        }

                        function redomine() {
                            // redomy2.removeEventListener("click", redomine);
                            // redomy2.removeAttribute('listener');
                            //  var audio = document.getElementById('recplayer2') as HTMLAudioElement | null;;
                            //           audio.pause();
                            //          mainbutton.innerHTML = " Start ";
                            // tostop2.classList.add("hideIt");
                            // rcreate2.classList.add("hideIt");
                            //rtext2.classList.remove("hideIt");
                            // voicepicker2.classList.remove("hideIt");
                            // viz2.classList.add("hideIt");
                            // redo2.classList.add("hideIt");
                            if (mainbutton.getAttribute('listener') === 'true') {
                                mainbutton.removeEventListener("click", stopIt);
                                mainbutton.removeAttribute('listener');
                            }
                        }

                        function stopIt() {
                            sbutttext = document.getElementById("sbutt2").innerHTML;
                            if (sbutttext === " Start ") {} else if (sbutttext === "Play audio") {} else if (sbutttext === "Playing...") {} else {
                                // tostop2.classList.add("hideIt");
                                //  rcreate2.classList.remove("hideIt");
                                //  rtext2.classList.add("hideIt");
                                //  viz2.classList.add("hideIt");
                                //   voicepicker2.classList.add("hideIt");
                                //  redo2.classList.remove("hideIt");
                                clearInterval(myintervaltime);
                                mediaRecorder.stop();
                                var track = stream.getTracks()[0];
                                track.stop();
                            }
                        }
                        mediaRecorder.ondataavailable = function(e) {
                            chunks.push(e.data);
                        }
                    }
                    let onError = function(err) {
                        var mye = String(err);
                        if (mye.indexOf('NotAllowedError') != -1) {
                            microphoneGrant();
                        } else {
                            microphoneError();
                        }
                    }
                    navigator.mediaDevices.getUserMedia(constraints).then(onSuccess, onError);
                } else {
                    notSupported();
                }
            } else {
            }
        } else {
            notSupported();
        }
    }
    async tooshort(): Promise < any > {
        const alert = await this.alertController.create({
            cssClass: 'my-custom-class',
            header: 'Alert',
            mode: 'ios',
            message: 'Create a recording longer than 3 seconds.',
            buttons: ['OK']
        });
        await alert.present();
    }
    async notsupported(): Promise < any > {
        const alert = await this.alertController.create({
            cssClass: 'my-custom-class',
            header: 'Alert',
            mode: 'ios',
            message: 'Voice recording is not supported by your browser. Please use the latest version of chrome.',
            buttons: ['OK']
        });
        await alert.present();
    }
    async microphoneerror(): Promise < any > {
        const alert = await this.alertController.create({
            cssClass: 'my-custom-class',
            header: 'Alert',
            mode: 'ios',
            message: 'You must allow access to the microphone to record audio.',
            buttons: ['OK']
        });
        await alert.present();
    }
    async microphonegrant(): Promise < any > {
        const alert = await this.alertController.create({
            cssClass: 'my-custom-class',
            header: 'Alert',
            mode: 'ios',
            message: 'There was an error obtaining microphone access for voice recording.',
            buttons: ['OK']
        });
        await alert.present();
    }
    constructor(public Apperyio: ApperyioHelperService, private $aio_mappingHelper: ApperyioMappingHelperService, private $aio_changeDetector: ChangeDetectorRef, public alertController: AlertController, public loadingController: LoadingController, public media: Media, public platform: Platform, public file: File, public http: HttpClient, public cd: ChangeDetectorRef, public purchases: Purchases, public navCtrl: NavController, public dialogs: Dialogs, public router: Router) {
        this.$a = this.Apperyio;
        this.$v = this.Apperyio.vars;
        this.aioChangeDetector = this.$aio_changeDetector;
    }
    ngOnInit() {
        this.Apperyio.setThinScrollIfNeeded();
    }
    ionViewWillEnter() {
        window.currentScreen = this;
        this.pageIonViewWillEnter__j_781();
    }
    ionViewWillLeave() {
        this.pageIonViewWillLeave__j_781();
    }
    async pageIonViewWillLeave__j_781(event?, currentItem?) {
        let __aio_tmp_val__: any;
        /* Run TypeScript */
        if (this.isrecording === false) {
            this.myMedia.stopRecord();
        }
    }
    async pageIonViewWillEnter__j_781(event?, currentItem?) {
        let __aio_tmp_val__: any;
        /* Invoke data service */
        this.invokeService_getPuppets2_clone_1();
        /* Run TypeScript */
        // resources in description
        this.showUpcoming = "showGrid";
        this.scriptCountString = "0";
        localStorage.setItem("gorecord", "none");
        this.showTop = "showGrid";
        this.buttonIcon = "arrow-up";
        this.buttonColor = "Medium";
        this.buttonText = "👆 Select a puppet";
        this.showBottom = "hideGrid";
        this.isMine = true;
        this.isrecording = false;
        localStorage.setItem("latest", "audio");
        this.apptype = "party";
        this.chosenvoice = "none";
        this.voiceChanger = false;
        this.recordingText = "Start";
        this.recordingBColor = "secondary";
        this.recordingClass = "circleButtonWhite";
        this.puppetImage = localStorage.getItem("pimage");
        this.puppetName = localStorage.getItem("pname");
        this.puppetId = localStorage.getItem("pid");
        this.token = localStorage.getItem("token");
        this.uid = localStorage.getItem("uid");
        this.vlimit = localStorage.getItem("vids");
        this.semail = localStorage.getItem("semail");
        this.showPast = "hideGrid";
        this.scriptCount = 0;
        this.timeLeft = 20;
        this.myscript = "";
        this.showAudio = "hideGrid";
        this.demourl = localStorage.getItem("demourl");
        this.play = "button-icon icon ion-play playButton centerImage";
        this.image = localStorage.getItem("pimage");
        this.name = localStorage.getItem("pname");
        this.creating = "creating";
        this.pid = localStorage.getItem("pid");
        this.ownedState = localStorage.getItem("latest");
        this.porder = localStorage.getItem("porder");
        this.postscene = localStorage.getItem("sback");
        this.myfront = localStorage.getItem("sfront");
        this.recordingStatus = false;
        this.noneclass = "voiceButtonSelected";
        this.language = "7732555F-88A2-4415-B308-27B27A61318F";
        const mainTabs = document.querySelector(".main-tabsa");
        const mainSliderCircle = document.querySelector(".main-slider-circlea");
        const roundButtons = document.querySelectorAll(".round-buttona");
        const colors = {
            blue: {
                50: {
                    value: "#e3f2fd"
                },
                100: {
                    value: "#bbdefb"
                }
            },
            green: {
                50: {
                    value: "#e8f5e9"
                },
                100: {
                    value: "#c8e6c9"
                }
            },
            purple: {
                50: {
                    value: "#f3e5f5"
                },
                100: {
                    value: "#e1bee7"
                }
            },
            orange: {
                50: {
                    value: "#ffe0b2"
                },
                100: {
                    value: "#ffe0b2"
                }
            },
            red: {
                50: {
                    value: "#ffebee"
                },
                100: {
                    value: "#ffcdd2"
                }
            }
        };
        const getColor = (color, variant) => {
            return colors[color][variant].value;
        };
        const handleActiveTab = (tabs, event, className) => {
            tabs.forEach((tab) => {
                tab.classList.remove(className);
            });
            if (!event.target.classList.contains(className)) {
                event.target.classList.add(className);
            }
        };
        const startActiveTab = (tabs) => {
            tabs.forEach((tab) => {
                tab.classList.remove("filter-activea");
            });
            tabs[0].classList.add("filter-activea");
        };
        const filterTabs = document.querySelector(".filter-tabsa");
        const filterButtons = document.querySelectorAll(".filter-buttona");
        filterTabs.addEventListener("click", (event) => {
            if (event !== null && event.target instanceof HTMLElement) {
                const root = document.documentElement;
                const targetTranslateValueq = event.target.dataset.translateValueq;
                if (this.isrecording === true) {
                    this.alertController.create({
                        header: 'Alert',
                        message: 'You are still recording. Please stop before proceeding.',
                        buttons: ['OK']
                    }).then(res => {
                        res.present();
                    });
                } else {
                    this.isrecording = false;
                    if (targetTranslateValueq === "100%") {
                        this.voiceChanger = false;
                        this.isMine = false;
                        this.showUpcoming = "hideGrid";
                        this.showPast = "showGrid";
                        this.showAudio = "hideGrid";
                    } else {
                        this.voiceChanger = false;
                        this.isMine = true;
                        this.showUpcoming = "showGrid";
                        this.showPast = "hideGrid";
                        this.showAudio = "hideGrid";
                    }
                    if (event.target.classList.contains("filter-buttona")) {
                        root.style.setProperty("--translate-filters-sliderq", targetTranslateValueq);
                        handleActiveTab(filterButtons, event, "filter-activea");
                    }
                }
            }
        });
        const rootfirst = document.documentElement;
        rootfirst.style.setProperty("--translate-filters-sliderq", "0");
        startActiveTab(filterButtons);
        /*let purchaserInfo: any = {};
        try {
           purchaserInfo = await this.purchases.getPurchaserInfo();
           var find = purchaserInfo.activeSubscriptions;
        		if (((find.indexOf('party89') !== -1) || (find.indexOf('party88') !== -1)) || find.indexOf('prod_NezOJB58il2mck') !== -1) {
        		    this.showSubButton = "hideGrid";
        		    var pstart = purchaserInfo.entitlements.active.premium.latestPurchaseDate;
                                    var bdate = new Date(pstart);
                                    var mystart = bdate.getTime();
                                    this.periodstart = mystart.toString();
                this.planid = purchaserInfo.entitlements.active.premium.productIdentifier;
        		    this.isowned = true;
        		    this.ownedState = "owned";
        this.showSubscribe = false;
        		} else {
        		    this.showSubButton = "showGrid";
        this.isowned = false;
        this.ownedState = "no";
            this.showSubscribe = true;
        		}
        } catch (e) {
            this.showSubButton = "showGrid";
         this.isowned = false;
         this.ownedState = "no";
            this.showSubscribe = true;
        }*/
    }
    async textarea1Keyup__j_797(event?, currentItem?) {
        let __aio_tmp_val__: any;
        /* Run TypeScript */
        var mylength = this.myscript.length;
        var myholder = this.myscript;
        if (mylength > 250) {
            var removal = 250 - mylength;
            var newscript = myholder.slice(0, removal);
            this.myscript = newscript;
            this.scriptCount = this.myscript.length;
            this.alertController.create({
                header: 'Alert',
                message: 'You are allowed only 250 characters.',
                buttons: ['OK']
            }).then(res => {
                res.present();
            });
        } else {
            this.scriptCount = mylength;
            this.scriptCountString = mylength.toString();
        }
    }
    async button1_copyClick__j_851(event?, currentItem?) {
        let __aio_tmp_val__: any;
        /* Dismiss Modal */
        this.Apperyio.getController("ModalController").dismiss();
    }
    private $aio_dataServices = {
        "getVoices1_clone_1": "invokeService_getVoices1_clone_1",
        "checkPending1_clone_1": "invokeService_checkPending1_clone_1",
        "makeAudio1_clone_1": "invokeService_makeAudio1_clone_1",
        "sendService1_clone_1": "invokeService_sendService1_clone_1",
        "bTimes1_clone_1": "invokeService_bTimes1_clone_1",
        "sendService2_clone_1": "invokeService_sendService2_clone_1",
        "getPuppets2_clone_1": "invokeService_getPuppets2_clone_1"
    };
    invokeService_getVoices1_clone_1(cb?: Function) {
        this.Apperyio.getService("getVoices").then(
            async (service) => {
                if (!service) {
                    console.log("Error. Service was not found.");
                    return;
                }
                let data = {};
                let params = {};
                let headers = {};
                let __aio_tmp_val__: any;
                this.$aio_changeDetector.detectChanges();
                /* Mapping */
                params = this.$aio_mappingHelper.updateData(params, ['lang'], this.$aio_mappingHelper.getSubdata(this.language, []));
                headers = this.$aio_mappingHelper.updateData(headers, ['user-token'], this.$aio_mappingHelper.getSubdata(this.token, []));
                service.execute({
                    data: data,
                    params: params,
                    headers: headers
                }).subscribe(
                    /* onsuccess */
                    async (res: any) => {
                        let mappingData: any = {};
                        /* Mapping */
                        this.voices = this.$aio_mappingHelper.updateData(this.voices, [], this.$aio_mappingHelper.getSubdata(res, []));
                        this.mappingData = { ...this.mappingData,
                            ...mappingData
                        };
                        if (cb && typeof cb === "function") cb(res);
                    },
                    /* onerror */
                    async (err: any) => {
                        /* Run TypeScript */
                        var errorstring = JSON.stringify(err);
                        if (errorstring.indexOf('Session timeout') !== -1) {
                            const alert = await this.alertController.create({
                                cssClass: 'my-custom-class',
                                header: 'Alert',
                                message: 'Your session has expired. Please login.',
                                buttons: ['OK']
                            });
                            await alert.present();
                            this.navCtrl.navigateRoot('/screen1');
                        } else {
                            const alert = await this.alertController.create({
                                cssClass: 'my-custom-class',
                                header: 'Alert',
                                message: 'Please check your network connection or try again later.',
                                buttons: ['OK']
                            });
                            await alert.present();
                        }
                    }
                )
            }
        );
    }
    invokeService_checkPending1_clone_1(cb?: Function) {
        this.Apperyio.getService("checkPending").then(
            async (service) => {
                if (!service) {
                    console.log("Error. Service was not found.");
                    return;
                }
                let data = {};
                let params = {};
                let headers = {};
                let __aio_tmp_val__: any;
                this.$aio_changeDetector.detectChanges();
                /* Mapping */
                headers = this.$aio_mappingHelper.updateData(headers, ['user-token'], this.$aio_mappingHelper.getSubdata(this.token, []));
                /* Present Loading */
                await (async () => {
                    let options = {
                        'animated': true,
                        'keyboardClose': true,
                        'message': 'please wait',
                        'mode': 'ios',
                        'showBackdrop': true,
                    };
                    let controller = this.Apperyio.getController('LoadingController');
                    const loading = await controller.create(options);
                    return await loading.present();
                })();
                service.execute({
                    data: data,
                    params: params,
                    headers: headers
                }).subscribe(
                    /* onsuccess */
                    async (res: any) => {
                        let mappingData: any = {};
                        /* Run TypeScript */
                        var mycount = res;
                        if (mycount > 0) {
                            this.Apperyio.getController("LoadingController").dismiss();
                            this.alertController.create({
                                header: 'Alert',
                                message: 'You currently have a video processing. Once that is finished, you can create another video.',
                                buttons: ['OK']
                            }).then(res => {
                                res.present();
                            });
                        } else {
                            if (this.isMine === true) {
                                this.Apperyio.getController("LoadingController").dismiss();
                                this.startRecording();
                            } else {
                                this.poststamp = Date.now();
                                if (this.isowned === true) {
                                    this.filename = this.voicePitch + "." + this.uid + "_" + this.postscene + "-" + "++" + this.porder + "_" + this.poststamp + ".mp3";
                                } else {
                                    this.filename = this.voicePitch + "." + this.uid + "_" + this.postscene + "-" + "p+" + this.porder + "_" + this.poststamp + ".mp3";
                                }
                                this.Apperyio.execDataService(this, "makeAudio1_clone_1"); // Change service_name to created DataSource name
                            }
                        }
                        this.mappingData = { ...this.mappingData,
                            ...mappingData
                        };
                        if (cb && typeof cb === "function") cb(res);
                    },
                    /* onerror */
                    async (err: any) => {
                        /* Run TypeScript */
                        var errorstring = JSON.stringify(err);
                        if (errorstring.indexOf('Session timeout') !== -1) {
                            const alert = await this.alertController.create({
                                cssClass: 'my-custom-class',
                                header: 'Alert',
                                message: 'Your session has expired. Please login.',
                                buttons: ['OK']
                            });
                            await alert.present();
                            this.navCtrl.navigateRoot('/screen1');
                        } else {
                            const alert = await this.alertController.create({
                                cssClass: 'my-custom-class',
                                header: 'Alert',
                                message: 'Please check your network connection or try again later.',
                                buttons: ['OK']
                            });
                            await alert.present();
                        }
                        /* Dismiss loading */
                        await this.Apperyio.getController("LoadingController").dismiss();
                    }
                )
            }
        );
    }
    invokeService_makeAudio1_clone_1(cb?: Function) {
        this.Apperyio.getService("makeAudio").then(
            async (service) => {
                if (!service) {
                    console.log("Error. Service was not found.");
                    return;
                }
                let data = {};
                let params = {};
                let headers = {};
                let __aio_tmp_val__: any;
                this.$aio_changeDetector.detectChanges();
                /* Mapping */
                data = this.$aio_mappingHelper.updateData(data, ['text'], this.$aio_mappingHelper.getSubdata(this.myscript, []));
                data = this.$aio_mappingHelper.updateData(data, ['pitch'], this.$aio_mappingHelper.getSubdata(this.voicePitch, []));
                data = this.$aio_mappingHelper.updateData(data, ['scene'], this.$aio_mappingHelper.getSubdata(this.postscene, []));
                data = this.$aio_mappingHelper.updateData(data, ['clientid'], this.$aio_mappingHelper.getSubdata(this.uid, []));
                data = this.$aio_mappingHelper.updateData(data, ['voice'], this.$aio_mappingHelper.getSubdata(this.voiceId, []));
                data = this.$aio_mappingHelper.updateData(data, ['rate'], this.$aio_mappingHelper.getSubdata(this.voiceRate, []));
                data = this.$aio_mappingHelper.updateData(data, ['filename'], this.$aio_mappingHelper.getSubdata(this.filename, []));
                data = this.$aio_mappingHelper.updateData(data, ['stamp'], this.$aio_mappingHelper.getSubdata(this.poststamp, []));
                service.execute({
                    data: data,
                    params: params,
                    headers: headers
                }).subscribe(
                    /* onsuccess */
                    async (res: any) => {
                        let mappingData: any = {};
                        /* Run TypeScript */
                        this.playButton = "showGrid";
                        this.pauseButton = "hideGrid";
                        this.caudiourl = "https://backendlessappcontent.com/876B8C32-01E1-6474-FFA9-06AFA3F13500/D7DD8A50-2CE0-B869-FF19-BF856519F500/files/audios/" + res.file;
                        const x = document.getElementById('waudio') as HTMLAudioElement | null;
                        x.pause();
                        x.load();
                        let TIME_IN_MS = 2000;
                        let showAudioGrid = setTimeout(() => {
                            this.showUpcoming = "hideGrid";
                            this.showPast = "hideGrid";
                            this.showAudio = "showGrid";
                            this.loadingController.dismiss();
                        }, TIME_IN_MS);
                        this.mappingData = { ...this.mappingData,
                            ...mappingData
                        };
                        if (cb && typeof cb === "function") cb(res);
                    },
                    /* onerror */
                    async (err: any) => {
                        /* Dismiss loading */
                        await this.Apperyio.getController("LoadingController").dismiss();
                        /* Run TypeScript */
                        var errorstring = JSON.stringify(err);
                        if (errorstring.indexOf('Session timeout') !== -1) {
                            const alert = await this.alertController.create({
                                cssClass: 'my-custom-class',
                                header: 'Alert',
                                message: 'Your session has expired. Please login.',
                                buttons: ['OK']
                            });
                            await alert.present();
                            this.navCtrl.navigateRoot('/screen1');
                        } else {
                            const alert = await this.alertController.create({
                                cssClass: 'my-custom-class',
                                header: 'Alert',
                                message: 'Please check your network connection or try again later.',
                                buttons: ['OK']
                            });
                            await alert.present();
                        }
                    }
                )
            }
        );
    }
    invokeService_sendService1_clone_1(cb?: Function) {
        this.Apperyio.getService("sendService").then(
            async (service) => {
                if (!service) {
                    console.log("Error. Service was not found.");
                    return;
                }
                let data = {};
                let params = {};
                let headers = {};
                let __aio_tmp_val__: any;
                this.$aio_changeDetector.detectChanges();
                /* Mapping */
                headers = this.$aio_mappingHelper.updateData(headers, ['user-token'], this.$aio_mappingHelper.getSubdata(this.token, []));
                data = this.$aio_mappingHelper.updateData(data, ["email"], this.$aio_mappingHelper.getSubdata(this.semail, []));
                data = this.$aio_mappingHelper.updateData(data, ["ownerId"], this.$aio_mappingHelper.getSubdata(this.uid, []));
                data = this.$aio_mappingHelper.updateData(data, ["puppetName"], this.$aio_mappingHelper.getSubdata(this.puppetName, []));
                data = this.$aio_mappingHelper.updateData(data, ["puppetImage"], this.$aio_mappingHelper.getSubdata(this.puppetImage, []));
                data = this.$aio_mappingHelper.updateData(data, ["puppetId"], this.$aio_mappingHelper.getSubdata(this.puppetId, []));
                data = this.$aio_mappingHelper.updateData(data, ["audioFile"], this.$aio_mappingHelper.getSubdata(this.saveaudio, []));
                data = this.$aio_mappingHelper.updateData(data, ["seconds"], this.$aio_mappingHelper.getSubdata(this.duration, []));
                data = this.$aio_mappingHelper.updateData(data, ["fileName"], this.$aio_mappingHelper.getSubdata(this.useFile, []));
                data = this.$aio_mappingHelper.updateData(data, ["app"], this.$aio_mappingHelper.getSubdata(this.apptype, []));
                /* Present Loading */
                await (async () => {
                    let options = {
                        'animated': true,
                        'cssClass': 'canvasLoader',
                        'keyboardClose': true,
                        'message': 'loading',
                        'mode': 'ios',
                        'showBackdrop': true,
                    };
                    let controller = this.Apperyio.getController('LoadingController');
                    const loading = await controller.create(options);
                    return await loading.present();
                })();
                service.execute({
                    data: data,
                    params: params,
                    headers: headers
                }).subscribe(
                    /* onsuccess */
                    async (res: any) => {
                        let mappingData: any = {};
                        /* Run TypeScript */
                        var sessId = res.objectId;
                        localStorage.setItem("sessionId", sessId);
                        /* Invoke data service */
                        this.invokeService_bTimes1_clone_1();
                        this.mappingData = { ...this.mappingData,
                            ...mappingData
                        };
                        if (cb && typeof cb === "function") cb(res);
                    },
                    /* onerror */
                    async (err: any) => {
                        /* Dismiss loading */
                        await this.Apperyio.getController("LoadingController").dismiss();
                        /* Run TypeScript */
                        var errorstring = JSON.stringify(err);
                        if (errorstring.indexOf('Session timeout') !== -1) {
                            const alert = await this.alertController.create({
                                cssClass: 'my-custom-class',
                                header: 'Alert',
                                message: 'Your session has expired. Please login.',
                                buttons: ['OK']
                            });
                            await alert.present();
                            this.navCtrl.navigateRoot('/screen1');
                        } else {
                            const alert = await this.alertController.create({
                                cssClass: 'my-custom-class',
                                header: 'Alert',
                                message: 'Please check your network connection or try again later.',
                                buttons: ['OK']
                            });
                            await alert.present();
                        }
                    }
                )
            }
        );
    }
    invokeService_bTimes1_clone_1(cb?: Function) {
        this.Apperyio.getService("bTimes").then(
            async (service) => {
                if (!service) {
                    console.log("Error. Service was not found.");
                    return;
                }
                let data = {};
                let params = {};
                let headers = {};
                let __aio_tmp_val__: any;
                this.$aio_changeDetector.detectChanges();
                /* Mapping */
                headers = this.$aio_mappingHelper.updateData(headers, ['user-token'], this.$aio_mappingHelper.getSubdata(this.token, []));
                params = this.$aio_mappingHelper.updateData(params, ['pid'], this.$aio_mappingHelper.getSubdata(this.puppetId, []));
                service.execute({
                    data: data,
                    params: params,
                    headers: headers
                }).subscribe(
                    /* onsuccess */
                    async (res: any) => {
                        let mappingData: any = {};
                        /* Run TypeScript */
                        var mycount = res;
                        var queueSeconds = mycount * 10;
                        var treadmillSeconds = 0;
                        var backSeconds = 0;
                        var constructionSeconds = 0;
                        var totalSeconds = this.duration + 10;
                        var recordingSeconds = totalSeconds;
                        if (this.duration <= 5) {
                            treadmillSeconds = totalSeconds + 10;
                            backSeconds = totalSeconds + 15;
                            constructionSeconds = totalSeconds + 20;
                            totalSeconds = totalSeconds + 25;
                        } else if (this.duration <= 10) {
                            treadmillSeconds = totalSeconds + 10;
                            backSeconds = totalSeconds + 15;
                            constructionSeconds = totalSeconds + 25;
                            totalSeconds = totalSeconds + 30;
                        } else if (this.duration <= 15) {
                            treadmillSeconds = totalSeconds + 10;
                            backSeconds = totalSeconds + 15;
                            constructionSeconds = totalSeconds + 25;
                            totalSeconds = totalSeconds + 35;
                        } else if (this.duration <= 20) {
                            treadmillSeconds = totalSeconds + 10;
                            backSeconds = totalSeconds + 20;
                            constructionSeconds = totalSeconds + 30;
                            totalSeconds = totalSeconds + 40;
                        } else if (this.duration <= 25) {
                            treadmillSeconds = totalSeconds + 15;
                            backSeconds = totalSeconds + 25;
                            constructionSeconds = totalSeconds + 35;
                            totalSeconds = totalSeconds + 45;
                        }
                        localStorage.setItem("qSeconds", queueSeconds.toString());
                        localStorage.setItem("rSeconds", recordingSeconds.toString());
                        localStorage.setItem("tSeconds", treadmillSeconds.toString());
                        localStorage.setItem("bSeconds", backSeconds.toString());
                        localStorage.setItem("cSeconds", constructionSeconds.toString());
                        localStorage.setItem("totalSeconds", totalSeconds.toString());
                        var total = localStorage.getItem("vids");
                        var ctotal = parseInt(localStorage.getItem("mycount")) + 1;
                        //this.Apperyio.navigateTo("Tracker"/*, optional, params, here */);
                        this.Apperyio.getController("ModalController").dismiss(
                            /* data */
                        );
                        /* Dismiss loading */
                        await this.Apperyio.getController("LoadingController").dismiss();
                        this.mappingData = { ...this.mappingData,
                            ...mappingData
                        };
                        if (cb && typeof cb === "function") cb(res);
                    },
                    /* onerror */
                    async (err: any) => {
                        /* Dismiss loading */
                        await this.Apperyio.getController("LoadingController").dismiss();
                        /* Run TypeScript */
                        var errorstring = JSON.stringify(err);
                        if (errorstring.indexOf('Session timeout') !== -1) {
                            const alert = await this.alertController.create({
                                cssClass: 'my-custom-class',
                                header: 'Alert',
                                message: 'Your session has expired. Please login.',
                                buttons: ['OK']
                            });
                            await alert.present();
                            this.navCtrl.navigateRoot('/screen1');
                        } else {
                            const alert = await this.alertController.create({
                                cssClass: 'my-custom-class',
                                header: 'Alert',
                                message: 'Please check your network connection or try again later.',
                                buttons: ['OK']
                            });
                            await alert.present();
                        }
                    }
                )
            }
        );
    }
    invokeService_sendService2_clone_1(cb?: Function) {
        this.Apperyio.getService("sendService").then(
            async (service) => {
                if (!service) {
                    console.log("Error. Service was not found.");
                    return;
                }
                let data = {};
                let params = {};
                let headers = {};
                let __aio_tmp_val__: any;
                this.$aio_changeDetector.detectChanges();
                /* Mapping */
                headers = this.$aio_mappingHelper.updateData(headers, ['user-token'], this.$aio_mappingHelper.getSubdata(this.token, []));
                data = this.$aio_mappingHelper.updateData(data, ["fileName"], this.$aio_mappingHelper.getSubdata(this.postfile, []));
                data = this.$aio_mappingHelper.updateData(data, ["puppetId"], this.$aio_mappingHelper.getSubdata(this.puppetId, []));
                data = this.$aio_mappingHelper.updateData(data, ["ownerId"], this.$aio_mappingHelper.getSubdata(this.uid, []));
                data = this.$aio_mappingHelper.updateData(data, ["seconds"], this.$aio_mappingHelper.getSubdata(this.duration, []));
                data = this.$aio_mappingHelper.updateData(data, ["puppetName"], this.$aio_mappingHelper.getSubdata(this.puppetName, []));
                data = this.$aio_mappingHelper.updateData(data, ["puppetImage"], this.$aio_mappingHelper.getSubdata(this.puppetImage, []));
                data = this.$aio_mappingHelper.updateData(data, ["email"], this.$aio_mappingHelper.getSubdata(this.semail, []));
                data = this.$aio_mappingHelper.updateData(data, ["audioFile"], this.$aio_mappingHelper.getSubdata(this.saveaudio, []));
                data = this.$aio_mappingHelper.updateData(data, ["app"], this.$aio_mappingHelper.getSubdata(this.apptype, []));
                /* Present Loading */
                await (async () => {
                    let options = {
                        'animated': true,
                        'cssClass': 'canvasLoader',
                        'keyboardClose': true,
                        'message': 'loading',
                        'mode': 'ios',
                        'showBackdrop': true,
                    };
                    let controller = this.Apperyio.getController('LoadingController');
                    const loading = await controller.create(options);
                    return await loading.present();
                })();
                service.execute({
                    data: data,
                    params: params,
                    headers: headers
                }).subscribe(
                    /* onsuccess */
                    async (res: any) => {
                        let mappingData: any = {};
                        /* Run TypeScript */
                        var sessId = res.objectId;
                        localStorage.setItem("sessionId", sessId);
                        /* Invoke data service */
                        this.invokeService_bTimes1_clone_1();
                        this.mappingData = { ...this.mappingData,
                            ...mappingData
                        };
                        if (cb && typeof cb === "function") cb(res);
                    },
                    /* onerror */
                    async (err: any) => {
                        /* Dismiss loading */
                        await this.Apperyio.getController("LoadingController").dismiss();
                        /* Run TypeScript */
                        var errorstring = JSON.stringify(err);
                        if (errorstring.indexOf('Session timeout') !== -1) {
                            const alert = await this.alertController.create({
                                cssClass: 'my-custom-class',
                                header: 'Alert',
                                message: 'Your session has expired. Please login.',
                                buttons: ['OK']
                            });
                            await alert.present();
                            this.navCtrl.navigateRoot('/screen1');
                        } else {
                            const alert = await this.alertController.create({
                                cssClass: 'my-custom-class',
                                header: 'Alert',
                                message: 'Please check your network connection or try again later.',
                                buttons: ['OK']
                            });
                            await alert.present();
                        }
                    }
                )
            }
        );
    }
    invokeService_getPuppets2_clone_1(cb?: Function) {
        this.Apperyio.getService("getPuppets").then(
            async (service) => {
                if (!service) {
                    console.log("Error. Service was not found.");
                    return;
                }
                let data = {};
                let params = {};
                let headers = {};
                let __aio_tmp_val__: any;
                this.$aio_changeDetector.detectChanges();
                /* Mapping */
                headers = this.$aio_mappingHelper.updateData(headers, ['user-token'], this.$aio_mappingHelper.getSubdata(this.token, []));
                service.execute({
                    data: data,
                    params: params,
                    headers: headers
                }).subscribe(
                    /* onsuccess */
                    async (res: any) => {
                        let mappingData: any = {};
                        /* Mapping */
                        this.puppets = this.$aio_mappingHelper.updateData(this.puppets, [], this.$aio_mappingHelper.getSubdata(res, []));
                        /* Run TypeScript */
                        this.puppets.sort((a, b) => (a.premium > b.premium)? 1: (a.premium === b.premium)?((a.order > b.order)? 1: -1): -1);

                        function isEven(value) {
                            if (value % 2 == 0)
                                return true;
                            else
                                return false;
                        }
                        for (let i = 0; i < this.puppets.length; i++) {
                            if (this.puppets[i].premium === "yes" && this.isowned === false) {
                                this.puppets[i].shrub = "showGrid";
                            } else {
                                this.puppets[i].shrub = "hideGrid";
                            }
                            this.puppets[i].image = "background-image: url('" + this.puppets[i].image + "');background-size:cover;background-repeat:no-repeat;background-position: center center;border: 5px solid #D3D3D3 !important;";
                            this.puppets[i].showPlay = "showGrid";
                            this.puppets[i].showPause = "hideGrid";
                        }
                        var videos = document.getElementsByTagName("video");
                        for (var i = 0; i < videos.length; i++) {
                            var el = videos[i];
                            el.load();
                        }
                        let TIME_IN_MS = 1500;
                        let hideFooterTimeout = setTimeout(() => {
                            //this.checkVideos();
                        }, TIME_IN_MS);
                        /* Invoke data service */
                        this.invokeService_getVoices1_clone_1();
                        this.mappingData = { ...this.mappingData,
                            ...mappingData
                        };
                        if (cb && typeof cb === "function") cb(res);
                    },
                    (err: any) => {
                        console.log(err);
                    }
                )
            }
        );
    }
}