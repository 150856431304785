import {
    NgModule
} from '@angular/core';
import {
    BrowserModule
} from '@angular/platform-browser';
import {
    BrowserAnimationsModule
} from '@angular/platform-browser/animations';
import {
    FormsModule
} from '@angular/forms';
import {
    HttpClientModule
} from '@angular/common/http';
import {
    IonicModule
} from '@ionic/angular';
import {
    IonicStorageModule
} from '@ionic/storage';
import {
    ApperyioModule
} from './scripts/apperyio/apperyio.module';
import {
    ApperyioDeclarablesModule
} from './scripts/apperyio/declarables/apperyio.declarables.module';
import {
    PipesModule
} from './scripts/pipes.module';
import {
    DirectivesModule
} from './scripts/directives.module';
import {
    ComponentsModule
} from './scripts/components.module';
import {
    CustomComponentsModule
} from './scripts/custom-components.module';
import {
    CustomModulesModule
} from './scripts/custom-modules.module';
import {
    Sanitizer
} from '@angular/core';
import {
    NgDompurifySanitizer
} from '@tinkoff/ng-dompurify';
import {
    createTranslateLoader
} from './scripts/apperyio/translate_module';
import {
    TranslateModule
} from '@ngx-translate/core';
import {
    TranslateLoader
} from '@ngx-translate/core';
import {
    HttpClient
} from '@angular/common/http';
import {
    app
} from './app';
import {
    AppRoutingModule
} from './app-routing.module';
import {
    UpgradePopup
} from './UpgradePopup/UpgradePopup';
import {
    Sample
} from './Sample/Sample';
import {
    guestlist
} from './guestlist/guestlist';
import {
    Overview
} from './Overview/Overview';
import {
    Pricing
} from './Pricing/Pricing';
import {
    Registry
} from './Registry/Registry';
import {
    Payment
} from './Payment/Payment';
import {
    EventOptions
} from './EventOptions/EventOptions';
import {
    guestBook
} from './guestBook/guestBook';
import {
    messages
} from './messages/messages';
import {
    AudioModal
} from './AudioModal/AudioModal';
import {
    Microphone
} from './Microphone/Microphone';
import {
    Login
} from './Login/Login';
import {
    Fonts
} from './Fonts/Fonts';
import {
    ShareLink
} from './ShareLink/ShareLink';
import {
    Crop
} from './Crop/Crop';
import {
    Search
} from './Search/Search';
import {
    InviteDetails
} from './InviteDetails/InviteDetails';
import {
    Preview
} from './Preview/Preview';
import {
    invitedGuests
} from './invitedGuests/invitedGuests';
import {
    Wizard
} from './Wizard/Wizard';
import {
    Colors
} from './Colors/Colors';
import {
    ImageSelect
} from './ImageSelect/ImageSelect';
import {
    ExportedClass as GenericService
} from './scripts/custom/GenericService_Impl';
import {
    ExportedClass as CameraService
} from './scripts/custom/Camera_Impl';
import {
    ExportedClass as checkUpdate
} from './scripts/services/checkUpdate';
import {
    ExportedClass as register
} from './scripts/services/register';
import {
    ExportedClass as login
} from './scripts/services/login';
import {
    ExportedClass as checkUser
} from './scripts/services/checkUser';
import {
    ExportedClass as getPuppets
} from './scripts/services/getPuppets';
import {
    ExportedClass as getCategories
} from './scripts/services/getCategories';
import {
    ExportedClass as getBackgrounds
} from './scripts/services/getBackgrounds';
import {
    ExportedClass as getBackgroundsCount
} from './scripts/services/getBackgroundsCount';
import {
    ExportedClass as getVoices
} from './scripts/services/getVoices';
import {
    ExportedClass as checkPending
} from './scripts/services/checkPending';
import {
    ExportedClass as makeAudio
} from './scripts/services/makeAudio';
import {
    ExportedClass as checkVideos
} from './scripts/services/checkVideos';
import {
    ExportedClass as saveAudio
} from './scripts/services/saveAudio';
import {
    ExportedClass as sendCode
} from './scripts/services/sendCode';
import {
    ExportedClass as confirmCode
} from './scripts/services/confirmCode';
import {
    ExportedClass as resetUpdate
} from './scripts/services/resetUpdate';
import {
    ExportedClass as sendService
} from './scripts/services/sendService';
import {
    ExportedClass as bTimes
} from './scripts/services/bTimes';
import {
    ExportedClass as getVideo
} from './scripts/services/getVideo';
import {
    ExportedClass as getVideos
} from './scripts/services/getVideos';
import {
    ExportedClass as getAccount
} from './scripts/services/getAccount';
import {
    ExportedClass as updateUser
} from './scripts/services/updateUser';
import {
    ExportedClass as logout
} from './scripts/services/logout';
import {
    ExportedClass as deleteUser
} from './scripts/services/deleteUser';
import {
    ExportedClass as getWorkshop
} from './scripts/services/getWorkshop';
import {
    ExportedClass as likeIt
} from './scripts/services/likeIt';
import {
    ExportedClass as confirmActivate
} from './scripts/services/confirmActivate';
import {
    ExportedClass as ip
} from './scripts/services/ip';
import {
    ExportedClass as getCardBacks
} from './scripts/services/getCardBacks';
import {
    ExportedClass as createInvite
} from './scripts/services/createInvite';
import {
    ExportedClass as updateInvite
} from './scripts/services/updateInvite';
import {
    ExportedClass as createContact
} from './scripts/services/createContact';
import {
    ExportedClass as getContacts
} from './scripts/services/getContacts';
import {
    ExportedClass as deleteContact
} from './scripts/services/deleteContact';
import {
    ExportedClass as createEvent
} from './scripts/services/createEvent';
import {
    ExportedClass as getEvents
} from './scripts/services/getEvents';
import {
    ExportedClass as getEventsPast
} from './scripts/services/getEventsPast';
import {
    ExportedClass as getInvites
} from './scripts/services/getInvites';
import {
    ExportedClass as getInvite
} from './scripts/services/getInvite';
import {
    ExportedClass as inviteCheck
} from './scripts/services/inviteCheck';
import {
    ExportedClass as updateEvent
} from './scripts/services/updateEvent';
import {
    ExportedClass as getEvent
} from './scripts/services/getEvent';
import {
    ExportedClass as deleteInvite
} from './scripts/services/deleteInvite';
import {
    ExportedClass as getEventMessages
} from './scripts/services/getEventMessages';
import {
    ExportedClass as updateEventMessages
} from './scripts/services/updateEventMessages';
import {
    ExportedClass as deleteMessage
} from './scripts/services/deleteMessage';
import {
    ExportedClass as hostMessage
} from './scripts/services/hostMessage';
import {
    ExportedClass as hostReply
} from './scripts/services/hostReply';
import {
    ExportedClass as deleteReply
} from './scripts/services/deleteReply';
import {
    ExportedClass as cancelSubscription
} from './scripts/services/cancelSubscription';
import {
    ExportedClass as getImages
} from './scripts/services/getImages';
import {
    ExportedClass as getImagesCount
} from './scripts/services/getImagesCount';
import {
    ExportedClass as getTemplate
} from './scripts/services/getTemplate';
import {
    ExportedClass as getMyImages
} from './scripts/services/getMyImages';
import {
    ExportedClass as getMyImagesCount
} from './scripts/services/getMyImagesCount';
import {
    ExportedClass as deleteMyImage
} from './scripts/services/deleteMyImage';
import {
    ExportedClass as getFrames
} from './scripts/services/getFrames';
import {
    ExportedClass as getTemplates
} from './scripts/services/getTemplates';
import {
    ExportedClass as getSettings
} from './scripts/services/getSettings';
import {
    ExportedClass as getContactsCount
} from './scripts/services/getContactsCount';
import {
    ExportedClass as getTemplatesSearch
} from './scripts/services/getTemplatesSearch';
import {
    ExportedClass as getTemplatesCount
} from './scripts/services/getTemplatesCount';
import {
    ExportedClass as buildToken
} from './scripts/services/buildToken';
import {
    ExportedClass as chargeToken
} from './scripts/services/chargeToken';
import {
    Contacts
} from '@ionic-native/contacts/ngx';
import {
    InAppBrowser
} from '@ionic-native/in-app-browser/ngx';
import {
    Camera
} from '@ionic-native/camera/ngx';
import {
    ScreenOrientation
} from '@ionic-native/screen-orientation/ngx';
import {
    Media
} from '@ionic-native/media/ngx';
import {
    File
} from '@ionic-native/file/ngx';
import {
    Dialogs
} from '@ionic-native/dialogs/ngx';
import {
    WebView
} from '@ionic-native/ionic-webview/ngx';
import {
    Device
} from '@ionic-native/device/ngx';
import {
    SplashScreen
} from '@ionic-native/splash-screen/ngx';
import {
    StatusBar
} from '@ionic-native/status-bar/ngx';
import {
    Keyboard
} from '@ionic-native/keyboard/ngx';
import {
    Network
} from '@ionic-native/network/ngx';
import {
    SecureStorage
} from '@ionic-native/secure-storage/ngx';
( < any > NgDompurifySanitizer.prototype)._sanitize_fn = NgDompurifySanitizer.prototype.sanitize;
NgDompurifySanitizer.prototype.sanitize = function(...args) {
    let value: any = args[1];
    if (value && value.hasOwnProperty("changingThisBreaksApplicationSecurity")) {
        args[1] = value.changingThisBreaksApplicationSecurity
    }
    return this._sanitize_fn(...args);
}
var getIonicModuleConfig, getIonicStorageModuleConfig;
@NgModule({
    declarations: [
        app, UpgradePopup, Sample, guestlist, Overview, Pricing, Registry, Payment, EventOptions, guestBook, messages, AudioModal, Microphone, Login, Fonts, ShareLink, Crop, Search, InviteDetails, Preview, invitedGuests, Wizard, Colors, ImageSelect
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        IonicModule.forRoot((typeof getIonicModuleConfig === "function")? getIonicModuleConfig(): undefined),
        HttpClientModule,
        ApperyioModule,
        PipesModule,
        DirectivesModule,
        ComponentsModule,
        ApperyioDeclarablesModule,
        CustomComponentsModule,
        CustomModulesModule,
        IonicStorageModule.forRoot((typeof getIonicStorageModuleConfig === "function")? getIonicStorageModuleConfig(): undefined),
        AppRoutingModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        })
    ],
    bootstrap: [
        app
    ],
    entryComponents: [
        //app
        UpgradePopup, Sample, guestlist, Overview, Pricing, Registry, Payment, EventOptions, guestBook, messages, AudioModal, Microphone, Login, Fonts, ShareLink, Crop, Search, InviteDetails, Preview, invitedGuests, Wizard, Colors, ImageSelect
    ],
    providers: [
        StatusBar,
        SplashScreen,
        WebView,
        Device,
        Keyboard,
        Network,
        SecureStorage,
        {
            provide: Sanitizer,
            useClass: NgDompurifySanitizer,
        },
        Contacts,
        InAppBrowser,
        Camera,
        ScreenOrientation,
        Media,
        File,
        Dialogs,
        GenericService,
        CameraService,
        checkUpdate,
        register,
        login,
        checkUser,
        getPuppets,
        getCategories,
        getBackgrounds,
        getBackgroundsCount,
        getVoices,
        checkPending,
        makeAudio,
        checkVideos,
        saveAudio,
        sendCode,
        confirmCode,
        resetUpdate,
        sendService,
        bTimes,
        getVideo,
        getVideos,
        getAccount,
        updateUser,
        logout,
        deleteUser,
        getWorkshop,
        likeIt,
        confirmActivate,
        ip,
        getCardBacks,
        createInvite,
        updateInvite,
        createContact,
        getContacts,
        deleteContact,
        createEvent,
        getEvents,
        getEventsPast,
        getInvites,
        getInvite,
        inviteCheck,
        updateEvent,
        getEvent,
        deleteInvite,
        getEventMessages,
        updateEventMessages,
        deleteMessage,
        hostMessage,
        hostReply,
        deleteReply,
        cancelSubscription,
        getImages,
        getImagesCount,
        getTemplate,
        getMyImages,
        getMyImagesCount,
        deleteMyImage,
        getFrames,
        getTemplates,
        getSettings,
        getContactsCount,
        getTemplatesSearch,
        getTemplatesCount,
        buildToken,
        chargeToken,
    ]
})
export class AppModule {}