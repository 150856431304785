import {
    Component
} from '@angular/core';
import {
    ChangeDetectorRef
} from '@angular/core';
import {
    ApperyioHelperService
} from '../scripts/apperyio/apperyio_helper';
import {
    ApperyioMappingHelperService
} from '../scripts/apperyio/apperyio_mapping_helper';
import {
    $aio_empty_object
} from '../scripts/interfaces';
import {
    ViewChild
} from '@angular/core';
@Component({
    templateUrl: 'InviteDetails.html',
    selector: 'page-invite-details',
    styleUrls: ['InviteDetails.css', 'InviteDetails.scss']
})
export class InviteDetails {
    public progressstyletop: string;
    public whitecolor: string;
    public eTitle: string;
    public eDate: string;
    public eTime: string;
    public eHost: string;
    public eLocation: string;
    public eStreet: string;
    public eCity: string;
    public eState: string;
    public eZip: string;
    public dateString: string;
    public timeString: string;
    public eNote: string;
    public ePhone: string;
    public details: any;
    public $a: ApperyioHelperService;
    public $v: {
        [name: string]: any
    };
    public aioChangeDetector: ChangeDetectorRef;
    public currentItem: any = null;
    @ViewChild('_aio_content') _aio_content;
    public mappingData: any = {};
    public __getMapping(_currentItem, property, defaultValue, isVariable?, isSelected?) {
        return this.$aio_mappingHelper.getMapping(this.mappingData, _currentItem, property, defaultValue, isVariable, isSelected);
    }
    public __isPropertyInMapping(_currentItem, property) {
        return this.$aio_mappingHelper.isPropertyInMapping(this.mappingData, _currentItem, property);
    }
    public __setMapping(data: any = {}, keyName: string, propName?: string): void {
        const changes = data.detail || {};
        if (propName) {
            this.mappingData = this.$aio_mappingHelper.updateData(this.mappingData, [keyName], changes[propName]);
        } else {
            this.mappingData = this.$aio_mappingHelper.updateData(this.mappingData, [keyName], changes.value);
        }
        this.$aio_changeDetector.detectChanges();
    }
    public __bindedMethods: any = {};
    closeWizard(): any {
        this.$a.closeModal(
            /* data */
        );
    }
    dateChange2(): any {
        function dateFormater(date) {
            var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
            var day = date.getDate();
            // get month from 0 to 11
            var month = date.getMonth();
            // conver month digit to month name
            month = months[month];
            var year = date.getFullYear();
            // show date in two digits
            if (day < 10) {
                day = '0' + day;
            }
            // now we have day, month and year
            // arrange them in the format we want
            return month + ' ' + day + ', ' + year;
        }
        this.dateString = dateFormater(new Date(this.eDate));
    }
    timeChange2(): any {
        function formatDate(date) {
            var hours = date.getHours();
            var minutes = date.getMinutes();
            var ampm = hours >= 12? 'PM': 'AM';
            hours = hours % 12;
            hours = hours? hours: 12; // the hour '0' should be '12'
            minutes = minutes < 10? '0' + minutes: minutes;
            var strTime = hours + ':' + minutes + ' ' + ampm;
            return strTime;
        }
        var d = new Date(this.eTime);
        var e = formatDate(d);
        this.timeString = e;
    }
    constructor(public Apperyio: ApperyioHelperService, private $aio_mappingHelper: ApperyioMappingHelperService, private $aio_changeDetector: ChangeDetectorRef) {
        this.$a = this.Apperyio;
        this.$v = this.Apperyio.vars;
        this.aioChangeDetector = this.$aio_changeDetector;
    }
    ngOnInit() {
        this.Apperyio.setThinScrollIfNeeded();
    }
    ionViewWillEnter() {
        window.currentScreen = this;
        this.pageIonViewWillEnter__j_1665();
    }
    async pageIonViewWillEnter__j_1665(event?, currentItem?) {
        let __aio_tmp_val__: any;
        /* Run TypeScript */
        this.details = this.$a.data.getVariable("eventDetails");
        this.progressstyletop = "width: 25%";
        this.whitecolor = "#ffffff";
    }
    async input1_copy13Keyup__j_1691(event?, currentItem?) {
        let __aio_tmp_val__: any;
        /* Run TypeScript */
        // Strip all characters from the input except digits
        this.ePhone = this.ePhone.replace(/\D/g, '');
        // Trim the remaining input to ten characters, to preserve phone number format
        this.ePhone = this.ePhone.substring(0, 10);
        // Based upon the length of the string, we add formatting as necessary
        var size = this.ePhone.length;
        if (size == 0) {
            this.ePhone = this.ePhone;
        } else if (size < 4) {
            this.ePhone = '(' + this.ePhone;
        } else if (size < 7) {
            this.ePhone = '(' + this.ePhone.substring(0, 3) + ') ' + this.ePhone.substring(3, 6);
        } else {
            this.ePhone = '(' + this.ePhone.substring(0, 3) + ') ' + this.ePhone.substring(3, 6) + ' - ' + this.ePhone.substring(6, 10);
        }
    }
}